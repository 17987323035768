var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";
export const configJSON = require("./config");
const AccountRegExr = /^([a-zA-Z0-9\_.-]+)$/;
export default class EditBusinessProfileController extends BlockComponent {
    // Customizable Area End
    constructor(props) {
        // Customizable Area Start
        super(props);
        this.handleBack = () => {
            this.props.navigation.goBack();
        };
        this.handleUpdateSuccess = (responseJson) => {
            var _a;
            if (responseJson) {
                this.setState({ isLoading: false });
            }
            if (this.state.crn !== responseJson.data.attributes.CRN) {
                if ((_a = responseJson === null || responseJson === void 0 ? void 0 : responseJson.meta[0]) === null || _a === void 0 ? void 0 : _a.CRN) {
                    toast.success(this.props.t(responseJson.meta[0].CRN), {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            }
            this.props.navigation.navigate("Profile");
        };
        this.handleTeamSize = (responseJson, errorReponse) => {
            if (responseJson !== null) {
                if (!responseJson.errors) {
                    this.handleGetBusinessTeamSizeResponse(responseJson, errorReponse);
                }
            }
        };
        this.handleEditImageData = (memberData) => {
            var _a, _b;
            let Images;
            let index;
            if (((_a = memberData.business_pictures.data) === null || _a === void 0 ? void 0 : _a.length) !== 0) {
                Images = (_b = memberData.business_pictures.data) === null || _b === void 0 ? void 0 : _b.map((picture) => {
                    return ({
                        data_url: picture.attributes.image,
                        index: picture.id,
                    });
                });
                this.setState({
                    businessImages: Images,
                }, () => this.onChange(Images, index));
            }
        };
        this.handleEditWorkingDays = (memberData) => {
            var _a, _b;
            if (((_a = memberData === null || memberData === void 0 ? void 0 : memberData.working_days) === null || _a === void 0 ? void 0 : _a.length) !== 0) {
                let working_days = memberData === null || memberData === void 0 ? void 0 : memberData.working_days.map(function (x) {
                    return x.toUpperCase();
                });
                let updateWorkday = (_b = this.state.workingDaysData) === null || _b === void 0 ? void 0 : _b.map((ele, index) => {
                    if (working_days === null || working_days === void 0 ? void 0 : working_days.includes(ele.day.toUpperCase())) {
                        let temp = Object.assign({}, ele);
                        temp.isWeekday = true;
                        temp.isDisableWeekend = true;
                        return temp;
                    }
                    else {
                        return ele;
                    }
                });
                this.setState({
                    workingDaysData: updateWorkday,
                    workingDays: memberData === null || memberData === void 0 ? void 0 : memberData.working_days,
                });
            }
        };
        this.handleEditWeekendDays = (memberData) => {
            var _a, _b;
            if (((_a = memberData === null || memberData === void 0 ? void 0 : memberData.weekends) === null || _a === void 0 ? void 0 : _a.length) !== 0) {
                let weekends = memberData === null || memberData === void 0 ? void 0 : memberData.weekends.map(function (x) {
                    return x.toUpperCase();
                });
                let updateWorkday = (_b = this.state.workingDaysData) === null || _b === void 0 ? void 0 : _b.map((ele, index) => {
                    if (weekends === null || weekends === void 0 ? void 0 : weekends.includes(ele.day.toUpperCase())) {
                        let temp = Object.assign({}, ele);
                        temp.isWeekend = true;
                        temp.isDisableWeekday = true;
                        return temp;
                    }
                    else {
                        return ele;
                    }
                });
                this.setState({
                    workingDaysData: updateWorkday,
                    weekendDays: memberData === null || memberData === void 0 ? void 0 : memberData.weekend_working_days,
                });
            }
        };
        this.handleSelection = (id) => {
            this.state.teamSizeData.map((item) => {
                if (item.attributes.id === id) {
                    item.attributes.isSelected = true;
                    this.setState({ selectedTeamSize: item.attributes.id }, () => {
                        if (this.state.selectedTeamSize) {
                            this.setState({ selectedTeamSizeError: "" });
                        }
                    });
                    return item;
                }
                else {
                    item.attributes.isSelected = false;
                }
                this.setState({ teamSizeData: [...this.state.teamSizeData] });
            });
        };
        this.optionDisable = (value) => {
            return value === "" ? true : false;
        };
        this.showTimeInUppercase = (value) => {
            return value.toUpperCase();
        };
        this.setServiceID = (value) => {
            this.setState({ serviceId: value });
        };
        // web events
        this.setStateValue = () => {
            this.setState({ openBankDetailsDialog: true });
        };
        // web events
        this.setInputValue = (text) => {
            if (/^[a-zA-Z ]*$/.test(text)) {
                this.setState({ txtInputValue: text, businessName: text, businessNameError: "" });
            }
        };
        this.setArabicInput = (text) => {
            if (/^$|^[\u0600-\u06FF\s]+$/.test(text)) {
                this.setState({ txtInputValue: text, arabicBusinessName: text, arabicBusinessNameError: "" });
            }
        };
        // web events
        this.onChange = (imageList, addUpdateIndex) => {
            // data for submit
            const flag = imageList.length === 0 ? false : true;
            if (imageList) {
                this.setState({ businessImages: imageList, showImages: flag, businessImagesError: "" });
            }
            if (imageList && imageList.length < 2) {
                this.setState({ businessImagesError: "Please upload at least 2 images of your business" });
            }
            else if (imageList && imageList.length > 5) {
                this.setState({ showImages: false, businessImages: [], businessImagesError: "You can upload max 5 images of your business" });
            }
        };
        this.getImageid = (id) => {
            this.setState((prevState) => ({
                imageId: [...prevState.imageId, id],
            }));
        };
        this.validateField = (fieldName) => {
            switch (fieldName) {
                case "address":
                    return this.state.address === "" ? "Please enter the address" : "";
                case "cityName":
                    return this.state.cityName === "" ? "Please enter the city" : "";
                case "stateName":
                    return this.state.stateName === "" ? "Please enter the state" : "";
                case "postalCode":
                    return this.state.postalCode === "" ? "Please enter the postal code" : "";
                case "countryName":
                    return this.state.countryName === "" ? "Please enter the country" : "";
                default:
                    return "";
            }
        };
        this.validateBusinessName = () => {
            if (this.state.businessName === "") {
                this.setState({ businessNameError: "Please enter business name" });
            }
            else {
                this.setState({ businessNameError: "" });
            }
        };
        this.validateArabicBusinessName = () => {
            if (this.state.arabicBusinessName === "") {
                this.setState({ arabicBusinessNameError: "Please enter business name in arabic" });
            }
            else {
                this.setState({ arabicBusinessNameError: "" });
            }
        };
        this.validateBusinessEmail = () => {
            if (this.state.businessEmail === "") {
                this.setState({ businessEmailError: "Please enter business email" });
            }
            else {
                this.setState({ businessEmailError: "" });
            }
        };
        this.validateWebsite = () => {
            const websiteFormat = /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/;
            if (this.state.website !== "") {
                if (!this.state.website.match(websiteFormat)) {
                    this.setState({ websiteError: "Invalid website" });
                }
                else {
                    this.setState({ websiteError: "" });
                }
            }
        };
        this.validateBusinessOwner = () => {
            if (this.state.businessOwner === "") {
                this.setState({ businessOwnerError: "Please enter owner name" });
            }
            else {
                this.setState({ businessOwnerError: "" });
            }
        };
        this.validateContact = () => {
            const contactRegex = /^[0-9 \-\(\)\.]{8,14}$/;
            if (this.state.contact === "") {
                this.setState({ contactError: "Please add your business contact number" });
            }
            else if (!this.state.contact.match(contactRegex)) {
                this.setState({ contactError: "Please add your business contact number" });
            }
            else {
                this.setState({ contactError: "" });
            }
        };
        this.validateCRN = () => {
            if (this.state.crn === "") {
                this.setState({ crnError: "Please enter CRN" });
            }
            else {
                this.setState({ crnError: "" });
            }
        };
        this.validateName = () => {
            const { accountOwnerFullName } = this.state;
            const accountOwnerNameError = accountOwnerFullName ? "" : "Please enter full name";
            this.setState({ accountOwnerNameError });
        };
        this.validateAccountNumber = () => {
            const { accountNumber } = this.state;
            let accountNumberError = "";
            if (accountNumber) {
                if (!accountNumber.match(AccountRegExr)) {
                    accountNumberError = "Invalid bank account number";
                }
            }
            else {
                accountNumberError = "Please enter bank account number";
            }
            this.setState({ accountNumberError });
        };
        this.validateConfirmAccountNumber = () => {
            const { accountNumber, confirmAccountNumber } = this.state;
            let confirmAccountNumberError = "";
            if (confirmAccountNumber) {
                if (accountNumber !== confirmAccountNumber) {
                    confirmAccountNumberError = "Confirm bank account number does not match";
                }
            }
            else {
                confirmAccountNumberError = "Please enter confirm bank account number";
            }
            this.setState({ confirmAccountNumberError });
        };
        this.validateBankName = () => {
            const { bankName } = this.state;
            const bankNameError = bankName ? "" : "Please enter bank name";
            this.setState({ bankNameError });
        };
        this.validatePurpose = () => {
            const { purposeOfPayment } = this.state;
            const purposeOfPaymentError = purposeOfPayment ? "" : "Please enter purpose of payment";
            this.setState({ purposeOfPaymentError });
        };
        this.handlePaymentSelection = (value) => {
            if (value === "payAtShop") {
                this.handlePayAtShop();
            }
            else {
                this.handlePayByCard();
            }
        };
        this.handlePayAtShop = () => {
            this.setState({ enablePayAtShop: !this.state.enablePayAtShop }, () => {
                if (this.state.enablePayAtShop) {
                    this.setState({ paymentOptionError: "" });
                }
                if (!this.state.enableCardPayment && !this.state.enablePayAtShop) {
                    this.setState({ paymentOptionError: "Please choose your payment options" });
                }
            });
        };
        this.handlePayByCard = () => {
            this.setState({ enableCardPayment: !this.state.enableCardPayment }, () => {
                if (this.state.enableCardPayment) {
                    this.setState({ paymentOptionError: "" });
                }
                if (!this.state.enableCardPayment && !this.state.enablePayAtShop) {
                    this.setState({ paymentOptionError: "Please choose your payment options" });
                }
            });
        };
        this.handleSelectToggle = (key) => {
            this.setState(prevState => {
                const newState = Object.assign(Object.assign({}, prevState), { [key]: !prevState[key] });
                return newState;
            });
        };
        this.getTogle = (item, togleTrue, togleFalse) => {
            if (item) {
                return togleTrue;
            }
            else {
                return togleFalse;
            }
        };
        this.getBorderStyle = (error) => {
            return error ? "1px solid #FF453A" : "1px solid #757575";
        };
        this.renderErrorMessageforProfile = (Message) => {
            const errorMessage = Message ? Message : null;
            return errorMessage;
        };
        this.handleBackgroundColor = (item) => {
            const isWeekday = item.isWeekday;
            const isWeekend = item.isWeekend;
            const selectionMode = this.state.getSelectionMode;
            let backgroundColor = "#f9f9f9";
            if ((selectionMode === "weekdays" && isWeekday) || (selectionMode === "weekends" && isWeekend)) {
                backgroundColor = "#1E5FEA";
            }
            return backgroundColor;
        };
        this.handleColor = (item) => {
            const isWeekday = item.isWeekday;
            const isWeekend = item.isWeekend;
            const selectionMode = this.state.getSelectionMode;
            let color = "#808080";
            if ((selectionMode === "weekdays" && isWeekday) || (selectionMode === "weekends" && isWeekend)) {
                color = "#FFFFFF";
            }
            return color;
        };
        this.handleCountryCodeChange = (value) => {
            if (value) {
                this.setState({ countryCode: value, countryCodeModal: false });
            }
            else {
                this.setState({ countryCodeModal: !this.state.countryCodeModal });
            }
        };
        this.handleWorkingHoursSelection = (itemId) => {
            const { getSelectionMode, workingDaysError, weekendDaysError } = this.state;
            const updatedWorkingDaysData = this.state.workingDaysData.map((item) => {
                if (itemId !== item.id)
                    return item;
                if (getSelectionMode === "weekdays" && !item.isWeekend) {
                    item.isWeekday = !item.isWeekday;
                    item.isDisableWeekend = item.isWeekday;
                }
                else if (getSelectionMode === "weekends" && !item.isWeekday) {
                    item.isWeekend = !item.isWeekend;
                    item.isDisableWeekday = item.isWeekend;
                }
                return item;
            });
            const workingDays = updatedWorkingDaysData.filter((item) => item.isWeekday);
            const weekendDays = updatedWorkingDaysData.filter((item) => item.isWeekend);
            this.setState({
                workingDaysData: updatedWorkingDaysData,
                workingDays: workingDays,
                workingDaysError: workingDays.length ? "" : workingDaysError,
                weekendDays: weekendDays,
                weekendDaysError: weekendDays.length ? "" : weekendDaysError,
            });
        };
        this.getBackGroundColor = (selectionMode, weekType) => {
            return selectionMode === weekType ? "#1e5fea" : undefined;
        };
        this.getCardBackGroundColor = (showImages) => {
            return showImages ? "#FFFFFF" : "#F9F9F8";
        };
        this.getTextColor = (selectionMode, weekType) => {
            return selectionMode === weekType ? 'white' : 'black';
        };
        this.handleSave = () => __awaiter(this, void 0, void 0, function* () {
            if (this.state.accountOwnerFullName === "") {
                this.setState({
                    accountOwnerNameError: "Please enter full name",
                });
            }
            else {
                this.setState({ accountOwnerNameError: "" });
            }
            if (this.state.accountNumber === "") {
                this.setState({ accountNumberError: "Please enter bank account number" });
            }
            else if (!this.state.accountNumber.match(AccountRegExr)) {
                this.setState({ accountNumberError: "Invalid bank account number" });
            }
            else {
                this.setState({ accountNumberError: "" });
            }
            if (this.state.confirmAccountNumber === "") {
                this.setState({ confirmAccountNumberError: "Please enter confirm bank account number" });
            }
            else if (this.state.accountNumber !== this.state.confirmAccountNumber) {
                this.setState({ confirmAccountNumberError: "Confirm bank account number does not match" });
            }
            else {
                this.setState({ confirmAccountNumberError: "" });
            }
            if (this.state.bankName === "") {
                this.setState({
                    bankNameError: "Please enter bank name",
                });
            }
            else {
                this.setState({ bankNameError: "" });
            }
            if (this.state.purposeOfPayment === "") {
                this.setState({ purposeOfPaymentError: "Please enter purpose of payment" });
            }
            else {
                this.setState({ purposeOfPaymentError: "" });
            }
            if (this.state.accountOwnerFullName !== "" && this.state.accountNumber.match(AccountRegExr) && this.state.confirmAccountNumber !== "" && this.state.accountNumber === this.state.confirmAccountNumber && this.state.bankName !== "" && this.state.purposeOfPayment !== "") {
                const bankDetails = [
                    { accountOwnerName: this.state.accountOwnerFullName },
                    { accountNumber: this.state.accountNumber },
                    { bankName: this.state.bankName },
                    { purpose: this.state.purposeOfPayment }
                ];
                this.setState({ openAddedDetailsDialog: true, openBankDetailsDialog: false, accountDetails: bankDetails, bankDetailsError: "" });
            }
        });
        this.onWeekendFromSelection = (selectedItem) => {
            this.setState({ isWeekendToDisable: false, weekendFrom: selectedItem, weekendHoursError: "" });
            let Ind;
            this.state.workingSlot.forEach((item, index) => {
                if (item === selectedItem) {
                    Ind = index;
                    let fromIndex = this.state.workingSlot.filter((item, index) => {
                        if (index > Ind) {
                            return item;
                        }
                    });
                    fromIndex.unshift("");
                    this.setState({ weekendSlotTo: fromIndex });
                    let lastIndex = this.state.workingSlot.length - 1;
                    if (lastIndex === Ind) {
                        let toTime = this.state.workingSlot.filter((item, index) => {
                            if (index !== Ind) {
                                return item;
                            }
                        });
                        toTime.unshift("");
                        this.setState({ weekendSlotTo: toTime });
                    }
                }
            });
        };
        this.onWorkingFromSelection = (selectedItem) => {
            this.setState({ isWorkingToDisable: false, workingFrom: selectedItem, workingHoursError: "" });
            let Ind;
            this.state.workingSlot.forEach((item, index) => {
                if (item === selectedItem) {
                    Ind = index;
                    let lastIndex = this.state.workingSlot.length - 1;
                    let fromIndex = this.state.workingSlot.filter((item, index) => {
                        if (index > Ind) {
                            return item;
                        }
                    });
                    fromIndex.unshift("");
                    this.setState({ workingSlotTo: fromIndex });
                    if (lastIndex === Ind) {
                        let toTime = this.state.workingSlot.filter((item, index) => {
                            if (index !== Ind) {
                                return item;
                            }
                        });
                        toTime.unshift("");
                        this.setState({ workingSlotTo: toTime });
                    }
                }
            });
        };
        this.validateBusinessSetup = () => {
            var _a;
            const contactRegex = /^[0-9 \-\(\)\.]{8,14}$/;
            const errors = {
                paymentOption: !this.state.enableCardPayment && !this.state.enablePayAtShop,
                businessImages: this.state.businessImages.length < 2 || this.state.businessImages.length > 5,
                businessName: this.state.businessName === "",
                businessOwner: this.state.businessOwner === "",
                businessEmail: this.state.businessEmail === "" || !this.state.businessEmail.match(configJSON.emailRegex),
                website: this.state.website !== "" && !this.state.website.match(/^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/),
                contact: this.state.contact === "" || !this.state.contact.match(contactRegex),
                crn: this.state.crn === "",
                service: this.state.serviceId === null,
                bankDetails: this.state.accountNumber === "" || this.state.accountOwnerFullName === "" || this.state.confirmAccountNumber === "" || this.state.bankName === "" || this.state.purposeOfPayment === "",
                workingHours: this.state.workingFrom === "" || this.state.workingTo === "" || this.state.workingFrom === this.state.workingTo,
                weekendHours: this.state.weekendFrom === "" || this.state.weekendTo === "" || this.state.weekendFrom === this.state.weekendTo,
                workingDays: this.state.workingDays.length === 0,
                weekendDays: ((_a = this.state.weekendDays) === null || _a === void 0 ? void 0 : _a.length) === 0,
                selectedTeamSize: this.state.selectedTeamSize === null
            };
            const errorMessages = {
                paymentOption: "Please choose your payment options",
                businessImages: "Please upload at least 2 images of your business",
                businessName: "This field can't be empty",
                businessOwner: "This field can't be empty",
                businessEmail: "Invalid business email",
                website: "Invalid website",
                contact: "Please add your business contact number",
                crn: "This field can't be empty",
                service: "Please choose a category",
                bankDetails: "Please add bank details",
                workingHours: "Please choose your working time",
                weekendHours: "Please choose your weekend time",
                workingDays: "Please choose your working days",
                weekendDays: "Please choose your weekend days",
                selectedTeamSize: "Please choose your team size"
            };
            let hasErrors = false;
            const errorState = {};
            Object.keys(errors).forEach((key) => {
                if (errors[key]) {
                    hasErrors = true;
                    errorState[`${key}Error`] = errorMessages[key];
                }
                else {
                    errorState[`${key}Error`] = "";
                }
            });
            if (hasErrors) {
                this.setState((prevState) => (Object.assign(Object.assign({}, prevState), errorState)));
            }
            else {
                this.updateBusinessProfileAPICall();
            }
        };
        this.fetchBusinessTeamSizeAPICall = () => __awaiter(this, void 0, void 0, function* () {
            const header = {
                "Content-Type": "application/json",
                token: this.state.token,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.apiGetBusinessTeamSizeCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getBusinessTeamSizeEndPoint);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.dashboarApiMethodType);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        });
        this.fetchBusinessProfileAPICall = () => __awaiter(this, void 0, void 0, function* () {
            const header = {
                token: this.state.token,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.apiGetBusinessProfileCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getBusinessProfileEndPoint);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.dashboarApiMethodType);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        });
        this.getOS = () => {
            const platform = navigator.userAgent;
            let os = 'Unknown';
            if (/Win/.test(platform)) {
                os = 'Windows';
            }
            else if (/Mac/.test(platform)) {
                os = 'MacOS';
            }
            else if (/Linux/.test(platform)) {
                os = 'Linux';
            }
            else if (/iPad|iPhone|iPod/.test(platform)) {
                os = 'iOS';
            }
            else if (/Android/.test(platform)) {
                os = 'Android';
            }
            return os;
        };
        this.hasLocationPermission = () => __awaiter(this, void 0, void 0, function* () {
            if (this.getOS() === "Linux" || this.getOS() === "Windows" || this.getOS() === "Android" || this.getOS() === "MacOS" || this.getOS() === "iOS") {
                if (navigator.geolocation) {
                    return true;
                }
                else {
                    return false;
                }
            }
            return false;
        });
        this.allowLoactionAccess = (value) => {
            this.setState({ allowAccess: value });
        };
        this.extractAddressComponent = (json, type) => {
            const result = json.results[0].address_components.find((component) => component.types.includes(type));
            return result ? result.long_name : "";
        };
        this.getCurrentLocation = (url) => __awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield fetch(url);
                const json = yield response.json();
                const latLocation = this.extractAddressComponent(json, "locality");
                const countryName = this.extractAddressComponent(json, "country");
                const stateName = this.extractAddressComponent(json, "administrative_area_level_1");
                const pincode = this.extractAddressComponent(json, "postal_code");
                const landmark = this.extractAddressComponent(json, "sublocality_level_1");
                const address = `${landmark}, ${latLocation}, ${stateName}`;
                this.setState({
                    countryName,
                    stateName,
                    cityName: latLocation,
                    postalCode: pincode,
                    landmark,
                    address,
                    businessLocation: latLocation
                });
                if (this.state.address !== "") {
                    this.setState({ addressError: "" });
                }
                if (this.state.postalCode !== "") {
                    this.setState({ postalCodeError: "" });
                }
                if (this.state.cityName !== "") {
                    this.setState({ cityNameError: "" });
                }
                if (this.state.stateName !== "") {
                    this.setState({ stateNameError: "" });
                }
                if (this.state.countryName !== "") {
                    this.setState({ countryNameError: "" });
                }
            }
            catch (e) {
                console.log(e);
            }
        });
        this.getLocation = () => __awaiter(this, void 0, void 0, function* () {
            console.log("user getLocation");
            try {
                const hasLocationPermission = yield this.hasLocationPermission();
                console.log("hasLocationPermission ::", hasLocationPermission);
                if (!hasLocationPermission) {
                    this.allowLoactionAccess(false);
                    return;
                }
                const googleAPIkey = "AIzaSyAsVvsnqcCH2nhpRgyQrpJZYqxRcHkq6xc";
                //@ts-ignore
                const position = yield new Promise((resolve, reject) => {
                    navigator.geolocation.getCurrentPosition(resolve, reject, {
                        enableHighAccuracy: true,
                        timeout: 5000,
                        maximumAge: 0
                    });
                });
                const googleMapApi = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=${googleAPIkey}&language=en`;
                this.setState({
                    coordinatesLat: position.coords.latitude,
                    coordinatesLong: position.coords.longitude,
                });
                const locationData = yield this.getCurrentLocation(googleMapApi);
                console.log(locationData);
            }
            catch (error) {
                console.log(error);
            }
        });
        this.validate = () => __awaiter(this, void 0, void 0, function* () {
            const { address, postalCode, cityName, stateName, countryName } = this.state;
            let errorState = {
                addressError: "",
                cityNameError: "",
                stateNameError: "",
                postalCodeError: "",
                countryNameError: "",
            };
            if (!address)
                errorState.addressError = "Please enter the address";
            if (!cityName)
                errorState.cityNameError = "Please enter the city";
            if (!stateName)
                errorState.stateNameError = "Please enter the state";
            if (!postalCode)
                errorState.postalCodeError = "Please enter the postal code";
            if (!countryName)
                errorState.countryNameError = "Please enter the country";
            this.setState(errorState);
            if (address && postalCode && cityName && stateName && countryName) {
                this.setState({ openLocationDialog: false });
            }
        });
        this.updateBusinessProfileAPICall = () => __awaiter(this, void 0, void 0, function* () {
            this.setState({ isLoading: true });
            const header = {
                token: this.state.token
            };
            let formdata = new FormData();
            //business details 
            formdata.append("owner_name", this.state.businessOwner);
            formdata.append("business_name", this.state.businessName);
            formdata.append("arabic_business_name", this.state.arabicBusinessName);
            formdata.append("website", this.state.website);
            formdata.append("crn", this.state.crn);
            formdata.append("pay_at_shop", this.state.enablePayAtShop.toString());
            formdata.append("card_payment", this.state.enableCardPayment.toString());
            formdata.append("open_from", this.state.workingFrom);
            formdata.append("close_at", this.state.workingTo);
            formdata.append("weekend_working_hours", "true");
            formdata.append("show_staff_members", this.state.allowTeam.toString());
            formdata.append("about_us", this.state.aboutUsText);
            formdata.append("arabic_about_us", this.state.aboutUsTextArabic);
            formdata.append("weekend_hours_to", this.state.weekendTo);
            formdata.append("weekend_hours_from", this.state.weekendFrom);
            formdata.append("business_team_size_id", this.state.selectedTeamSize);
            //Business images
            this.state.imageId.map((id, index) => {
                formdata.append(`[attachments_attributes][${index}][id]`, id);
                formdata.append(`[attachments_attributes][${index}][_destroy]`, 'true');
            });
            this.state.businessImages.map((item, index) => {
                if (item.file && this.state.imageId.length) {
                    this.state.imageId.map((id, index) => {
                        formdata.append(`[attachments_attributes][${index}][id]`, id);
                        formdata.append(`[attachments_attributes][${index}][image]`, item.file);
                        this.state.imageId.shift();
                    });
                }
                else if (item.file && !this.state.imageId.length) {
                    formdata.append(`[attachments_attributes][${index}][image]`, item.file);
                }
            });
            //Bank details
            formdata.append("bank_account_detail_attributes[owner_name]", this.state.accountOwnerFullName);
            formdata.append("bank_account_detail_attributes[account_number]", this.state.accountNumber);
            formdata.append("bank_account_detail_attributes[bank_name]", this.state.bankName);
            formdata.append("bank_account_detail_attributes[purpose]", this.state.purposeOfPayment);
            formdata.append("bank_account_detail_attributes[id]", this.state.profileDetails.bank_account_detail.id);
            //Location details
            formdata.append("location_attributes[id]", this.state.profileDetails.business_location.id);
            formdata.append("location_attributes[city]", this.state.cityName);
            formdata.append("location_attributes[country]", this.state.countryName);
            formdata.append("location_attributes[state]", this.state.stateName);
            formdata.append("location_attributes[address]", this.state.address);
            formdata.append("location_attributes[full_address]", this.state.cityName);
            formdata.append("location_attributes[landmark]", this.state.landmark);
            formdata.append("location_attributes[pincode]", this.state.postalCode);
            formdata.append("location_attributes[latitude]", this.state.coordinatesLat);
            formdata.append("location_attributes[longitude]", this.state.coordinatesLong);
            //business working and weekend days
            this.state.workingDaysData.map((item) => {
                if (item.isWeekday) {
                    formdata.append("working_days[]", item.day);
                }
            });
            this.state.workingDaysData.map((item) => {
                if (item.isWeekend) {
                    formdata.append("weekends[]", item.day);
                }
            });
            this.setState({ imageId: [] });
            for (const pair of formdata.entries()) {
                console.log(`${pair[0]}: ${pair[1]}`);
            }
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.apiPostBusinessSetUpCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.PostBusinessSetupEndPoint);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formdata);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.put);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        });
        this.handleContactChange = (e) => {
            if (/^$|^[\d]{0,14}$/.test(e.target.value)) {
                this.setState({
                    contactError: "",
                    contact: e.target.value,
                });
            }
        };
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            isSelectOpenWeekHoursTo: false,
            isSelectOpenWeekEndsFrom: false,
            isSelectOpenWeekHoursFrom: false,
            isSelectOpenWeekEndsTo: false,
            isSelectOpen: false,
            loginSuccess: false,
            aboutUsText: "",
            backgroundColor: "#f9f9f9",
            color: "#808080",
            paymentOptionError: "",
            submisionError: '',
            token: '',
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            isLoading: false,
            openBankDetailsDialog: false,
            openAddedDetailsDialog: false,
            showImages: false,
            imageId: [],
            countryCode: '+91',
            countryCodeModal: false,
            profileDetails: null,
            arabicBusinessNameError: "",
            arabicBusinessName: "",
            aboutUsTextArabic: "",
            accountOwnerFullName: "",
            accountOwnerNameError: "",
            accountNumber: "",
            accountNumberError: "",
            confirmAccountNumber: "",
            confirmAccountNumberError: "",
            bankName: "",
            bankNameError: "",
            purposeOfPayment: "",
            purposeOfPaymentError: "",
            allowTeam: false,
            enablePayAtShop: false,
            enableCardPayment: false,
            showServiceList: false,
            service: "",
            serviceId: 1,
            serviceValue: "Choose",
            imageModalVisible: false,
            workingFrom: "",
            workingTo: "",
            businessImages: [],
            businessImagesError: "",
            providedService: [{
                    id: 1,
                    name: "Men"
                },
                {
                    id: 2,
                    name: "Women"
                },
                {
                    id: 3,
                    name: "Men & Women"
                }],
            selectedTeamSize: null,
            removePhotoEnable: false,
            selectedPhoto: null,
            serviceCategory: [],
            teamSizeData: [],
            selectedTeamSizeError: '',
            businessName: "",
            businessNameError: "",
            businessOwner: "",
            businessOwnerError: "",
            businessEmail: "",
            businessEmailError: "",
            website: "",
            websiteError: "",
            crn: "",
            crnError: "",
            contact: "",
            contactError: "",
            bankAccountPlaceholder: "Add bank details",
            accountDetails: [],
            getRoundCorner: 25,
            getSelectionMode: 'weekdays',
            weekendFrom: "",
            weekendTo: "",
            serviceError: "",
            bankDetailsError: "",
            workingHoursError: "",
            workingDaysError: "",
            workingDays: [],
            isTakePhotoModalVisible: false,
            capturedImage: "",
            height: null,
            width: null,
            uri: "",
            weekendHoursError: "",
            weekendDays: [],
            isWorkingDayDisable: false,
            weekendDaysError: "",
            selectedWorkingFromIndex: "",
            selectedWorkingToIndex: '',
            workingSlotTo: [],
            weekendSlotTo: [],
            isWorkingToDisable: false,
            isWeekendToDisable: false,
            businessLocation: "",
            businessLocationError: "",
            address: "",
            addressError: "",
            landmark: "",
            landmarkError: "",
            postalCode: "",
            postalCodeError: "",
            cityName: "",
            cityNameError: "",
            stateName: "",
            stateNameError: "",
            countryName: "",
            countryNameError: "",
            openLocationDialog: false,
            allowAccess: false,
            coordinatesLat: null,
            coordinatesLong: null,
            crnUpdateStatus: "",
            workingDaysData: [
                {
                    id: 1,
                    day: "Sunday",
                    isWeekend: false,
                    isWeekday: false,
                    isDisableWeekday: false,
                    isDisableWeekend: false
                },
                {
                    id: 2,
                    day: "Monday",
                    isWeekend: false,
                    isWeekday: false,
                    isDisableWeekday: false,
                    isDisableWeekend: false
                },
                {
                    id: 3,
                    day: "Tuesday",
                    isWeekend: false,
                    isWeekday: false,
                    isDisableWeekday: false,
                    isDisableWeekend: false
                },
                {
                    id: 4,
                    day: "Wednesday",
                    isWeekend: false,
                    isWeekday: false,
                    isDisableWeekday: false,
                    isDisableWeekend: false
                },
                {
                    id: 5,
                    day: "Thursday",
                    isWeekend: false,
                    isWeekday: false,
                    isDisableWeekday: false,
                    isDisableWeekend: false
                },
                {
                    id: 6,
                    day: "Friday",
                    isWeekend: false,
                    isWeekday: false,
                    isDisableWeekday: false,
                    isDisableWeekend: false
                },
                {
                    id: 7,
                    day: "Saturday",
                    isWeekend: false,
                    isWeekday: false,
                    isDisableWeekday: false,
                    isDisableWeekend: false
                },
            ],
            workingSlot: [
                "",
                "12:00 AM",
                "12:30 AM",
                "01:00 AM",
                "01:30 AM",
                "02:00 AM",
                "02:30 AM",
                "03:00 AM",
                "03:30 AM",
                "04:00 AM",
                "04:30 AM",
                "05:00 AM",
                "05:30 AM",
                "06:00 AM",
                "06:30 AM",
                "07:00 AM",
                "07:30 AM",
                "08:00 AM",
                "08:30 AM",
                "09:00 AM",
                "09:30 AM",
                "10:00 AM",
                "10:30 AM",
                "11:00 AM",
                "11:30 AM",
                "12:00 PM",
                "12:30 PM",
                "01:00 PM",
                "01:30 PM",
                "02:00 PM",
                "02:30 PM",
                "03:00 PM",
                "03:30 PM",
                "04:00 PM",
                "04:30 PM",
                "05:00 PM",
                "05:30 PM",
                "06:00 PM",
                "06:30 PM",
                "07:00 PM",
                "07:30 PM",
                "08:00 PM",
                "08:30 PM",
                "09:00 PM",
                "09:30 PM",
                "10:00 PM",
                "10:30 PM",
                "11:00 PM",
                "11:30 PM",
            ],
        };
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
        // Customizable Area End
    }
    // Customizable Area Start
    receive(from, message) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
        return __awaiter(this, void 0, void 0, function* () {
            runEngine.debugLog("Message Recived", message);
            if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
                const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
                let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
                let errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
                if (apiRequestCallId === this.apiGetBusinessTeamSizeCallId) {
                    this.handleTeamSize(responseJson, errorReponse);
                }
                if (apiRequestCallId === this.apiGetBusinessProfileCallId) {
                    const memberData = responseJson.data.attributes;
                    this.setState({
                        profileDetails: responseJson.data.attributes,
                    });
                    this.handleEditImageData(memberData);
                    this.handleEditWorkingDays(memberData);
                    this.handleEditWeekendDays(memberData);
                    this.setState({
                        businessOwner: memberData === null || memberData === void 0 ? void 0 : memberData.owner_name,
                        businessName: memberData === null || memberData === void 0 ? void 0 : memberData.business_name,
                        arabicBusinessName: memberData.arabic_business_name || "",
                        businessEmail: memberData === null || memberData === void 0 ? void 0 : memberData.business_email,
                        website: memberData === null || memberData === void 0 ? void 0 : memberData.website,
                        contact: memberData === null || memberData === void 0 ? void 0 : memberData.contant_number,
                        crn: memberData === null || memberData === void 0 ? void 0 : memberData.crn,
                        aboutUsText: memberData === null || memberData === void 0 ? void 0 : memberData.about_us,
                        aboutUsTextArabic: memberData.arabic_about_us || "",
                        coordinatesLat: (_a = memberData.business_location) === null || _a === void 0 ? void 0 : _a.latitude,
                        coordinatesLong: (_b = memberData.business_location) === null || _b === void 0 ? void 0 : _b.longitude,
                        businessLocation: (_c = memberData.business_location) === null || _c === void 0 ? void 0 : _c.city,
                        address: (_d = memberData.business_location) === null || _d === void 0 ? void 0 : _d.address,
                        landmark: (_e = memberData.business_location) === null || _e === void 0 ? void 0 : _e.landmark,
                        postalCode: (_f = memberData.business_location) === null || _f === void 0 ? void 0 : _f.pincode,
                        cityName: (_g = memberData.business_location) === null || _g === void 0 ? void 0 : _g.city,
                        stateName: (_h = memberData.business_location) === null || _h === void 0 ? void 0 : _h.state,
                        countryName: (_j = memberData.business_location) === null || _j === void 0 ? void 0 : _j.country,
                        accountOwnerFullName: (_k = memberData.bank_account_detail) === null || _k === void 0 ? void 0 : _k.owner_name,
                        accountNumber: (_l = memberData.bank_account_detail) === null || _l === void 0 ? void 0 : _l.account_number,
                        confirmAccountNumber: (_m = memberData.bank_account_detail) === null || _m === void 0 ? void 0 : _m.account_number,
                        purposeOfPayment: (_o = memberData.bank_account_detail) === null || _o === void 0 ? void 0 : _o.purpose,
                        bankName: (_p = memberData.bank_account_detail) === null || _p === void 0 ? void 0 : _p.bank_name,
                        enablePayAtShop: memberData.pay_at_shop,
                        enableCardPayment: memberData.card_payment,
                        selectedTeamSize: (_q = memberData.team_size.data) === null || _q === void 0 ? void 0 : _q.attributes.id,
                        workingDays: memberData.working_days,
                        weekendDays: memberData.weekend_working_days,
                        workingFrom: (_r = memberData.open_from) === null || _r === void 0 ? void 0 : _r.toUpperCase(),
                        workingTo: (_s = memberData.close_at) === null || _s === void 0 ? void 0 : _s.toUpperCase(),
                        weekendFrom: (_t = memberData === null || memberData === void 0 ? void 0 : memberData.weekend_hours_from) === null || _t === void 0 ? void 0 : _t.toUpperCase(),
                        weekendTo: (_u = memberData === null || memberData === void 0 ? void 0 : memberData.weekend_hours_to) === null || _u === void 0 ? void 0 : _u.toUpperCase(),
                        allowTeam: memberData.show_staff_members,
                        crnUpdateStatus: memberData.crn_update_request,
                        isLoading: false,
                    }, () => {
                        this.onWorkingFromSelection(this.state.workingFrom);
                        this.onWeekendFromSelection(this.state.weekendFrom);
                    });
                }
                if (apiRequestCallId === this.apiPostBusinessSetUpCallId) {
                    this.handleUpdateSuccess(responseJson);
                }
            }
        });
    }
    handleGetBusinessTeamSizeResponse(responseJson, errorReponse) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!responseJson.errors) {
                const teamSizeData = responseJson.data;
                const arr = teamSizeData.map((item) => (Object.assign(Object.assign({}, item), { attributes: Object.assign(Object.assign({}, item.attributes), { isSelected: false }) })));
                this.setState({ teamSizeData: arr });
            }
            else if (responseJson.errors[0].token) {
                console.log("responseJson:", responseJson.errors[0].token);
            }
            else {
                alert(errorReponse);
            }
        });
    }
    renderErrorMessage() {
        const errorMessage = this.state.getSelectionMode === "weekdays"
            ? this.state.workingDaysError
            : this.state.weekendDaysError;
        return errorMessage ? errorMessage : null;
    }
    componentDidUpdate(prevProps, prevState) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.state.openAddedDetailsDialog || this.state.loginSuccess) {
                setTimeout(() => {
                    this.setState({ openAddedDetailsDialog: false, loginSuccess: false });
                }, 3000);
            }
        });
    }
    componentDidMount() {
        const _super = Object.create(null, {
            componentDidMount: { get: () => super.componentDidMount }
        });
        return __awaiter(this, void 0, void 0, function* () {
            _super.componentDidMount.call(this);
            this.setState({ isLoading: true, loginSuccess: true, token: localStorage.getItem('token') }, () => {
                this.fetchBusinessTeamSizeAPICall();
                this.fetchBusinessProfileAPICall();
            });
            let user = localStorage.getItem("userType") || "";
            let token = localStorage.getItem("token") || "";
            if (user != "business" || !token) {
                localStorage.clear();
                window.location.replace("/LandingPage");
            }
        });
    }
}
