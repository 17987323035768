var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// Customizable Area Start
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { setStorageData } from "framework/src/Utilities";
const Cookies = require("js-cookie");
export const configJSON = require("./config");
const configJSONLogin = require("../../social-media-account-login/src/config.js");
export default class ForgotPasswordMobileNumber extends BlockComponent {
    constructor(props) {
        super(props);
        this.sendOtpCallId = "";
        this.validateOtpCallId = "";
        this.createNewPasswordCallId = "";
        this.startLoading = () => {
            this.setState({ loading: true });
        };
        this.stopLoading = () => {
            this.setState({ loading: false });
        };
        this.handleReceive = (apiRequestCallId, responseJson, errorJson) => {
            if (apiRequestCallId === this.sendOtpCallId) {
                this.handleSendOtpResponse(responseJson, errorJson);
            }
            if (apiRequestCallId === this.validateOtpCallId) {
                this.handleValidateOtpResponse(responseJson, errorJson);
            }
            if (apiRequestCallId === this.createNewPasswordCallId) {
                this.handleCreateNewPasswordResponse(responseJson, errorJson);
            }
        };
        this.handleSendOtpResponse = (responseJson, errorReponse) => {
            var _a;
            if ((_a = responseJson === null || responseJson === void 0 ? void 0 : responseJson.meta) === null || _a === void 0 ? void 0 : _a.token) {
                this.handleSuccessSendOtpResponse(responseJson === null || responseJson === void 0 ? void 0 : responseJson.meta.token);
            }
            if (responseJson === null || responseJson === void 0 ? void 0 : responseJson.errors) {
                this.handleErrorSendOtpResponse(responseJson);
            }
            else {
                runEngine.debugLog(errorReponse);
            }
        };
        this.handleSuccessSendOtpResponse = (token) => {
            setStorageData("forgotPasswordToken", token);
            if (this.state.isOtpSent) {
                this.setState({
                    resendOtpSuccess: true,
                }, () => {
                    setTimeout(() => {
                        this.setState({
                            resendOtpSuccess: false,
                            isResendButtonEnabled: false,
                        });
                    }, 1000);
                });
            }
            this.setState({
                isOtpSent: true,
                otp: "",
                otpError: false,
                isAccountBlocked: false,
            });
        };
        this.handleErrorSendOtpResponse = (errorResponse) => {
            if (errorResponse.errors[0].account) {
                this.setState({ mobileNumberNotRegisteredError: true });
            }
            if (errorResponse.errors[0].validation) {
                this.setState({ mobileNumberNotValidFromBE: true });
            }
            if (errorResponse.errors[0].message ===
                "Account is blocked please try after 30 minutes.") {
                this.setState({ isAccountBlocked: true });
            }
            if (errorResponse.errors[0].message ===
                "Please use the registered email id associated with the phone number.") {
                this.setState({ emailNotRegisteredError: true });
            }
        };
        this.handleValidateOtpResponse = (responseJson, errorReponse) => {
            if (responseJson && responseJson.messages && responseJson.messages[0].otp) {
                this.setState({
                    otp: "",
                    otpSuccessMessageShow: true,
                    isSetNewPassword: true,
                    isAccountBlocked: false,
                }, () => {
                    setTimeout(() => {
                        this.setState({
                            otpSuccessMessageShow: false,
                        });
                    }, 1000);
                });
            }
            if (responseJson && responseJson.errors) {
                if (responseJson.errors[0].otp) {
                    this.setState({ otpError: true });
                }
                if (responseJson.errors[0].message) {
                    this.setState({ isAccountBlocked: true });
                }
            }
            else {
                runEngine.debugLog(errorReponse);
            }
        };
        this.handleCreateNewPasswordResponse = (responseJson, errorReponse) => {
            if (responseJson && responseJson.message) {
                this.setState({
                    otp: "",
                    isSetNewPassword: false,
                    isOtpSent: false,
                    isNewPasswordCreated: true,
                }, () => {
                    setTimeout(() => {
                        this.setState({ isNewPasswordCreated: false });
                        localStorage.clear();
                        this.handleNavigation("Login");
                    }, 1000);
                });
            }
            else {
                runEngine.debugLog(errorReponse);
            }
        };
        this.sendOtpApi = () => {
            const { countryCode, mobileNumber, email } = this.state;
            let phoneValidation = this.validatePhoneNumber();
            let emailValidation = this.validateEmail();
            if (phoneValidation && emailValidation) {
                this.startLoading();
                const header = {
                    "Content-Type": configJSON.forgotPasswordAPiContentType,
                };
                const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
                this.sendOtpCallId = requestMessage.messageId;
                requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.passwordRecoveryStartOtpAPiEndPoint);
                const httpBody = {
                    data: {
                        attributes: {
                            country_code: countryCode.slice(1),
                            phone_number: mobileNumber,
                            email: email,
                        },
                    },
                };
                requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
                requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
                requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPostMethod);
                runEngine.sendMessage(requestMessage.id, requestMessage);
                return true;
            }
        };
        this.validateOtpApi = () => {
            const { otp } = this.state;
            const token = localStorage.getItem("forgotPasswordToken") || "";
            if (!otp) {
                return false;
            }
            this.startLoading();
            const header = {
                "Content-Type": configJSON.forgotPasswordAPiContentType,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.validateOtpCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.validateOtpEndPoint}?token=${token}`);
            const httpBody = {
                data: {
                    token: token,
                    otp_code: otp,
                },
            };
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPostMethod);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.createNewPasswordApi = () => {
            const { newPassword, confirmNewPassword } = this.state;
            const token = localStorage.getItem("forgotPasswordToken") || "";
            if (!newPassword && newPassword.length <= 0) {
                this.setState({
                    passwordEmptyError: true,
                    passwordWithSpaceError: false,
                });
                return false;
            }
            else {
                this.setState({
                    passwordEmptyError: false,
                });
            }
            if (newPassword && newPassword.match(/\s/)) {
                this.setState({ passwordWithSpaceError: true, passwordError: false });
                return false;
            }
            else {
                this.setState({ passwordWithSpaceError: false });
            }
            if (!configJSON.passwordRegex.test(newPassword)) {
                this.setState({
                    passwordError: true,
                });
                return false;
            }
            else if (newPassword !== confirmNewPassword) {
                this.setState({
                    passwordNotMatchedError: true,
                    passwordError: false,
                });
                return false;
            }
            else {
                this.setState({
                    passwordNotMatchedError: false,
                    passwordError: false,
                });
            }
            this.startLoading();
            const header = {
                "Content-Type": configJSON.forgotPasswordAPiContentType,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.createNewPasswordCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.createNewPasswordEndPoint);
            const httpBody = {
                data: {
                    token: token,
                    new_password: newPassword,
                    password_confirmation: confirmNewPassword,
                },
            };
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPostMethod);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.handleNavigation = (path) => {
            this.props.navigation.navigate(path);
        };
        this.handleCountryCodeChange = (value) => {
            if (value) {
                this.setState({ countryCode: value, countryCodeModal: false });
            }
            else {
                this.setState({ countryCodeModal: !this.state.countryCodeModal });
            }
        };
        this.handleChange = (name, value) => {
            const inputRegex = /^[a-zA-Z0-9 !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]*$/;
            if (/^$|^[\d]{0,14}$/.test(value) && name === "mobileNumber") {
                this.setState(Object.assign(Object.assign({}, this.state), { mobileNumber: value }));
            }
            if (inputRegex.test(value) && name != "mobileNumber") {
                this.setState(Object.assign(Object.assign({}, this.state), { [name]: value }));
            }
        };
        this.handleOtpChange = (value) => {
            if (this.state.otpError) {
                this.setState(Object.assign(Object.assign({}, this.state), { otpError: false }));
            }
            this.setState(Object.assign(Object.assign({}, this.state), { otp: value }), () => {
                if (this.state.otp.length === 4) {
                    this.validateOtpApi();
                }
            });
        };
        this.showPasswordChange = () => {
            this.setState({ isShowPassword: !this.state.isShowPassword });
        };
        this.showConfirmPasswordChange = () => {
            this.setState({ isShowConfirmPassword: !this.state.isShowConfirmPassword });
        };
        this.closeOtpModal = () => {
            this.setState({ isOtpSent: false });
        };
        this.closeNewPasswodModal = () => {
            this.setState({ isSetNewPassword: false });
        };
        this.handleEnableResend = () => {
            this.setState(Object.assign(Object.assign({}, this.state), { isResendButtonEnabled: true, otpError: false }));
        };
        this.validatePhoneNumber = () => {
            const { countryCode, mobileNumber } = this.state;
            let fullPhoneNumber = countryCode + mobileNumber;
            this.setState({
                mobileNumberError: false,
                mobileNumberNotRegisteredError: false,
                mobileNumberNotValidFromBE: false,
            });
            if (!configJSON.phoneRegExp.test(fullPhoneNumber)) {
                this.setState({
                    mobileNumberError: true,
                    mobileNumberNotRegisteredError: false,
                });
                return false;
            }
            else {
                this.setState({
                    mobileNumberError: false,
                    mobileNumberNotRegisteredError: false,
                });
                return true;
            }
        };
        this.validateEmail = () => {
            const { email } = this.state;
            this.setState({
                emailError: false,
                emailNotRegisteredError: false,
            });
            if (!configJSONLogin.emailRegex.test(email)) {
                this.setState({
                    emailError: true,
                    emailNotRegisteredError: false,
                });
                return false;
            }
            else {
                this.setState({
                    emailNotRegisteredError: false,
                    emailError: false,
                });
                return true;
            }
        };
        this.borderRight = () => {
            let webAppDirection = localStorage.getItem("webAppDirection") ||
                Cookies.get("webAppDirection") ||
                "ltr";
            let borderColorRight = "";
            if (webAppDirection === "ltr") {
                if (this.state.mobileNumberError ||
                    this.state.mobileNumberNotRegisteredError ||
                    this.state.mobileNumberNotValidFromBE) {
                    borderColorRight = "1px solid #ff453a";
                }
                else {
                    borderColorRight = "1px solid #979797";
                }
            }
            return borderColorRight;
        };
        this.borderLeft = () => {
            let webAppDirection = localStorage.getItem("webAppDirection") ||
                Cookies.get("webAppDirection") ||
                "ltr";
            let borderColorLeft = "";
            if (webAppDirection === "rtl") {
                if (this.state.mobileNumberError ||
                    this.state.mobileNumberNotRegisteredError ||
                    this.state.mobileNumberNotValidFromBE) {
                    borderColorLeft = "1px solid #ff453a";
                }
                else {
                    borderColorLeft = "1px solid #979797";
                }
            }
            return borderColorLeft;
        };
        this.mobileNumberFieldError = () => this.state.mobileNumberError ||
            this.state.mobileNumberNotRegisteredError ||
            this.state.mobileNumberNotValidFromBE;
        this.emailFieldError = () => this.state.emailError || this.state.emailNotRegisteredError;
        this.countryCodeColor = () => this.state.mobileNumberError ||
            this.state.mobileNumberNotRegisteredError ||
            this.state.mobileNumberNotValidFromBE
            ? "#ff453a"
            : "#2c2c2e";
        this.indormentDirection = () => {
            let webAppDirection = localStorage.getItem("webAppDirection") ||
                Cookies.get("webAppDirection") ||
                "ltr";
            return webAppDirection === "ltr" ? "start" : "end";
        };
        this.subScribedMessages = [];
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
        ]);
        this.state = {
            loading: false,
            isRegistration: false,
            countryCode: "+91",
            mobileNumber: "",
            otp: "",
            newPassword: "",
            confirmNewPassword: "",
            countryCodeModal: false,
            isOtpSent: false,
            otpError: false,
            mobileNumberError: false,
            isSetNewPassword: false,
            isShowPassword: false,
            isShowConfirmPassword: false,
            passwordError: false,
            passwordNotMatchedError: false,
            mobileNumberNotRegisteredError: false,
            isAccountBlocked: false,
            otpSuccessMessageShow: false,
            isNewPasswordCreated: false,
            resendOtpSuccess: false,
            passwordEmptyError: false,
            passwordWithSpaceError: false,
            isResendButtonEnabled: false,
            email: "",
            emailError: false,
            emailNotRegisteredError: false,
            mobileNumberNotValidFromBE: false,
        };
    }
    componentDidMount() {
        const _super = Object.create(null, {
            componentDidMount: { get: () => super.componentDidMount }
        });
        return __awaiter(this, void 0, void 0, function* () {
            _super.componentDidMount.call(this);
            let isUserLoggedIn = localStorage.getItem("token") || "";
            if (isUserLoggedIn && isUserLoggedIn.length > 0) {
                window.location.replace("/");
            }
        });
    }
    receive(from, message) {
        return __awaiter(this, void 0, void 0, function* () {
            this.stopLoading();
            if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
                const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
                const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
                const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
                if (apiRequestCallId === this.sendOtpCallId ||
                    apiRequestCallId === this.validateOtpCallId ||
                    apiRequestCallId === this.createNewPasswordCallId) {
                    this.handleReceive(apiRequestCallId, responseJson, errorReponse);
                }
            }
            else {
                runEngine.debugLog("GOIT");
            }
        });
    }
}
// Customizable Area End
