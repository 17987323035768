// Customizable Area Start
//@ts-nocheck
import React from "react";
import CustomerHomeScreenController from "./CustomerHomeScreenController";
import { close_modal_icon, cross, } from "../../appointmentmanagement/src/assets";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import "../assets/style/squeezMeInStyle.web.css";
import { withRouter } from "react-router-dom";
import HeaderWeb from "./Header.web";
import FooterWeb from "./Footer.web";
import { Dialog, Button } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import "../assets/style/customerModal.web.scss";
import { withTranslation } from "react-i18next";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End
class CustomerHomeScreen extends CustomerHomeScreenController {
    constructor() {
        super(...arguments);
        // Customizable Area Start
        // Customizable Area End
        this.modalData = () => {
            // Customizable Area Start
            return this.state.get_api_data02.map((element) => {
                return (React.createElement("div", { key: element.id + "upper ", style: { cursor: "pointer" }, id: "upper_slider-div", onClick: () => {
                        this.props.history.push({
                            pathname: "/CustomerSubcategoryDetail",
                            state: {
                                gender: "",
                                show_gender: "",
                                subCategory: "",
                                id: "",
                                subId: element.attributes.business_profile_id,
                            },
                        });
                    } },
                    React.createElement("img", { style: {
                            height: "280px",
                            objectFit: "fill",
                            borderRadius: "24px",
                            width: "100%",
                        }, src: element.attributes.banner })));
            });
            // Customizable Area End
        };
    }
    render() {
        var _a, _b;
        // Customizable Area Start
        this.Set_api_callTimes = "last_call";
        let { t } = this.props;
        return (React.createElement(React.Fragment, null,
            React.createElement(Loader, { loading: this.state.isLoading }),
            React.createElement("div", { style: {
                    overflow: "scroll",
                    backgroundColor: "#F2F2F2",
                    height: "100%",
                } },
                React.createElement("div", { className: "constainer01" },
                    React.createElement(HeaderWeb, { handleSearch: this.navigateToSearch }),
                    React.createElement("div", { className: "customer-home-screen-main-container" },
                        React.createElement("div", { className: "squeeze_data_only" },
                            React.createElement("div", { className: "Squeeze_image_big_first" }, this.state.shouldRenderCarousel &&
                                this.state.get_api_data02 ? (React.createElement(Carousel, { showArrows: false, showThumbs: false, showStatus: false, axis: "horizontal", autoPlay: true, infiniteLoop: true, interval: 5000 }, this.modalData())) : ("")),
                            React.createElement("div", { className: "services-text-customer-home-screen" }, t("Services")),
                            this.state.isActive == false && (React.createElement("div", { className: "reschedul-error-container" },
                                React.createElement("span", { style: { display: "flex", alignItems: "center" } },
                                    React.createElement("img", { src: cross, className: "reschedul-error-img" }),
                                    React.createElement("span", { className: "reschedul-error-text" }, t("Contact SqueezMeln to make further bookings"))))),
                            React.createElement("div", { className: "Puppet" }, (_a = this.state.service_provider_res) === null || _a === void 0 ? void 0 : _a.map((service_provider) => {
                                var _a, _b, _c, _d;
                                return (React.createElement("div", { className: "service_provided_main_container", key: (_a = service_provider === null || service_provider === void 0 ? void 0 : service_provider.attributes) === null || _a === void 0 ? void 0 : _a.id },
                                    React.createElement("div", { className: "service_provided_image_container" },
                                        React.createElement("img", { "data-testid": "model_popup01_women", src: (_b = service_provider === null || service_provider === void 0 ? void 0 : service_provider.attributes) === null || _b === void 0 ? void 0 : _b.icon, style: { cursor: "pointer" }, alt: `${(_c = service_provider === null || service_provider === void 0 ? void 0 : service_provider.attributes) === null || _c === void 0 ? void 0 : _c.name} icon`, onClick: () => {
                                                var _a, _b, _c, _d;
                                                if (((_a = this.state.service_provider_res) === null || _a === void 0 ? void 0 : _a.length) !== 0) {
                                                    this.setState({
                                                        model_popup01: true,
                                                        check_gender: ((_b = service_provider === null || service_provider === void 0 ? void 0 : service_provider.attributes) === null || _b === void 0 ? void 0 : _b.name) || "",
                                                        show_gender: ((_c = service_provider === null || service_provider === void 0 ? void 0 : service_provider.attributes) === null || _c === void 0 ? void 0 : _c.display_name) || "",
                                                    });
                                                    this.setGenderForModal(((_d = service_provider === null || service_provider === void 0 ? void 0 : service_provider.attributes) === null || _d === void 0 ? void 0 : _d.name) || "");
                                                }
                                            } })),
                                    React.createElement("div", { className: "customer-home-screen-service-type-text" }, (_d = service_provider === null || service_provider === void 0 ? void 0 : service_provider.attributes) === null || _d === void 0 ? void 0 : _d.display_name)));
                            })),
                            React.createElement("div", { className: "Squeeze_image_big_second" }, this.state.shouldRenderCarousel &&
                                this.state.get_api_data ? (React.createElement(Carousel, { showArrows: false, showThumbs: false, showStatus: false, axis: "horizontal", autoPlay: true, infiniteLoop: true, interval: 5000 }, this.state.get_api_data.map((element) => {
                                return (React.createElement("div", { key: element.id + "lower", id: "lower_slider-div", style: { cursor: "pointer" }, onClick: () => {
                                        this.props.history.push({
                                            pathname: "/CustomerSubcategoryDetail",
                                            state: {
                                                gender: "",
                                                subCategory: "",
                                                show_gender: "",
                                                id: "",
                                                subId: element.attributes.business_profile_id,
                                            },
                                        });
                                    } },
                                    React.createElement("img", { src: element.attributes.banner, style: {
                                            width: "100%",
                                            height: "280px",
                                            objectFit: "fill",
                                            borderRadius: "24px",
                                        } })));
                            }))) : ("")))),
                    React.createElement(FooterWeb, null)),
                React.createElement(Dialog, { onClose: () => this.setState({ model_popup01: false }), "aria-labelledby": "simple-dialog-title", open: this.state.model_popup01, className: "buisness-dialog-modal" },
                    React.createElement("div", { className: "first-title-div" },
                        React.createElement("h1", null, t(this.state.show_gender)),
                        React.createElement("img", { src: close_modal_icon, alt: "", id: "close_dialog_img", onClick: () => this.setState({ model_popup01: false }) })),
                    this.state.isActive === false && (React.createElement("div", { className: "reschedul-error-container reschedul-error" },
                        React.createElement("span", { style: { display: "flex", alignItems: "center" } },
                            React.createElement("img", { src: cross, className: "reschedul-error-img" }),
                            React.createElement("span", { className: "reschedul-error-text reschedul-text" }, t("Contact SqueezMeln to make further bookings"))))),
                    React.createElement("div", { className: "items_of_business" }, (_b = this.state.service_provider_gender) === null || _b === void 0 ? void 0 : _b.map((element, serviceindex) => {
                        var _a, _b, _c, _d, _e, _f;
                        return (React.createElement("div", { key: JSON.stringify(serviceindex) },
                            React.createElement("h1", null, t((_a = element === null || element === void 0 ? void 0 : element.attributes) === null || _a === void 0 ? void 0 : _a.display_name)),
                            React.createElement("img", { src: ((_b = element === null || element === void 0 ? void 0 : element.attributes) === null || _b === void 0 ? void 0 : _b.business_types_images) &&
                                    ((_d = (_c = element === null || element === void 0 ? void 0 : element.attributes) === null || _c === void 0 ? void 0 : _c.business_types_images) === null || _d === void 0 ? void 0 : _d.length) !=
                                        0
                                    ? (_e = element === null || element === void 0 ? void 0 : element.attributes) === null || _e === void 0 ? void 0 : _e.business_types_images[0] : "", id: "main_dialog_img", alt: `${(_f = element === null || element === void 0 ? void 0 : element.attributes) === null || _f === void 0 ? void 0 : _f.business_type} icon`, onClick: () => {
                                    var _a;
                                    this.props.history.push({
                                        pathname: "/CustomerSubcategory",
                                        state: {
                                            gender: this.state.check_gender,
                                            show_gender: this.state.show_gender,
                                            subCategory: (_a = element === null || element === void 0 ? void 0 : element.attributes) === null || _a === void 0 ? void 0 : _a.business_type,
                                            id: element.id,
                                        },
                                    });
                                } })));
                    }))),
                React.createElement(Modal, { id: "cookiesModal", open: this.state.openCookieModal, className: "cookies-modal", onClose: () => this.setState({ openCookieModal: !this.state.openCookieModal }) },
                    React.createElement("div", { className: "cookie-container" },
                        React.createElement("div", { className: "cookie-header" }, t("Cookies Consent")),
                        React.createElement("div", { className: "cookie-content" }, t("This website use cookies to help you have a superior and more admissible browsing experience on the website.")),
                        React.createElement("div", { className: "cookie-button-container" },
                            React.createElement(Button, { className: "cookie-accept-button", id: "accept", variant: "contained", color: "primary", style: { width: "100%", marginTop: 1 }, onClick: this.addCookies }, t("Accept")),
                            React.createElement(Button, { className: "cookie-decline-button", id: "decline", variant: "outlined", color: "secondary", style: { width: "100%", marginTop: 1 }, onClick: this.deniedCookies }, t("Decline"))))))));
        // Customizable Area End
    }
}
//@ts-ignore
export default withTranslation()(withRouter(CustomerHomeScreen));
