var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getApp } from "firebase/app";
// Customizable Area End
export const configJSON = require("./config");
export default class AnalyticsController extends BlockComponent {
    // Customizable Area End
    constructor(props) {
        super(props);
        // Customizable Area Start
        this.getAnalyticsApiId = "";
        // Customizable Area Start
        this.txtInputWebProps = {
            onChangeText: (text) => {
                this.setState({ txtInputValue: text });
            },
            secureTextEntry: false
        };
        this.txtInputMobileProps = Object.assign(Object.assign({}, this.txtInputWebProps), { autoCompleteType: "email", keyboardType: "email-address" });
        this.btnExampleProps = {
            onPress: () => this.doButtonPressed()
        };
        this.getTextColor = (selectionMode, weekType) => {
            return selectionMode === weekType ? "white" : "black";
        };
        this.getBackGroundColor = (selectionMode, weekType) => {
            return selectionMode === weekType ? "#1e5fea" : undefined;
        };
        this.getAnalyticsApi = () => {
            const header = {
                "Content-Type": configJSON.validationApiContentType,
                token: localStorage.getItem("token") || "",
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getAnalyticsApiId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getAnalyticsEndPoint);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.handleReceive = (response) => {
            this.setState({
                analyticsData: response,
                currentData: response.current_week[0],
                pastData: response.past_week[0]
            });
            const currentOnline = Object.values(response.current_week[0].per_date_count.online_bookings).map((online) => {
                return online;
            });
            const pastOnline = Object.values(response.past_week[0].per_date_count.online_bookings).map((online) => {
                return online;
            });
            // Update ChartDataThisWeek state with the formatted sales data
            this.setState((prevState) => ({
                ChartDataThisWeek: Object.assign(Object.assign({}, prevState.ChartDataThisWeek), { options: Object.assign(Object.assign({}, prevState.ChartDataThisWeek.options), { series: [
                            {
                                name: "Online",
                                type: "line",
                                data: currentOnline,
                            },
                        ] }) }),
                ChartDataLastWeek: Object.assign(Object.assign({}, prevState.ChartDataLastWeek), { options: Object.assign(Object.assign({}, prevState.ChartDataLastWeek.options), { series: [
                            {
                                name: "Online",
                                type: "line",
                                data: pastOnline,
                            },
                        ] }) }),
            }));
            this.manageTopBookingsData(response.current_week[0].top_bookings, response.past_week[0].top_bookings);
        };
        this.manageTopBookingsData = (currentBookings, pastBookings) => {
            let curraentBookingData = [];
            let pastBookingData = [];
            Object.entries(currentBookings).forEach(([key, value]) => {
                if (key !== "") {
                    curraentBookingData.push({
                        serviceName: key,
                        serviceValue: value,
                    });
                }
            });
            Object.entries(pastBookings).forEach(([key, value]) => {
                if (key !== "") {
                    pastBookingData.push({
                        serviceName: key,
                        serviceValue: value,
                    });
                }
            });
            this.setState({ currentTopBookings: curraentBookingData, pastTopBookings: pastBookingData });
        };
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            txtInputValue: "",
            getSelectionMode: "thisWeek",
            analyticsData: {},
            currentData: {},
            pastData: {},
            currentTopBookings: [],
            pastTopBookings: [],
            ChartDataThisWeek: {
                options: {
                    colors: ["#737373"],
                    series: [
                        {
                            name: "Online",
                            type: "line",
                            data: [],
                        },
                    ],
                    legend: {
                        floating: true,
                        show: true,
                        onItemClick: {
                            toggleDataSeries: true,
                        },
                        horizontalAlign: "right",
                        offsetY: 0,
                        position: "top",
                        offsetX: 60,
                    },
                    grid: {
                        strokeDashArray: 0,
                        show: true,
                        xaxis: {
                            lines: {
                                show: false,
                            },
                        },
                        position: "back",
                        yaxis: {
                            lines: {
                                show: false,
                            },
                        },
                    },
                    xaxis: {
                        axisTicks: {
                            show: false,
                        },
                        labels: {
                            show: false,
                        },
                        axisBorder: {
                            show: false,
                        },
                    },
                    yaxis: {
                        axisTicks: {
                            show: false,
                        },
                        labels: {
                            show: false,
                        },
                        axisBorder: {
                            show: false,
                        },
                    },
                    chart: {
                        type: "line",
                        height: 350,
                        style: {
                            position: "absolute",
                        },
                        toolbar: {
                            show: false,
                        },
                    },
                    stroke: {
                        width: 2,
                        curve: "smooth",
                    },
                    tooltip: {
                        x: {
                            format: "dd/MM/yy HH:mm",
                        },
                    },
                    dataLabels: {
                        enabled: false,
                    },
                },
            },
            ChartDataLastWeek: {
                options: {
                    series: [
                        {
                            name: "Online",
                            type: "line",
                            data: [],
                        },
                    ],
                    colors: ["#737373"],
                    legend: {
                        show: true,
                        floating: true,
                        horizontalAlign: "right",
                        onItemClick: {
                            toggleDataSeries: true,
                        },
                        position: "top",
                        offsetY: 0,
                        offsetX: 60,
                    },
                    grid: {
                        show: true,
                        strokeDashArray: 0,
                        position: "back",
                        xaxis: {
                            lines: {
                                show: false,
                            },
                        },
                        yaxis: {
                            lines: {
                                show: false,
                            },
                        },
                    },
                    yaxis: {
                        labels: {
                            show: false,
                        },
                        axisTicks: {
                            show: false,
                        },
                        axisBorder: {
                            show: false,
                        },
                    },
                    xaxis: {
                        labels: {
                            show: false,
                        },
                        axisTicks: {
                            show: false,
                        },
                        axisBorder: {
                            show: false,
                        },
                    },
                    chart: {
                        height: 350,
                        type: "line",
                        toolbar: {
                            show: false,
                        },
                        style: {
                            position: "absolute",
                        },
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    stroke: {
                        curve: "smooth",
                        width: 2,
                    },
                    tooltip: {
                        x: {
                            format: "dd/MM/yy HH:mm",
                        },
                    },
                },
            },
        };
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
        const app = getApp();
        if (app) {
            const analytics = getAnalytics(app);
            logEvent(analytics, "Analytics::Web::Load");
        }
        // Customizable Area End
    }
    receive(from, message) {
        return __awaiter(this, void 0, void 0, function* () {
            runEngine.debugLog("Message Recived", message);
            // Customizable Area Start
            if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
                const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
                const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
                if (apiRequestCallId === this.getAnalyticsApiId && responseJson) {
                    this.handleReceive(responseJson);
                }
            }
            else {
                runEngine.debugLog("GOIT");
            }
            // Customizable Area End
        });
    }
    doButtonPressed() {
        return __awaiter(this, void 0, void 0, function* () {
            const app = getApp();
            const analytics = getAnalytics(app);
            logEvent(analytics, "Analytics::Web::button_clicked");
        });
    }
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            let userLoggedIn = localStorage.getItem("token") || "";
            let user = localStorage.getItem("userType") || "";
            if (user != "business" || !userLoggedIn) {
                localStorage.clear();
                window.location.replace("/LandingPage");
            }
            else {
                this.getAnalyticsApi();
            }
        });
    }
}
