// Customizable Area Start
import React from "react";
import "../assets/Styles/manage-business.css";
import { defultprofile } from "../../dashboard/src/assets";
import { astar, dstar } from "../../appointmentmanagement/src/assets";
import BusinessManageReviewsWebController from "./BusinessManageReviewsWebController";
import { withTranslation } from "react-i18next";
const Cookies = require("js-cookie");
class ReviewsListing extends BusinessManageReviewsWebController {
    render() {
        let { t } = this.props;
        let webAppDirection = localStorage.getItem("webAppDirection") ||
            Cookies.get("webAppDirection") ||
            "ltr";
        return (React.createElement("div", { className: webAppDirection === "ltr"
                ? "manage-catalouge-content-container"
                : "arabic-manage-catalouge-content-container" },
            React.createElement("span", { className: "manage-catalouge-header-text" }, t("Reviews")),
            this.state.allReviews.length === 0 && (React.createElement("div", { className: "add-new-categories-message-text" }, t("No reviews found"))),
            this.state.allReviews.length > 0 &&
                this.state.allReviews.map((review) => {
                    return (React.createElement("div", { className: "manage-reviews-listing-details-container", key: review.id },
                        React.createElement("div", { className: "review-detail-giver-container" },
                            React.createElement("div", { className: "manage-review-image-name-date-container" },
                                React.createElement("img", { src: review.attributes.reviewer.data.attributes
                                        .profile_image || defultprofile, alt: "aa" }),
                                React.createElement("div", { className: "manage-review-name-date-container " },
                                    React.createElement("div", { className: "manage-reviews-name-text-style" }, `${review.attributes.reviewer.data.attributes
                                        .full_name
                                        ? review.attributes.reviewer.data.attributes
                                            .full_name
                                        : `${review.attributes.reviewer.data.attributes.first_name} ${review.attributes.reviewer.data.attributes.last_name}`}`),
                                    React.createElement("div", { className: "manage-reviews-date-text-style" }, `\u202A${t(review.attributes.review_date)}\u202C`))),
                            React.createElement("div", { className: "manage-reviews-given-star-container" }, this.state.ratingStars.map((rating) => {
                                return (React.createElement("img", { src: rating <= review.attributes.rating ? astar : dstar, alt: "star", className: "rating-star-image", key: rating }));
                            }))),
                        React.createElement("div", { className: "manage-reviews-description-text-style" }, review.attributes.description),
                        React.createElement("div", { className: "manage-reviews-employee-name-text-style" }, `${t("Employee :")} ${review.attributes.employee_name}`),
                        React.createElement("div", { className: "manage-reviews-booking-code-text-style" }, `${t("Booking ID :")} ${review.attributes.booking_id}`)));
                })));
    }
}
export default withTranslation()(ReviewsListing);
// Customizable Area End
