var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// Customizable Area Start
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
export const configJSON = require("./config");
let responseData;
let w = window.innerWidth;
export default class PerformanceTrackerWebController extends BlockComponent {
    constructor(props) {
        super(props);
        this.handleReceivePreformanceData = (responseJson) => {
            responseData = responseJson;
            this.setState(Object.assign(Object.assign({}, this.state), { teamMemberPerformance: responseJson, page: "performance", getSelectionMode: "thisWeek" }));
            const dayOrder = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
            const salesData = dayOrder.map((day) => {
                return responseData.current_week.sales[day] || 0;
            });
            const pastSalesData = dayOrder.map((day) => {
                return responseData.past_week.sales[day] || 0;
            });
            // Update ChartDataThisWeek state with the formatted sales data
            this.setState(prevState => ({
                ChartDataThisWeek: Object.assign(Object.assign({}, prevState.ChartDataThisWeek), { series: [
                        {
                            data: salesData,
                            name: "Employee performance"
                        }
                    ] }),
                ChartDataLastWeek: Object.assign(Object.assign({}, prevState.ChartDataLastWeek), { series: [
                        {
                            data: pastSalesData,
                            name: "Employee performance"
                        }
                    ] })
            }));
        };
        this.handleReceive = (responseJson) => {
            this.setState(Object.assign(Object.assign({}, this.state), { allTeamMembers: responseJson.data }));
        };
        this.handleBack = () => {
            this.setState({ page: "employee" });
        };
        this.getTextColor = (selectionMode, weekType) => {
            return selectionMode === weekType ? "white" : "black";
        };
        this.getBackGroundColor = (selectionMode, weekType) => {
            return selectionMode === weekType ? "#1e5fea" : undefined;
        };
        this.getTeamMemberListApi = () => {
            const header = {
                "Content-Type": configJSON.validationApiContentType,
                token: localStorage.getItem("token") || "",
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getTeamMemberListCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getTeamMembersListEndpoint);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.getTeamMemberPerformanceApi = (member) => {
            this.setState({ fullName: member.first_name + " " + member.last_name });
            const header = {
                "Content-Type": configJSON.validationApiContentType,
                token: localStorage.getItem("token") || "",
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getTeamMemberPerformanceId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getTeamMemberPerformanceEndPoint + member.employee_account_id);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.handleMemberSearch = (value) => {
            if (value === "") {
                this.getTeamMemberListApi();
            }
            const searchedTeamMember = this.state.allTeamMembers.filter((member) => {
                return (`${member.attributes.first_name} ${member.attributes.last_name}`
                    .replace(/\s{2,}/g, " ")
                    .toLowerCase()
                    .includes(value.toLowerCase()) ||
                    `${member.attributes.email_address}`
                        .toLowerCase()
                        .includes(value.toLowerCase()));
            });
            this.setState(Object.assign(Object.assign({}, this.state), { searchValue: value, allTeamMembers: searchedTeamMember }));
        };
        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            loading: false,
            allTeamMembers: [],
            tempAllTeamMembers: [],
            searchValue: "",
            page: "employee",
            editableMemberId: null,
            unsubscribedUserModal: false,
            teamMemberPerformance: {},
            getSelectionMode: "thisWeek",
            fullName: "",
            ChartDataThisWeek: {
                options: {
                    plotOptions: {
                        bar: {
                            columnWidth: 80,
                            dataLabels: {
                                position: "top",
                            },
                        },
                    },
                    dataLabels: {
                        enabled: true,
                        formatter: function (val, opts) {
                            const dayIndex = opts.dataPointIndex;
                            const dayLabels = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
                            const dayOfWeek = dayLabels[dayIndex];
                            const salesValue = responseData.current_week.sales[dayOfWeek];
                            let numericValue = parseInt(salesValue);
                            return "OMR" + " " + numericValue;
                        },
                        offsetY: -30,
                        style: {
                            colors: ["#191D32"],
                            textAlign: "right",
                            fontFamily: "Poppins-Medium",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "24px" /* 171.429% */,
                            marginBottom: "20px"
                        },
                    },
                    xaxis: {
                        categories: ["Sun", "Mon", "Tues", "Wed", "Thur", "Fri", "Sat"],
                        labels: {
                            offsetY: 0,
                        },
                        position: "bottom",
                        axisTicks: {
                            show: false,
                        },
                        axisBorder: {
                            show: false,
                        },
                        crosshairs_: {
                            fill: {
                                gradient: {
                                    colorTo: "#BED1E6",
                                    colorFrom: "#D8E3F0",
                                    opacityTo: 0.5,
                                    opacityFrom: 0.4,
                                    stops: [0, 100],
                                },
                                type: "gradient",
                            },
                        },
                        tooltip: {
                            offsetY: -35,
                            enabled: false,
                        },
                    },
                    grid: {
                        strokeDashArray: 0,
                        position: "back",
                        show: true,
                        xaxis: {
                            lines: {
                                show: false,
                            },
                        },
                        yaxis: {
                            lines: {
                                show: false,
                            },
                        },
                    },
                    fill: {
                        gradient: {
                            type: "horizontal",
                            shade: "light",
                            gradientToColors: undefined,
                            shadeIntensity: 0.25,
                            opacityFrom: 1,
                            inverseColors: true,
                            stops: [50, 0, 100, 100],
                            opacityTo: 1,
                        },
                    },
                    chart: {
                        width: 70,
                        animations: {
                            enabled: true,
                        },
                        toolbar: {
                            show: false,
                        },
                    },
                    yaxis: {
                        labels: {
                            show: false,
                        },
                        axisTicks: {
                            show: false,
                        },
                        axisBorder: {
                            show: false,
                        },
                    },
                    colors: ["#3D40C3"],
                    stroke: {
                        width: 1,
                        show: true,
                    },
                },
                series: [
                    {
                        data: [],
                        name: "Employee performance",
                    },
                ],
            },
            ChartDataLastWeek: {
                options: {
                    plotOptions: {
                        bar: {
                            columnWidth: 80,
                            dataLabels: {
                                position: "top",
                            },
                        },
                        style: {}
                    },
                    dataLabels: {
                        enabled: true,
                        offsetY: -30,
                        formatter: function (val, opts) {
                            const dayIndex = opts.dataPointIndex;
                            const dayLabels = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
                            const dayOfWeek = dayLabels[dayIndex];
                            const salesValue = responseData.past_week.sales[dayOfWeek];
                            let numericValue = parseInt(salesValue);
                            return "OMR" + " " + numericValue;
                        },
                        style: {
                            textAlign: "right",
                            fontFamily: "Poppins-Medium",
                            fontSize: "14px",
                            colors: ["#191D32"],
                            fontStyle: "normal",
                            lineHeight: "24px" /* 171.429% */,
                            fontWeight: 500,
                            margin: "5px !important",
                        },
                    },
                    xaxis: {
                        position: "bottom",
                        categories: ["Sun", "Mon", "Tues", "Wed", "Thur", "Fri", "Sat"],
                        axisBorder: {
                            show: false,
                        },
                        labels: {
                            offsetY: 0,
                        },
                        axisTicks: {
                            show: false,
                        },
                        crosshairs_: {
                            fill: {
                                type: "gradient",
                                gradient: {
                                    colorTo: "#BED1E6",
                                    colorFrom: "#D8E3F0",
                                    stops: [0, 100],
                                    opacityTo: 0.5,
                                    opacityFrom: 0.4,
                                },
                            },
                        },
                        tooltip: {
                            enabled: false,
                            offsetY: -35,
                        },
                    },
                    fill: {
                        gradient: {
                            shade: "light",
                            type: "horizontal",
                            gradientToColors: undefined,
                            shadeIntensity: 0.25,
                            opacityTo: 1,
                            inverseColors: true,
                            opacityFrom: 1,
                            stops: [50, 0, 100, 100],
                        },
                    },
                    grid: {
                        strokeDashArray: 0,
                        show: true,
                        position: "back",
                        xaxis: {
                            lines: {
                                show: false,
                            },
                        },
                        yaxis: {
                            lines: {
                                show: false,
                            },
                        },
                    },
                    yaxis: {
                        axisBorder: {
                            show: false,
                        },
                        axisTicks: {
                            show: false,
                        },
                        labels: {
                            show: false,
                        },
                    },
                    chart: {
                        animations: {
                            enabled: true,
                        },
                        width: 70,
                        toolbar: {
                            show: false,
                        },
                    },
                    colors: ["#3D40C3"],
                    stroke: {
                        show: true,
                        width: 1,
                    },
                },
                series: [
                    {
                        name: "Employee performance",
                        data: [],
                    },
                ],
            },
        };
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            let token = localStorage.getItem("token") || "";
            let user = localStorage.getItem("userType") || "";
            if (user != "business" || !token) {
                localStorage.clear();
                window.location.replace("/LandingPage");
            }
            else {
                this.getTeamMemberListApi();
            }
        });
    }
    receive(from, message) {
        return __awaiter(this, void 0, void 0, function* () {
            if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
                const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
                const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
                if (apiRequestCallId === this.getTeamMemberListCallId) {
                    this.handleReceive(responseJson);
                }
                if (apiRequestCallId === this.getTeamMemberPerformanceId) {
                    this.handleReceivePreformanceData(responseJson);
                }
            }
            else {
                runEngine.debugLog("GOIT");
            }
        });
    }
}
// Customizable Area End
