// Customizable Area Start
import React from "react";
import CustomerSubcategoryScreenController from "./CustomerSubcategoryScreenController.web";
import { dot_fitness, star_rate } from "../../appointmentmanagement/src/assets";
import "../assets/style/CategoryListComponent.web.scss";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
const Cookies = require("js-cookie");
// Customizable Area End
class CategoryListComponent extends CustomerSubcategoryScreenController {
    // Customizable Area Start
    // Customizable Area End
    render() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5;
        // Customizable Area Start
        let { t } = this.props;
        let webAppDirection = localStorage.getItem("webAppDirection") ||
            Cookies.get("webAppDirection") ||
            "ltr";
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "fitness-main-div", onClick: () => {
                    var _a, _b, _c, _d, _e, _f;
                    this.props.history.push({
                        pathname: "/CustomerSubcategoryDetail",
                        state: {
                            gender: (_b = (_a = this.props.data.attributes) === null || _a === void 0 ? void 0 : _a.service_provided) === null || _b === void 0 ? void 0 : _b.name,
                            show_gender: (_d = (_c = this.props.data.attributes) === null || _c === void 0 ? void 0 : _c.service_provided) === null || _d === void 0 ? void 0 : _d.display_name,
                            subCategory: (_f = (_e = this.props.data.attributes) === null || _e === void 0 ? void 0 : _e.business_type) === null || _f === void 0 ? void 0 : _f.business_type,
                            id: this.state.categoryId,
                            subId: this.props.data.id,
                        },
                    });
                } },
                React.createElement("img", { src: ((_a = this.props.data.attributes) === null || _a === void 0 ? void 0 : _a.images) && ((_b = this.props.data.attributes) === null || _b === void 0 ? void 0 : _b.images.data)
                        ? (_f = (_e = (_d = (_c = this.props.data.attributes) === null || _c === void 0 ? void 0 : _c.images) === null || _d === void 0 ? void 0 : _d.data[0]) === null || _e === void 0 ? void 0 : _e.attributes) === null || _f === void 0 ? void 0 : _f.image : "", className: "beast-up-img", alt: t("no image available") }),
                React.createElement("div", { className: "fitness_logo-div" },
                    React.createElement("img", { src: dot_fitness, alt: "" }),
                    "\u00A0 \u00A0 ",
                    React.createElement("h1", null, t((_h = (_g = this.props.data.attributes) === null || _g === void 0 ? void 0 : _g.business_type) === null || _h === void 0 ? void 0 : _h.business_type))),
                React.createElement("div", { className: "beast-up-details-div" },
                    React.createElement("h1", null, (_l = (_k = (_j = this.props) === null || _j === void 0 ? void 0 : _j.data) === null || _k === void 0 ? void 0 : _k.attributes) === null || _l === void 0 ? void 0 : _l.display_business_name),
                    React.createElement("p", { style: {
                            marginLeft: webAppDirection === "ltr" ? "auto" : "0",
                            marginRight: webAppDirection === "rtl" ? "auto" : "0",
                        } }, t((_p = (_o = (_m = this.props.data) === null || _m === void 0 ? void 0 : _m.attributes) === null || _o === void 0 ? void 0 : _o.service_provided) === null || _p === void 0 ? void 0 : _p.name))),
                React.createElement("div", { className: "location-beast-div" },
                    React.createElement("h1", null, ((_q = this.props.data.attributes) === null || _q === void 0 ? void 0 : _q.location) ? ((_s = (_r = this.props.data.attributes) === null || _r === void 0 ? void 0 : _r.location) === null || _s === void 0 ? void 0 : _s.address) +
                        " " + ((_u = (_t = this.props.data.attributes) === null || _t === void 0 ? void 0 : _t.location) === null || _u === void 0 ? void 0 : _u.landmark) +
                        " " + ((_w = (_v = this.props.data.attributes) === null || _v === void 0 ? void 0 : _v.location) === null || _w === void 0 ? void 0 : _w.city) +
                        " " + ((_y = (_x = this.props.data.attributes) === null || _x === void 0 ? void 0 : _x.location) === null || _y === void 0 ? void 0 : _y.pincode) +
                        " " + ((_0 = (_z = this.props.data.attributes) === null || _z === void 0 ? void 0 : _z.location) === null || _0 === void 0 ? void 0 : _0.state) +
                        " " + ((_2 = (_1 = this.props.data.attributes) === null || _1 === void 0 ? void 0 : _1.location) === null || _2 === void 0 ? void 0 : _2.country)
                        : t("No location available"))),
                React.createElement("div", { className: "rating-details-div" },
                    React.createElement("img", { src: star_rate, alt: "" }),
                    "\u00A0",
                    React.createElement("h1", null, (_3 = this.props.data.attributes) === null || _3 === void 0 ? void 0 : _3.reviews),
                    "\u00A0",
                    React.createElement("p", null,
                        "(",
                        t("reviews"),
                        ")"),
                    React.createElement("h4", { style: {
                            marginLeft: webAppDirection === "ltr" ? "auto" : "0",
                            marginRight: webAppDirection === "rtl" ? "auto" : "0",
                        } }, ((_4 = this.props.data.attributes) === null || _4 === void 0 ? void 0 : _4.destance) &&
                        `${(_5 = this.props.data.attributes) === null || _5 === void 0 ? void 0 : _5.destance} ${t("Km")}`)))));
        // Customizable Area End
    }
}
//@ts-ignore
export default withTranslation()(withRouter(CategoryListComponent));
