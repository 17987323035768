import React from "react";
import { OutlinedInput, Button, IconButton, InputAdornment, Grid, styled, Box, Dialog, Radio, DialogTitle, DialogContent, FormControl, Paper, } from "@material-ui/core";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { uploadImage, cross, countryCodeSearchIcon, img7f284a623dad0db2fed9fbe2e3d55e6af5f0cd63, ArrowDown, } from "./assets";
import "../assets/Styles/Userprofile.css";
import "../assets/Styles/AddTeamMembers.css";
import AfterLoginHeader from "../../dashboard/src/AfterLoginHeader.web";
import AddTeamMembersController from "./AddTeamMembersController.web";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import CountryCodeModal from "../../social-media-account-login/src/CountryCodeModal.web";
import { withTranslation } from "react-i18next";
const Cookies = require("js-cookie");
import Loader from "../../../components/src/Loader.web";
const Item = styled(Paper)(({ theme }) => (Object.assign(Object.assign({}, theme.typography.body2), { padding: theme.spacing(1), textAlign: "center", color: theme.palette.text.secondary, background: "none" })));
class AddTeamMembers extends AddTeamMembersController {
    constructor(props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    render() {
        var _a;
        let { t } = this.props;
        let webAppDirection = localStorage.getItem("webAppDirection") ||
            Cookies.get("webAppDirection") ||
            "ltr";
        const { workingHoursError, weekendHoursError, selectedGenderError, selectedLanguageError, blockWorkingHoursError, blockWeekendHoursError, } = this.state;
        const borderStyleworkingHours = this.getErrorBorderStyle(workingHoursError);
        const borderStyleweekendHours = this.getErrorBorderStyle(weekendHoursError);
        const borderStyleGender = this.getErrorBorderStyle(selectedGenderError);
        const borderStyleLanguage = this.getErrorBorderStyle(selectedLanguageError);
        const borderStyleBlockTimeHours = this.getErrorBorderStyle(blockWorkingHoursError);
        const borderStyleBlockTimeWeekendHours = this.getErrorBorderStyle(blockWeekendHoursError);
        let arabicMarginRight = webAppDirection === "rtl" ? 0 : "";
        let marginLeftError = webAppDirection === "ltr" ? "6.5%" : "0";
        let marginRightError = webAppDirection === "rtl" ? "6.5%" : "0";
        let setupTeamDownClassName = webAppDirection === "ltr" ? "dropdown-image" : "arabic-dropdown-image";
        return (React.createElement(React.Fragment, null,
            React.createElement(Loader, { loading: this.state.isLoader }),
            React.createElement("div", { style: { overflow: "scroll", backgroundColor: "#F2F2F2" } },
                React.createElement("div", null,
                    React.createElement(AfterLoginHeader, { navigation: this.props.navigation })),
                React.createElement("div", { style: { width: "100%", padding: "4% 10%" } },
                    React.createElement("span", { className: "main-lable" }, t("Add Team Member")),
                    React.createElement(Grid, { container: true, spacing: 2 },
                        React.createElement(Grid, { item: true, xs: 12, sm: 6 },
                            React.createElement(Item, { style: { display: "flex", justifyContent: "center" } },
                                React.createElement(Paper, { style: {
                                        border: 0,
                                        borderTop: 0,
                                        borderRadius: 0,
                                        backgroundColor: "#FFFFFF",
                                    }, className: "employment-card", variant: "outlined" },
                                    React.createElement("div", null,
                                        React.createElement("div", { className: "input-container" },
                                            React.createElement("div", { className: "team-image" }, this.state.profileImages.length <= 0 ? (React.createElement("label", { htmlFor: "img", className: "labelupload" },
                                                React.createElement("img", { src: uploadImage, alt: "logo", width: "50px", height: "50px" }),
                                                React.createElement("input", { "data-test-id": "fileUpload", type: "file", id: "img", name: "img", accept: "image/*", multiple: true, hidden: true, onChange: (event) => this.handleImage(event), value: this.state.profileImages }))) : (React.createElement("div", null,
                                                React.createElement("div", null,
                                                    React.createElement("img", { src: this.state.profileImages, alt: "temp", style: {
                                                            width: "120px",
                                                            height: "120px",
                                                            borderRadius: "185px",
                                                            objectFit: "cover",
                                                        } })))))),
                                        React.createElement("div", { className: "input-container" },
                                            React.createElement("span", { className: "form-label", style: { color: "#2C2C2E" } }, t("Title *")),
                                            React.createElement(OutlinedInput, { type: "text", value: this.state.title, "data-test-id": "title", className: "input-style-team", error: this.state.titleError, onChange: (event) => this.setTitleValue(event.target.value), inputProps: {
                                                    min: 0,
                                                    maxLength: 50,
                                                    style: {
                                                        outline: "none",
                                                        boxShadow: "none",
                                                    },
                                                }, style: {
                                                    marginRight: arabicMarginRight,
                                                } }),
                                            React.createElement("span", { className: "profile-error-message" }, t(this.renderErrorMessageforProfile(this.state.titleError)))),
                                        React.createElement("div", { className: "input-container" },
                                            React.createElement("span", { className: "form-label", style: { color: "#2C2C2E" } }, t("First name *")),
                                            React.createElement(OutlinedInput, { type: "text", style: {
                                                    marginRight: arabicMarginRight,
                                                }, value: this.state.firstName, "data-test-id": "firstName", className: "input-style-team", error: this.state.firstNameError, onChange: (event) => this.setInputValue(event.target.value), inputProps: {
                                                    min: 0,
                                                    maxLength: 25,
                                                    style: {
                                                        outline: "none",
                                                        boxShadow: "none",
                                                    },
                                                } }),
                                            React.createElement("span", { className: "profile-error-message" }, t(this.renderErrorMessageforProfile(this.state.firstNameError)))),
                                        React.createElement("div", { className: "input-container" },
                                            React.createElement("span", { className: "form-label", style: { color: "#2C2C2E" } }, t("Last name *")),
                                            React.createElement(OutlinedInput, { type: "text", "data-test-id": "lastName", value: this.state.lastName, style: {
                                                    marginRight: arabicMarginRight,
                                                }, error: this.state.lastNameError, onChange: (event) => {
                                                    if (/^[a-zA-Z ]*$/.test(event.target.value)) {
                                                        this.setState({
                                                            lastNameError: "",
                                                            lastName: event.target.value,
                                                        });
                                                    }
                                                }, className: "input-style-team", inputProps: {
                                                    min: 0,
                                                    maxLength: 25,
                                                    style: {
                                                        outline: "none",
                                                        boxShadow: "none",
                                                    },
                                                } }),
                                            React.createElement("span", { className: "profile-error-message" }, t(this.renderErrorMessageforProfile(this.state.lastNameError)))),
                                        React.createElement("div", { className: "input-container" },
                                            React.createElement("span", { className: "form-label", style: { color: "#2C2C2E" } }, t("Email Address *")),
                                            React.createElement(OutlinedInput, { type: "text", "data-test-id": "emailAddress", value: this.state.emailAddress, error: this.state.emailAddressError, style: {
                                                    marginRight: arabicMarginRight,
                                                }, onChange: (event) => {
                                                    if (/^[a-zA-Z0-9 !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]*$/.test(event.target.value)) {
                                                        this.setState({
                                                            emailAddress: event.target.value,
                                                            emailAddressError: "",
                                                        });
                                                    }
                                                }, className: "input-style-team", placeholder: t("Enter email address"), inputProps: {
                                                    min: 0,
                                                    style: {
                                                        outline: "none",
                                                        boxShadow: "none",
                                                    },
                                                } }),
                                            React.createElement("span", { className: "profile-error-message" }, t(this.renderErrorMessageforProfile(this.state.emailAddressError)))),
                                        React.createElement("div", { className: "input-container" },
                                            React.createElement("span", { className: "form-label", style: { color: "#2C2C2E", marginBottom: "16px" } }, t("Mobile number *")),
                                            React.createElement(OutlinedInput, { error: this.state.contactError, value: this.state.contact, onChange: (event) => {
                                                    if (/^$|^\d{0,14}$/.test(event.target.value)) {
                                                        this.setState({
                                                            contactError: "",
                                                            contact: event.target.value,
                                                        });
                                                    }
                                                }, type: "text", style: {
                                                    border: "1px solid",
                                                    borderRadius: "8px",
                                                    marginRight: arabicMarginRight,
                                                }, "data-test-id": "mobileNumber", className: "input-style-team-contact-number", name: "phoneNumber", inputProps: {
                                                    min: 0,
                                                    style: { outline: "none", boxShadow: "none" },
                                                }, startAdornment: React.createElement(InputAdornment, { position: "start" },
                                                    React.createElement(IconButton, { style: {
                                                            width: 66,
                                                            height: 48,
                                                            borderRight: webAppDirection === "ltr"
                                                                ? "1px solid #979797"
                                                                : "",
                                                            borderLeft: webAppDirection === "rtl"
                                                                ? "1px solid #979797"
                                                                : "",
                                                            cursor: "pointer",
                                                            justifyContent: "center",
                                                            borderRadius: "0%",
                                                        }, id: "countrycode", onClick: () => this.handleCountryCodeChange() },
                                                        React.createElement("span", { className: "country-code-text-style", style: {
                                                                color: "#2c2c2e",
                                                            } }, this.state.countryCode))) }),
                                            React.createElement("span", { className: "profile-error-message" }, t(this.renderErrorMessageforProfile(this.state.contactError)))),
                                        React.createElement("div", { className: "input-container" },
                                            React.createElement("span", { className: "form-label", style: { color: "#2C2C2E" } }, t("Gender *")),
                                            React.createElement("div", { style: { marginTop: "15px" } },
                                                React.createElement("div", { className: "custom-select-team" },
                                                    React.createElement("select", { id: "outlined-select", placeholder: t("Select gender"), "data-test-id": "gender", value: this.state.selectedGender, onChange: (event) => {
                                                            this.setGender(event.target.value);
                                                        }, onBlur: this.handleBlurOfSelect, onClick: () => {
                                                            this.handleSelectToggle("isSelectOpen");
                                                        }, style: {
                                                            border: borderStyleGender,
                                                            borderRadius: 8,
                                                            direction: "inherit",
                                                        } },
                                                        React.createElement("option", { value: "", disabled: true, selected: true, style: { color: "#999999 !important" } }, t("Select gender")),
                                                        this.state.gender.map((option) => (React.createElement("option", { style: {
                                                                fontFamily: "Poppins-Medium, sans-serif",
                                                            }, key: option.id, value: option.name }, t(option.name))))),
                                                    React.createElement("img", { src: this.handleArrow(this.state.isSelectOpen), className: setupTeamDownClassName, alt: "Custom Icon" })),
                                                React.createElement("span", { className: "profile-error-message" }, t(this.renderErrorMessageforProfile(this.state.selectedGenderError))))),
                                        React.createElement("div", { className: "input-container" },
                                            React.createElement("span", { className: "form-label", style: { color: "#2C2C2E", marginBottom: "10px" } }, t("Date of birth *")),
                                            React.createElement(FormControl, { variant: "outlined" },
                                                React.createElement(OutlinedInput, { style: {
                                                        border: "1px solid",
                                                        borderRadius: 8,
                                                        width: "100%",
                                                        fontFamily: "Poppins-Medium, sans-serif",
                                                    }, "data-test-id": "birthDate", type: "text", error: this.state.birthDateError, value: this.state.birthDate, placeholder: t("Select date"), onClick: () => {
                                                        this.setState({
                                                            openCalenderDialog: true,
                                                            modaltype: "birthDate",
                                                        });
                                                    } })),
                                            React.createElement("span", { className: "profile-error-message" }, t(this.renderErrorMessageforProfile(this.state.birthDateError)))),
                                        React.createElement("div", { className: "input-container" },
                                            React.createElement("span", { className: "form-label", style: { color: "#2C2C2E" } }, t("Preferred language")),
                                            React.createElement("div", { style: { marginTop: "15px" } },
                                                React.createElement("div", { className: "custom-select-team" },
                                                    React.createElement("select", { id: "outlined-select", placeholder: t("Select language"), "data-test-id": "language", onChange: (event) => {
                                                            this.setLanguage(event.target.value);
                                                        }, onBlur: this.handleBlurOfSelect, onClick: () => {
                                                            this.handleSelectToggle("isSelectLanguage");
                                                        }, style: {
                                                            border: borderStyleLanguage,
                                                            direction: "inherit",
                                                            borderRadius: 8,
                                                        }, value: this.state.selectedLanguage },
                                                        React.createElement("option", { value: "", disabled: true, selected: true, style: { color: "#999999" } }, t("Select language")),
                                                        this.state.language.map((option) => (React.createElement("option", { style: {
                                                                fontFamily: "Poppins-Medium, sans-serif",
                                                            }, key: option.id, value: option.name }, t(option.name))))),
                                                    React.createElement("img", { src: this.handleArrow(this.state.isSelectLanguage), className: setupTeamDownClassName, alt: "Custom Icon" })),
                                                React.createElement("span", { className: "profile-error-message" }, t(this.renderErrorMessageforProfile(this.state.selectedLanguageError))))),
                                        React.createElement("div", { className: "input-container", style: { marginBottom: 30 } },
                                            React.createElement("span", { className: "form-label", style: { color: "#2C2C2E", marginTop: 24 } }, t("About me (Optional)")),
                                            React.createElement(OutlinedInput, { value: this.state.aboutUsText, "data-test-id": "aboutMe", className: "input-style-team", style: {
                                                    border: "1px solid #757575",
                                                    marginTop: "20px",
                                                    height: "150px",
                                                    fontFamily: "Poppins, sans-serif",
                                                    flexDirection: "column",
                                                    marginRight: arabicMarginRight,
                                                }, multiline: true, rows: 4, onChange: (event) => this.setState({ aboutUsText: event.target.value }), placeholder: t("Write about yourself..."), type: "text", inputProps: {
                                                    min: 0,
                                                    maxLength: 100,
                                                    style: {
                                                        marginLeft: 5,
                                                        outline: "none",
                                                        boxShadow: "none",
                                                    },
                                                } })))))),
                        React.createElement(Grid, { item: true, xs: 12, sm: 6 },
                            React.createElement(Item, null,
                                React.createElement("div", null,
                                    React.createElement("span", { className: "form-label", style: { color: "#2C2C2E", fontSize: 20 } }, t("Employment")),
                                    React.createElement(Paper, { style: {
                                            border: 0,
                                            borderTop: 0,
                                            borderRadius: 0,
                                            backgroundColor: "#FFFFFF",
                                        }, className: "employment-card", variant: "outlined" },
                                        React.createElement("div", null,
                                            React.createElement("div", { className: "input-container" },
                                                React.createElement("span", { className: "form-label", style: { color: "#2C2C2E" } }, t("Start date")),
                                                React.createElement("div", { style: { marginTop: "15px", width: "100%" } },
                                                    React.createElement(FormControl, { variant: "outlined" },
                                                        React.createElement(OutlinedInput, { style: {
                                                                border: "1px solid",
                                                                borderRadius: 8,
                                                                width: "100%",
                                                                fontFamily: "Poppins-Medium, sans-serif",
                                                            }, "data-test-id": "startDate", type: "text", error: this.state.startDateError, placeholder: t("Select date"), value: this.state.startDate, onClick: () => {
                                                                this.setState({
                                                                    openCalenderDialog: true,
                                                                    modaltype: "startDate",
                                                                });
                                                            }, endAdornment: React.createElement(InputAdornment, { position: "end" },
                                                                React.createElement(IconButton, { "aria-label": "toggle password visibility", edge: "end" },
                                                                    React.createElement("img", { src: ArrowDown, alt: "type", width: "25px", height: "26px" }))) })),
                                                    React.createElement("span", { className: "profile-error-message" }, t(this.renderErrorMessageforProfile(this.state.startDateError)))))),
                                        React.createElement("div", null,
                                            React.createElement("div", { className: "input-container" },
                                                React.createElement("span", { className: "form-label", style: { color: "#2C2C2E" } }, t("End date")),
                                                React.createElement("div", { style: { marginTop: "15px", width: "100%" } },
                                                    React.createElement(FormControl, { variant: "outlined" },
                                                        React.createElement(OutlinedInput, { style: {
                                                                border: "1px solid",
                                                                borderRadius: 8,
                                                                width: "100%",
                                                                fontFamily: "Poppins-Medium, sans-serif",
                                                            }, "data-test-id": "endDate", type: "text", error: this.state.endDateError, value: t(this.state.endDate), placeholder: t("Select date"), onClick: () => {
                                                                this.setState({
                                                                    openCalenderDialog: true,
                                                                    modaltype: "endDate",
                                                                });
                                                            }, endAdornment: React.createElement(InputAdornment, { position: "end" },
                                                                React.createElement(IconButton, { "aria-label": "toggle password visibility", edge: "end" },
                                                                    React.createElement("img", { src: ArrowDown, alt: "type", width: "25px", height: "26px" }))) })),
                                                    React.createElement("span", { className: "profile-error-message" }, t(this.renderErrorMessageforProfile(this.state.endDateError)))))))),
                                React.createElement("div", null,
                                    React.createElement("span", { className: "form-label", style: { color: "#2C2C2E", fontSize: 20 } }, t("Business working hours")),
                                    React.createElement(Paper, { style: {
                                            border: 0,
                                            borderTop: 0,
                                            borderRadius: 0,
                                            display: "flex",
                                            justifyContent: "center",
                                        }, className: "papar-container", variant: "outlined" },
                                        React.createElement("div", { style: {
                                                width: "100%",
                                                display: "flex",
                                                justifyContent: "center",
                                            } },
                                            React.createElement("div", { className: "profile-business-working-hours-card-team", style: { border: "none", boxShadow: "none" } },
                                                React.createElement("div", { className: "input-container-left-team" },
                                                    React.createElement("div", { className: "input-container-button", style: { marginLeft: "0px" } },
                                                        React.createElement(ToggleButtonGroup, { style: {
                                                                width: "100%",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                marginRight: 20,
                                                            } },
                                                            React.createElement(ToggleButton, { style: {
                                                                    border: 0,
                                                                    borderRadius: 24,
                                                                    height: 44,
                                                                    textTransform: "none",
                                                                    fontSize: 14,
                                                                    color: this.getTextColor(this.state.getSelectionMode, "weekdays"),
                                                                    backgroundColor: this.getBackGroundColor(this.state.getSelectionMode, "weekdays"),
                                                                    fontWeight: 500,
                                                                }, value: "left", id: "btnSelect", "aria-label": "left aligned", onClick: () => {
                                                                    this.setState({
                                                                        getSelectionMode: "weekdays",
                                                                    });
                                                                }, className: "weekdays" },
                                                                React.createElement("span", { className: "fontfamily-profile" }, t("Weekdays"))),
                                                            React.createElement(ToggleButton, { style: {
                                                                    borderRadius: 24,
                                                                    fontSize: 14,
                                                                    border: 0,
                                                                    color: this.getTextColor(this.state.getSelectionMode, "weekends"),
                                                                    height: 44,
                                                                    fontWeight: 500,
                                                                    textTransform: "none",
                                                                    backgroundColor: this.getBackGroundColor(this.state.getSelectionMode, "weekends"),
                                                                    fontFamily: "Poppins",
                                                                }, "aria-label": "centered", onClick: () => {
                                                                    this.setState({
                                                                        getSelectionMode: "weekends",
                                                                    });
                                                                }, value: "center", id: "weekEnds" },
                                                                React.createElement("span", { className: "fontfamily-profile" }, t("Weekends"))))),
                                                    this.state.getSelectionMode === "weekdays" ? (React.createElement("div", { className: "item-time" },
                                                        React.createElement("div", { style: {
                                                                flex: "start",
                                                                display: "felx",
                                                                marginTop: 30,
                                                            } },
                                                            React.createElement("span", { className: "profile-form-sub-header", style: {
                                                                    marginRight: webAppDirection === "ltr"
                                                                        ? "65%"
                                                                        : "-65%",
                                                                } }, t("From *")),
                                                            React.createElement("div", { className: "hours-form-team", style: { marginTop: "0px" } },
                                                                React.createElement("div", { className: "form-custom-select", style: { marginTop: "16px" } },
                                                                    React.createElement("select", { id: "SelectWeekHours", onChange: (event) => {
                                                                            this.onWorkingFromSelection(event.target.value);
                                                                        }, onBlur: this.handleBlurOfSelect, onClick: () => {
                                                                            this.handleSelectToggle("isSelectOpenWeekHoursFrom");
                                                                        }, style: {
                                                                            border: borderStyleworkingHours,
                                                                            direction: "inherit",
                                                                            borderRadius: 8,
                                                                        }, value: this.state.workingFrom }, this.state.workingSlot.map((option, myNumber) => {
                                                                        let [time, amPm,] = this.showTimeInUppercase(option).split(" ");
                                                                        return (React.createElement("option", { value: option, key: myNumber, disabled: this.optionDisable(option), id: myNumber },
                                                                            time,
                                                                            " ",
                                                                            t(amPm)));
                                                                    })),
                                                                    React.createElement("img", { className: setupTeamDownClassName, src: this.handleArrow(this.state.isSelectOpenWeekHoursFrom), alt: "Custom Icon" })))),
                                                        React.createElement("div", { style: {
                                                                display: "felx",
                                                                marginTop: 30,
                                                                flex: "end",
                                                            } },
                                                            React.createElement("span", { className: "add-team-member-week-to", style: {
                                                                    marginRight: webAppDirection === "ltr"
                                                                        ? "76%"
                                                                        : "-76%",
                                                                } }, t("To *")),
                                                            React.createElement("div", { className: "hours-to", style: {
                                                                    marginTop: "0px",
                                                                    marginLeft: "0px",
                                                                } },
                                                                React.createElement("div", { className: "form-custom-select", style: { marginTop: "16px" } },
                                                                    React.createElement("select", { id: "mySelectTo", style: {
                                                                            direction: "inherit",
                                                                            borderRadius: 8,
                                                                            border: borderStyleworkingHours,
                                                                        }, value: this.state.workingTo, disabled: this.state.isWorkingToDisable, onBlur: this.handleBlurOfSelect, onClick: () => {
                                                                            this.handleSelectToggle("isSelectOpenWeekHoursTo");
                                                                        }, onChange: (event) => {
                                                                            this.setState({
                                                                                workingTo: event.target.value,
                                                                                workingHoursError: "",
                                                                            });
                                                                        } }, this.state.workingSlotTo.map((option, myId) => {
                                                                        let [time, amPm,] = this.showTimeInUppercase(option).split(" ");
                                                                        return (React.createElement("option", { key: myId, value: option, disabled: this.optionDisable(option), id: myId },
                                                                            time,
                                                                            " ",
                                                                            t(amPm)));
                                                                    })),
                                                                    React.createElement("img", { src: this.handleArrow(this.state.isSelectOpenWeekHoursTo), className: setupTeamDownClassName, alt: "Custom Icon" })))))) : (React.createElement("div", { className: "item-time" },
                                                        React.createElement("div", { style: {
                                                                marginTop: 30,
                                                                display: "felx",
                                                                flex: "start",
                                                            } },
                                                            React.createElement("span", { className: "profile-form-sub-header", style: {
                                                                    marginRight: webAppDirection === "ltr"
                                                                        ? "65%"
                                                                        : "-65%",
                                                                } }, t("From *")),
                                                            React.createElement("div", { className: "hours-form-team", style: { marginTop: "0px" } },
                                                                React.createElement("div", { className: "form-custom-select", style: { marginTop: "16px" } },
                                                                    React.createElement("select", { id: "mySelected", onChange: (event) => {
                                                                            this.onWeekendFromSelection(event.target.value);
                                                                        }, onBlur: this.handleBlurOfSelect, onClick: () => {
                                                                            this.handleSelectToggle("isSelectOpenWeekEndsFrom");
                                                                        }, value: this.state.weekendFrom, style: {
                                                                            direction: "inherit",
                                                                            borderRadius: 8,
                                                                            border: borderStyleweekendHours,
                                                                        } }, this.state.workingSlot.map((option, myNumberId) => {
                                                                        let [time, amPm,] = this.showTimeInUppercase(option).split(" ");
                                                                        return (React.createElement("option", { key: myNumberId, disabled: this.optionDisable(option), value: option, id: myNumberId },
                                                                            time,
                                                                            " ",
                                                                            t(amPm)));
                                                                    })),
                                                                    React.createElement("img", { src: this.handleArrow(this.state.isSelectOpenWeekEndsFrom), className: setupTeamDownClassName, alt: "Custom Icon" })))),
                                                        React.createElement("div", { style: {
                                                                flex: "start",
                                                                marginTop: 30,
                                                                display: "felx",
                                                            } },
                                                            React.createElement("span", { className: "add-team-member-week-to", style: {
                                                                    marginRight: webAppDirection === "ltr"
                                                                        ? "76%"
                                                                        : "-76%",
                                                                } }, t("To *")),
                                                            React.createElement("div", { className: "hours-to", style: {
                                                                    marginTop: "0px",
                                                                    marginLeft: "0px",
                                                                } },
                                                                React.createElement("div", { className: "form-custom-select", style: { marginTop: "16px" } },
                                                                    React.createElement("select", { disabled: this.state.isWeekendToDisable, id: "mySelecting", onChange: (event) => {
                                                                            this.setState({
                                                                                weekendTo: event.target.value,
                                                                                weekendHoursError: "",
                                                                            });
                                                                        }, value: this.state.weekendTo, style: {
                                                                            border: borderStyleweekendHours,
                                                                            borderRadius: 8,
                                                                            direction: "inherit",
                                                                        }, onBlur: this.handleBlurOfSelect, onClick: () => {
                                                                            this.handleSelectToggle("isSelectOpenWeekEndsTo");
                                                                        } }, this.state.weekendSlotTo.map((option, myNumber) => {
                                                                        let [time, amPm,] = this.showTimeInUppercase(option).split(" ");
                                                                        return (React.createElement("option", { id: myNumber, key: myNumber, disabled: this.optionDisable(option), value: option },
                                                                            time,
                                                                            " ",
                                                                            t(amPm)));
                                                                    })),
                                                                    React.createElement("img", { src: this.handleArrow(this.state.isSelectOpenWeekEndsTo), className: setupTeamDownClassName, alt: "Custom Icon" })))))),
                                                    React.createElement("div", { className: "item-time", style: {
                                                            flexDirection: "column",
                                                        } },
                                                        this.state.workingHoursError && (React.createElement("div", { style: {
                                                                marginBottom: 10,
                                                                marginLeft: marginLeftError,
                                                                marginRight: marginRightError,
                                                                position: "relative",
                                                            } },
                                                            React.createElement("span", { className: "profile-error-message" }, t(this.state.workingHoursError)))),
                                                        this.state.weekendHoursError && (React.createElement("div", { style: {
                                                                marginRight: marginRightError,
                                                                marginLeft: marginLeftError,
                                                                marginBottom: 10,
                                                                position: "relative",
                                                            } },
                                                            React.createElement("span", { className: "profile-error-message" }, t(this.state.weekendHoursError))))),
                                                    React.createElement("div", { className: "add-team-member-block-time", style: {
                                                            marginRight: webAppDirection === "ltr" ? "69%" : "-69%",
                                                        } }, t("Block time")),
                                                    this.state.getSelectionMode === "weekdays" ? (React.createElement(React.Fragment, null,
                                                        React.createElement("div", { className: "item-time", style: {
                                                                display: "flex",
                                                                alignItems: "center",
                                                            } },
                                                            React.createElement("div", { style: {
                                                                    flex: "start",
                                                                    marginTop: 30,
                                                                    display: "felx",
                                                                } },
                                                                React.createElement("span", { className: "profile-form-sub-header", style: {
                                                                        marginRight: webAppDirection === "ltr"
                                                                            ? "65%"
                                                                            : "-65%",
                                                                    } }, t("From *")),
                                                                React.createElement("div", { className: "hours-form-team", style: { marginTop: "0px" } },
                                                                    React.createElement("div", { className: "form-custom-select", style: { marginTop: "16px" } },
                                                                        React.createElement("select", { onChange: (event) => {
                                                                                this.onBlockFromSelection(event.target.value);
                                                                            }, id: "SelectWeekHour", onBlur: this.handleBlurOfSelect, onClick: () => {
                                                                                this.handleSelectToggle("isSelectOpenBlockTimeHoursFrom");
                                                                            }, value: this.state.blockTimeFrom, style: {
                                                                                direction: "inherit",
                                                                                border: borderStyleBlockTimeHours,
                                                                                borderRadius: 8,
                                                                            } }, this.state.workingSlot.map((option, myIndex) => {
                                                                            let [time, amPm,] = this.showTimeInUppercase(option).split(" ");
                                                                            return (React.createElement("option", { key: myIndex, id: myIndex, disabled: this.optionDisable(option), value: option },
                                                                                time,
                                                                                " ",
                                                                                t(amPm)));
                                                                        })),
                                                                        React.createElement("img", { src: this.handleArrow(this.state
                                                                                .isSelectOpenBlockTimeHoursFrom), className: setupTeamDownClassName, alt: "Custom Icon" })))),
                                                            React.createElement("div", { style: {
                                                                    display: "felx",
                                                                    marginTop: 30,
                                                                    flex: "start",
                                                                } },
                                                                React.createElement("span", { className: "profile-form-sub-header", style: {
                                                                        marginRight: webAppDirection === "ltr"
                                                                            ? "76%"
                                                                            : "-76%",
                                                                    } }, t("To *")),
                                                                React.createElement("div", { className: "hours-to-team", style: {
                                                                        marginTop: "0px",
                                                                        marginLeft: "0px",
                                                                    } },
                                                                    React.createElement("div", { className: "form-custom-select", style: { marginTop: "16px" } },
                                                                        React.createElement("select", { disabled: this.state.isBlockToDisable, id: "mySelect", onChange: (event) => {
                                                                                this.setState({
                                                                                    blockTimeTo: event.target.value,
                                                                                    BlockTimeHoursError: "",
                                                                                });
                                                                            }, value: this.state.blockTimeTo, style: {
                                                                                border: borderStyleBlockTimeHours,
                                                                                borderRadius: 8,
                                                                                direction: "inherit",
                                                                            }, onBlur: this.handleBlurOfSelect, onClick: () => {
                                                                                this.handleSelectToggle("isSelectOpenBlockTimeHoursTo");
                                                                            } }, this.state.blockWorkingSlotTo.map((option, slotIndex) => {
                                                                            let [time, amPm,] = this.showTimeInUppercase(option).split(" ");
                                                                            return (React.createElement("option", { disabled: this.optionDisable(option), key: slotIndex, id: JSON.stringify(slotIndex), value: option },
                                                                                time,
                                                                                " ",
                                                                                t(amPm)));
                                                                        })),
                                                                        React.createElement("img", { className: setupTeamDownClassName, src: this.handleArrow(this.state
                                                                                .isSelectOpenBlockTimeHoursTo), alt: "Custom Icon" }))))))) : (React.createElement(React.Fragment, null,
                                                        React.createElement("div", { className: "item-time", style: {
                                                                display: "flex",
                                                                alignItems: "center",
                                                            } },
                                                            React.createElement("div", { style: {
                                                                    marginTop: 30,
                                                                    display: "felx",
                                                                    flex: "start",
                                                                } },
                                                                React.createElement("span", { className: "profile-form-sub-header", style: {
                                                                        marginRight: webAppDirection === "ltr"
                                                                            ? "65%"
                                                                            : "-65%",
                                                                    } }, t("From *")),
                                                                React.createElement("div", { className: "hours-form-team", style: { marginTop: "0px" } },
                                                                    React.createElement("div", { className: "form-custom-select", style: { marginTop: "16px" } },
                                                                        React.createElement("select", { id: "block_weekend_from", value: this.state.blockTimeFromWeekends, onChange: (event) => {
                                                                                this.onBlockWeekendTimeFromSelection(event.target.value);
                                                                            }, onBlur: this.handleBlurOfSelect, onClick: () => {
                                                                                this.handleSelectToggle("isSelectOpenBlockTimeHoursWeekendsFrom");
                                                                            }, style: {
                                                                                border: borderStyleBlockTimeWeekendHours,
                                                                                borderRadius: 8,
                                                                                direction: "inherit",
                                                                            } }, this.state.workingSlot.map((option, indexNumber) => {
                                                                            let [time, amPm,] = this.showTimeInUppercase(option).split(" ");
                                                                            return (React.createElement("option", { key: indexNumber, id: indexNumber, disabled: this.optionDisable(option), value: option },
                                                                                time,
                                                                                " ",
                                                                                t(amPm)));
                                                                        })),
                                                                        React.createElement("img", { src: this.handleArrow(this.state
                                                                                .isSelectOpenBlockTimeHoursWeekendsFrom), className: setupTeamDownClassName, alt: "Custom Icon" })))),
                                                            React.createElement("div", { style: {
                                                                    marginTop: 30,
                                                                    display: "felx",
                                                                    flex: "start",
                                                                } },
                                                                React.createElement("span", { className: "profile-form-sub-header", style: {
                                                                        marginRight: webAppDirection === "ltr"
                                                                            ? "76%"
                                                                            : "-76%",
                                                                    } }, t("To *")),
                                                                React.createElement("div", { className: "hours-to-team", style: {
                                                                        marginTop: "0px",
                                                                        marginLeft: "0px",
                                                                    } },
                                                                    React.createElement("div", { className: "form-custom-select", style: { marginTop: "16px" } },
                                                                        React.createElement("select", { id: "block_weekend_to", disabled: this.state.isBlockToWeekendDisable, value: this.state.blockTimeToWeekends, onChange: (event) => {
                                                                                this.setState({
                                                                                    blockTimeToWeekends: event.target.value,
                                                                                    BlockTimeWeekendHoursError: "",
                                                                                });
                                                                            }, onBlur: this.handleBlurOfSelect, onClick: () => {
                                                                                this.handleSelectToggle("isSelectOpenBlockTimeHoursWeekendsTo");
                                                                            }, style: {
                                                                                border: borderStyleBlockTimeWeekendHours,
                                                                                borderRadius: 8,
                                                                                direction: "inherit",
                                                                            } }, this.state.blockWeekendSlotTo.map((option, keyIndex) => {
                                                                            let [time, amPm,] = this.showTimeInUppercase(option).split(" ");
                                                                            return (React.createElement("option", { key: keyIndex, id: JSON.stringify(keyIndex), value: option, disabled: this.optionDisable(option) },
                                                                                time,
                                                                                " ",
                                                                                t(amPm)));
                                                                        })),
                                                                        React.createElement("img", { src: this.handleArrow(this.state
                                                                                .isSelectOpenBlockTimeHoursWeekendsTo), className: setupTeamDownClassName, alt: "Custom Icon" }))))))),
                                                    React.createElement("div", { className: "item-time", style: {
                                                            flexDirection: "column",
                                                        } },
                                                        this.state.blockWorkingHoursError && (React.createElement("div", { style: {
                                                                position: "relative",
                                                                marginLeft: marginLeftError,
                                                                marginBottom: 10,
                                                                marginRight: marginRightError,
                                                            } },
                                                            React.createElement("span", { className: "profile-error-message" }, t(this.state.blockWorkingHoursError)))),
                                                        this.state.blockWeekendHoursError && (React.createElement("div", { style: {
                                                                marginLeft: marginLeftError,
                                                                position: "relative",
                                                                marginRight: marginRightError,
                                                                marginBottom: 10,
                                                            } },
                                                            React.createElement("span", { className: "profile-error-message" }, t(this.state.blockWeekendHoursError))))),
                                                    React.createElement("div", { className: "form-working-days", style: {
                                                            marginLeft: webAppDirection === "ltr" ? "60px" : "0",
                                                            marginRight: webAppDirection === "rtl" ? "60px" : "0",
                                                        } },
                                                        React.createElement("span", { className: "form-label", style: { color: "#2C2C2E" } }, t("Working Days"))),
                                                    React.createElement("div", { className: "form-working-days-label" },
                                                        React.createElement("div", { style: {
                                                                flexWrap: "wrap",
                                                                flexDirection: "row",
                                                                marginLeft: webAppDirection === "ltr" ? "45px" : "0",
                                                                marginRight: webAppDirection === "rtl" ? "45px" : "0",
                                                            }, className: "form-working-days-label-day" }, this.state.workingDaysData.map((item) => {
                                                            return (React.createElement(Button, { className: "profile-from-button", key: item.id, "data-test-id": "workingDays", onClick: () => {
                                                                    this.handleWorkingHoursSelection(item.id);
                                                                }, color: "primary", style: {
                                                                    padding: 0,
                                                                    border: 0,
                                                                    background: 0,
                                                                    marginRight: 10,
                                                                    borderRadius: "27px",
                                                                    width: "144px",
                                                                    height: "54px",
                                                                    fontSize: "18px",
                                                                    marginTop: 10,
                                                                    backgroundColor: this.handleDayBackgroundColor(item),
                                                                    color: this.handleDayColor(item),
                                                                } },
                                                                React.createElement("span", { className: "fontfamily-profile" }, t(item.day))));
                                                        }))),
                                                    React.createElement("div", { style: {
                                                            marginLeft: marginLeftError,
                                                            marginRight: marginRightError,
                                                            marginTop: 20,
                                                        } }, React.createElement("span", { className: "profile-error-message" }, t(this.renderErrorMessage())))))))),
                                React.createElement("div", { style: { width: "100%" } },
                                    React.createElement("span", { className: "form-label", style: {
                                            color: "#2C2C2E",
                                            fontSize: 20,
                                            marginBottom: "10px",
                                        } }, t("Services")),
                                    React.createElement("span", { className: "form-label", style: {
                                            color: "#757575",
                                            fontSize: 16,
                                            marginBottom: "23px",
                                            textAlign: "inherit",
                                        } }, t("Add the services this team member can offer.")),
                                    React.createElement("div", { style: { width: "100%" } },
                                        React.createElement("div", { className: "services-container", style: { cursor: "pointer" }, onClick: () => this.setState({ showServiceList: true }) },
                                            React.createElement("div", null,
                                                React.createElement("span", { style: {
                                                        fontSize: "16px",
                                                        color: "#2C2C2E",
                                                        fontWeight: 500,
                                                    } },
                                                    t("All services"),
                                                    " (",
                                                    this.getAllServicesLength(),
                                                    ")")),
                                            React.createElement("div", null,
                                                React.createElement("span", { "data-test-id": "serviceBtn", style: { fontSize: "16px", color: "#999999" } }, t("Edit")))))),
                                React.createElement("div", { className: "button-conatiner" },
                                    React.createElement(Button, { className: "cancel-button", "data-test-id": "cancelButton", onClick: () => {
                                            this.handleCancel();
                                        } }, t("Cancel")),
                                    React.createElement(Button, { className: "profile-next-Step-button", "data-test-id": "saveButton", color: "primary", onClick: () => {
                                            this.addTeamMembers();
                                        } }, t("Save")),
                                    React.createElement("div", { style: {
                                            marginLeft: 10,
                                            marginTop: 20,
                                            position: "absolute",
                                        } },
                                        React.createElement("span", { className: "profile-error-message" }, t(this.renderErrorMessageforProfile(this.state.submisionError))))))))),
                this.state.countryCodeModal && (React.createElement(CountryCodeModal, { open: this.state.countryCodeModal, onSelect: this.handleCountryCodeChange })),
                React.createElement(Dialog, { open: this.state.openCalenderDialog, PaperProps: {
                        style: {
                            width: "403px",
                            height: "auto",
                            borderRadius: "24px",
                            boxShadow: "none",
                            background: "#FFFFFF",
                        },
                    } },
                    React.createElement(DialogContent, null,
                        React.createElement(Box, { mb: 0.5 },
                            React.createElement("div", { style: {
                                    marginLeft: "10px",
                                } },
                                React.createElement("div", { className: "add-member-calendar-container" },
                                    React.createElement("button", { className: "calendar-close", onClick: this.handleClosCalendar }, "\u00D7"),
                                    React.createElement(Calendar, { maxDate: this.state.modaltype === "birthDate"
                                            ? new Date(new Date().setFullYear(new Date().getFullYear() - 18))
                                            : undefined, onChange: this.handleDateChange, calendarType: "US", formatMonthYear: (locale, date) => date.toLocaleString(locale, {
                                            month: "short",
                                            year: "numeric",
                                        }) }),
                                    React.createElement("div", { style: {
                                            display: "flex",
                                            gap: 10,
                                            marginTop: "35px",
                                        } },
                                        React.createElement("button", { style: {
                                                width: "130px",
                                                height: "52px",
                                                border: "1px solid #2C2C2E",
                                                borderRadius: "28px",
                                                marginLeft: "30px",
                                            }, id: "handleOngoing", disabled: this.disableModalType(), onClick: () => {
                                                this.handleOngoing();
                                            } }, t("Ongoing")),
                                        React.createElement("button", { style: {
                                                width: "130px",
                                                height: "52px",
                                                borderRadius: "28px",
                                                backgroundColor: "#1E5FEA",
                                                color: "#FFFFFF",
                                                border: "none",
                                            }, id: "handleDone", onClick: () => {
                                                this.setState({ openCalenderDialog: false });
                                            } }, t("Done")))))))),
                React.createElement(Dialog, { open: this.state.showServiceList, disableAutoFocus: true, PaperProps: {
                        style: {
                            width: "683px",
                            height: "585px",
                            borderRadius: "24px",
                            boxShadow: "none",
                            background: "#FFFFFF",
                        },
                    } },
                    React.createElement(DialogTitle, { style: { marginTop: "40px", marginLeft: 32, marginRight: 40 } },
                        React.createElement("div", { className: "container" },
                            React.createElement("span", { className: "label-left" },
                                t("Services"),
                                React.createElement(Button, { variant: "contained", color: "primary", className: "business-button", style: { marginLeft: "16px" } }, (_a = this.state.teamServices[0]) === null || _a === void 0 ? void 0 : _a.attributes.business_provided.display_name)),
                            React.createElement("span", { className: "label-right", style: {
                                    marginRight: webAppDirection === "rtl" ? "auto" : "",
                                    marginLeft: webAppDirection === "rtl" ? "0" : "rtl",
                                } },
                                React.createElement(IconButton, { color: "primary", "aria-label": "upload picture", component: "span", id: "close-dialog", onClick: () => {
                                        this.handleSaveCancelService(false);
                                    } },
                                    React.createElement("img", { src: cross, alt: "logo", width: "24px", height: "24px" }))))),
                    React.createElement(DialogContent, null,
                        React.createElement("div", { style: { margin: "20px 8px 0px 32px" } },
                            React.createElement("div", { className: "servicelist-list-container" },
                                React.createElement("div", { className: "servicelist-main-container" },
                                    React.createElement(OutlinedInput, { type: "text", placeholder: t("Search for Services"), id: "search", className: "addmember-search-service-input-style", onChange: (event) => this.handleSearch(event.target.value), startAdornment: React.createElement(InputAdornment, { position: "start" },
                                            React.createElement(IconButton, { "aria-label": "edit mobile number", edge: "end", style: {
                                                    width: 24,
                                                    height: 24,
                                                    marginRight: 2,
                                                    marginLeft: 10,
                                                } },
                                                React.createElement("img", { src: countryCodeSearchIcon, height: 14, width: 14, alt: "eye" }))) }),
                                    this.state.teamServices &&
                                        this.state.teamServices.map((service, myCount) => {
                                            return (React.createElement(React.Fragment, null,
                                                React.createElement("div", { key: `first${myCount}`, className: "servicelist-detail-container" },
                                                    React.createElement("div", { className: "main-service-container-team", key: service.attributes.id },
                                                        React.createElement("span", { style: { color: "white" } }, service.attributes.display_name),
                                                        !this.state.selectAll.includes(service.attributes.id.toString()) ? (React.createElement("div", { "data-test-id": "selectAll", onClick: () => this.selectAll(service.attributes
                                                                .business_sub_categories, service.attributes.id, true) }, t("Select All"))) : (React.createElement("div", { "data-test-id": "deselectAll", onClick: () => this.selectAll(service.attributes
                                                                .business_sub_categories, service.attributes.id, false) }, t("Deselect All")))),
                                                    React.createElement("div", { className: "employee-service-note" }, service.attributes.business_sub_categories.map((subCategories, count) => {
                                                        return (React.createElement("div", { key: `second${count}`, className: "subcategories-container" },
                                                            React.createElement("div", null,
                                                                React.createElement("span", { className: "employee-service-name-text" }, subCategories.display_name),
                                                                React.createElement("div", { className: "employee-service-note" },
                                                                    React.createElement("span", null,
                                                                        this.getHoursFromDuration(subCategories),
                                                                        " ",
                                                                        t("min"),
                                                                        " - OMR",
                                                                        " ",
                                                                        subCategories.service_cost))),
                                                            React.createElement(Radio, { "data-test-id": "radioBtn", size: "medium", value: subCategories.id.toString(), checked: this.state.selectedServices
                                                                    .length > 0
                                                                    ? this.state.selectedServices.includes(subCategories.id.toString())
                                                                    : false, onClick: () => this.handleRadioButton(this.state.selectedServices.includes(subCategories.id.toString()), subCategories.id, service.attributes.id, // Pass the main service ID
                                                                service.attributes.business_sub_categories // Pass all sub-services
                                                                ) })));
                                                    })))));
                                        })),
                                React.createElement("div", { className: "modal-button-conatiner" },
                                    React.createElement(Button, { className: "modal-cancel-button", "data-test-id": "cancelService", onClick: () => {
                                            this.handleSaveCancelService(false);
                                        } }, t("Cancel")),
                                    React.createElement(Button, { className: "modal-save-button", "data-test-id": "saveService", color: "primary", onClick: () => {
                                            this.handleSaveCancelService(true);
                                        } }, t("Save"))))))),
                React.createElement(Dialog, { PaperProps: {
                        style: {
                            width: 407,
                            borderRadius: "14px",
                            boxShadow: "none",
                        },
                    }, open: this.state.successModal },
                    React.createElement(Box, { component: "div", sx: { padding: 40 } },
                        React.createElement(Grid, { container: true, justifyContent: "center" },
                            React.createElement(Grid, { item: true, xs: 12 },
                                React.createElement(Box, { component: "div", sx: { textAlign: "center" } },
                                    React.createElement("img", { src: img7f284a623dad0db2fed9fbe2e3d55e6af5f0cd63, alt: "type", width: "130px", height: "130px", style: {
                                            textAlign: "center",
                                        } }))),
                            React.createElement(Grid, { item: true, xs: 12 },
                                React.createElement(Box, { component: "div", sx: { textAlign: "center" } },
                                    React.createElement(Box, { component: "div", style: {
                                            marginTop: 10,
                                            fontSize: 24,
                                            lineHeight: "30px",
                                            fontWeight: 600,
                                            fontFamily: "Poppins-Medium, sans-serif",
                                        } }, t("Congratulations!")),
                                    React.createElement(Box, { component: "div", style: {
                                            color: "#757575",
                                            marginTop: 15,
                                            fontWeight: 500,
                                            fontSize: 14,
                                            fontFamily: "Poppins-Medium, sans-serif",
                                        } },
                                        this.state.firstName,
                                        " ",
                                        t("has been added as your team member.")),
                                    React.createElement(Box, { component: "div", style: {
                                            fontSize: 20,
                                            marginTop: 10,
                                            fontWeight: 500,
                                            fontFamily: "Poppins-Medium, sans-serif",
                                        } }, t("Your business setup is completed!")),
                                    React.createElement(Box, { component: "div", style: {
                                            color: "#757575",
                                            marginTop: 10,
                                            fontWeight: 500,
                                            fontSize: 14,
                                            fontFamily: "Poppins-Medium, sans-serif",
                                        } }, t("Your business is now Online.")))),
                            React.createElement(Grid, { container: true, spacing: 2, style: { marginTop: 20 } },
                                React.createElement(Grid, { item: true, xs: 12 },
                                    React.createElement(Button, { style: {
                                            backgroundColor: "#1B5ADD",
                                            color: "#ffffff",
                                            height: 64,
                                            cursor: "pointer",
                                            width: "100%",
                                            fontStyle: "normal",
                                            fontSize: "20px",
                                            fontWeight: 500,
                                            borderRadius: "32px",
                                            textTransform: "none",
                                            lineHeight: "18px",
                                            fontFamily: "Poppins, sans-serif",
                                        }, variant: "outlined", "data-test-id": "manageTeam", onClick: () => this.successModalButtonClick("ManageTeam") }, t("Manage team"))),
                                React.createElement(Grid, { item: true, xs: 12 },
                                    React.createElement(Button, { style: {
                                            height: 64,
                                            cursor: "pointer",
                                            width: "100%",
                                            fontStyle: "normal",
                                            fontSize: "20px",
                                            fontWeight: 500,
                                            border: "1px solid",
                                            borderRadius: "32px",
                                            textTransform: "none",
                                            lineHeight: "18px",
                                            fontFamily: "Poppins, sans-serif",
                                        }, variant: "outlined", "data-test-id": "home", onClick: () => this.successModalButtonClick("BusinessHomeScreen") }, t("Home"))))))))));
    }
}
//@ts-ignore
export default withTranslation()(AddTeamMembers);
