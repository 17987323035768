// Customizable Area Start
import React from "react";
import { withRouter } from "react-router-dom";
import ManageBusiness from "./ManageBusiness.web";
import { IconButton, Button, Card, CardContent, Typography, OutlinedInput, InputAdornment, FormControl, Dialog, DialogContent, TextField, Box, } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ManageBlockMyTimeController from "../src/ManageBlockMyTimeController";
import { backdrop, whiteAddbutton, arrowRight, calender, countryCodeSearchIcon, editIcon, alertIcon, ArrowDown, } from "../../CustomisableUserProfiles/src/assets";
import moment from "moment";
import DatePicker from "../../CustomisableUserProfiles/src/EmployeeProfile/Calender";
import { withTranslation } from "react-i18next";
import { defultPng } from "../../appointmentmanagement/src/assets";
import Loader from "../../../components/src/Loader.web";
const Cookies = require("js-cookie");
// Customizable Area End
class ManageBlockMyTime extends ManageBlockMyTimeController {
    constructor(props) {
        super(props);
        // Customizable Area Start
        this.handleDateChange = (date) => {
            const formattedDate = moment(date).format("DD MMM YYYY");
            if (this.state.modaltype === "startDate") {
                this.setState({ DateError: "", startDate: formattedDate });
            }
            else {
                this.setState({ endDateError: "", endDate: formattedDate });
            }
        };
        this.handaleClose = () => {
            this.setState({ selectDateError: "", openCalenderDialog: false });
        };
        this.handleOngoing = () => {
            if (this.state.modaltype === "startDate") {
                this.setState({
                    startDate: "ongoing",
                    openCalenderDialog: false,
                    DateError: "",
                });
            }
            else {
                this.setState({
                    endDate: "ongoing",
                    openCalenderDialog: false,
                    endDateError: "",
                });
            }
        };
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area End
    render() {
        var _a;
        // Customizable Area Start
        const minDate = this.calculateMinDate(this.state.modaltype, this.state.startDate, new Date());
        let webAppDirection = localStorage.getItem("webAppDirection") ||
            Cookies.get("webAppDirection") ||
            "ltr";
        let { t } = this.props;
        const mainClassName = this.mainDivClassName(webAppDirection);
        // Customizable Area End
        return (
        // Customizable Area Start
        React.createElement(React.Fragment, null,
            React.createElement(Loader, { loading: this.state.loading }),
            React.createElement("div", { className: "manage-employee-main-container" },
                React.createElement(ManageBusiness, null),
                React.createElement("div", { className: mainClassName },
                    React.createElement(React.Fragment, null,
                        React.createElement("div", { className: "manage-employee-heading-container" },
                            React.createElement("div", { className: "employee-back-icon", style: { display: "flex", alignItems: "center" } },
                                React.createElement(IconButton, { style: { width: "30px", height: "30px" }, id: "removeImage", onClick: () => {
                                        this.handaleBackArrowButton();
                                    } },
                                    React.createElement("img", { src: backdrop, alt: "logo", width: "30px", height: "30px" })),
                                React.createElement("span", { className: "manage-employee-header-text" }, this.getHeaderText()))),
                        React.createElement("div", { className: "employee-card-container" },
                            this.state.tabName === "list" && (React.createElement("div", { className: "employee-card" },
                                React.createElement("div", { className: "employee-search-textfield" },
                                    React.createElement(OutlinedInput, { type: "text", placeholder: t("Search by name"), id: "searchEmployee", className: "employess-search-service-input-style", onChange: (event) => this.handleSearch(event.target.value), startAdornment: React.createElement(InputAdornment, { position: "start" },
                                            React.createElement(IconButton, { "aria-label": "edit mobile number", edge: "end", style: {
                                                    width: 24,
                                                    height: 24,
                                                    marginRight: 2,
                                                    marginLeft: 10,
                                                } },
                                                React.createElement("img", { src: countryCodeSearchIcon, height: 14, width: 14, alt: "eye" }))) })),
                                React.createElement("div", { className: "employeelist-main-container" },
                                    this.state.employeeList.map((item) => {
                                        return (React.createElement("div", { className: "list-item", onClick: () => {
                                                this.getEmployeeBlockTimeList(item);
                                            } },
                                            React.createElement("img", { src: item.attributes.team_member_profile ||
                                                    defultPng, alt: "Avatar" }),
                                            React.createElement("div", { className: "user-info" },
                                                React.createElement("h3", null,
                                                    item.attributes.first_name,
                                                    " ",
                                                    item.attributes.last_name),
                                                React.createElement("p", null, item.attributes.email_address)),
                                            React.createElement("div", { className: "arrow", style: {
                                                    marginLeft: this.autoMarginLeft(webAppDirection),
                                                    marginRight: this.autoMarginRight(webAppDirection),
                                                } },
                                                React.createElement("img", { src: arrowRight, alt: "Right arrow" }))));
                                    }),
                                    this.state.employeeList.length === 0 && (React.createElement("h3", { style: {
                                            color: "#2C2C2E",
                                            fontFamily: "Poppins-Medium",
                                            fontSize: "24px",
                                            fontStyle: "normal",
                                            fontWeight: 500,
                                            lineHeight: "30px",
                                            margin: "20px",
                                            textAlign: "center",
                                        } }, t("No data available")))))),
                            this.state.tabName === "none" && (React.createElement("div", { className: "employee-card" },
                                React.createElement("div", { className: "block-days-time-container" }, (_a = this.state.blockData) === null || _a === void 0 ? void 0 :
                                    _a.map((blockDay) => {
                                        var _a, _b;
                                        let [toTime, toAmPm] = ((_a = blockDay.attributes.end_time) === null || _a === void 0 ? void 0 : _a.split(" ")) || [];
                                        let [fromTime, fromAmPm] = ((_b = blockDay.attributes.start_time) === null || _b === void 0 ? void 0 : _b.split(" ")) || [];
                                        return (React.createElement("div", { key: blockDay.name },
                                            React.createElement(Card, { className: "card-box-class", onClick: () => {
                                                    this.handleEditBlockTime(blockDay.attributes.id);
                                                } },
                                                React.createElement(CardContent, null,
                                                    React.createElement(Typography, { variant: "h6" },
                                                        React.createElement("div", { className: "employee-service-name" },
                                                            React.createElement("span", { className: "employee-service-name" }, blockDay.attributes.description))),
                                                    React.createElement(Typography, { variant: "body1" },
                                                        React.createElement("div", { className: "employee-container" },
                                                            React.createElement("span", { className: "employee-label-left" },
                                                                React.createElement("span", { className: "left-direction-text" }, blockDay.attributes.from_date),
                                                                "\u00A0-\u00A0",
                                                                React.createElement("span", { className: "left-direction-text" }, t(blockDay.attributes.to_date))),
                                                            React.createElement("span", { style: {
                                                                    marginRight: this.autoMarginRight(webAppDirection),
                                                                    marginLeft: this.autoMarginLeft(webAppDirection),
                                                                } },
                                                                React.createElement(IconButton, { color: "primary", "aria-label": "upload picture", className: "arrow", component: "span", id: "editBlock", onClick: () => {
                                                                        this.handleEditBlockTime(blockDay.attributes.id);
                                                                    } },
                                                                    React.createElement("img", { src: arrowRight, alt: "logo", height: "24px", width: "24px" }))))),
                                                    React.createElement(Typography, { variant: "body1" },
                                                        React.createElement("div", { className: "employee-label-left" },
                                                            React.createElement("span", { className: "employee-label-left" }, `${fromTime} ${t(fromAmPm)} - ${toTime} ${t(toAmPm)}`)))))));
                                    }),
                                    !this.state.loading && !this.state.blockData.length && (React.createElement("div", { style: { textAlign: "center" } }, t("Block days and time not found")))),
                                React.createElement("div", { className: "block-days-time-button" },
                                    React.createElement(Button, { variant: "contained", className: "block-days-time-Add-button", color: "primary", onClick: () => {
                                            this.setState({ tabName: "addBlockTime" });
                                        } },
                                        React.createElement("img", { src: whiteAddbutton, alt: "type", height: "17px", width: "17px" }),
                                        " ",
                                        "\u00A0 ",
                                        t("Add another"))))),
                            this.state.tabName === "addBlockTime" && (React.createElement("div", { className: "employee-card " },
                                React.createElement("div", { className: "employee-add-block-my-time-label " }, t("Days")),
                                React.createElement("div", { style: {
                                        maxWidth: "71%",
                                        alignItems: "center",
                                        gap: "24px",
                                        display: "flex",
                                        marginTop: 24,
                                    } },
                                    React.createElement("div", { style: {
                                            display: "flex",
                                            flexDirection: "column",
                                        } },
                                        React.createElement("label", { htmlFor: "outlined-adornment-password-1", className: "employee-add-block-my-time-sublabel" }, t("From")),
                                        React.createElement(FormControl, { variant: "outlined" },
                                            React.createElement(OutlinedInput, { id: "calendar1", type: "text", readOnly: true, error: this.state.DateError, onBlur: () => {
                                                    this.onDateChange(this.state.startDate, "start");
                                                }, value: this.state.startDate, placeholder: t("Select date"), style: {
                                                    fontFamily: "Poppins-Medium, sans-serif",
                                                    borderRadius: "8px",
                                                }, onClick: () => {
                                                    this.setState({
                                                        modaltype: "startDate",
                                                        openCalenderDialog: true,
                                                    });
                                                }, endAdornment: React.createElement(InputAdornment, { position: "end" },
                                                    React.createElement(IconButton, { id: "openCalenderstartTime", className: "openCalenderstartTime", edge: "end", "aria-label": "toggle password visibility" },
                                                        React.createElement("img", { src: calender, alt: "type", height: "26px", width: "25px" }))) })),
                                        React.createElement("div", { style: { marginTop: 10, display: "flex" } },
                                            React.createElement("span", { className: "manage-employee-error-message-text" }, t(this.renderErrorMessageforBlock(this.state.DateError))))),
                                    React.createElement("div", { style: {
                                            flexDirection: "column",
                                            display: "flex",
                                        } },
                                        React.createElement("label", { className: "employee-add-block-my-time-sublabel", htmlFor: "outlined-adornment-password-2" }, t("To")),
                                        React.createElement(FormControl, { variant: "outlined" },
                                            React.createElement(OutlinedInput, { type: "text", id: "calender-endtime", readOnly: true, error: this.state.endDateError, placeholder: t("Select date"), value: t(this.state.endDate), onBlur: () => {
                                                    this.onDateChange(this.state.endDate, "end");
                                                }, onClick: () => {
                                                    this.setState({
                                                        modaltype: "endDate",
                                                        openCalenderDialog: true,
                                                    });
                                                }, style: {
                                                    borderRadius: "8px",
                                                    fontFamily: "Poppins-Medium, sans-serif",
                                                }, endAdornment: React.createElement(InputAdornment, { position: "end" },
                                                    React.createElement(IconButton, { edge: "end", "aria-label": "toggle password visibility" },
                                                        React.createElement("img", { alt: "type", width: "25px", src: calender, height: "26px" }))) })),
                                        React.createElement("div", { style: { display: "flex", marginTop: 10 } },
                                            React.createElement("span", { className: "manage-employee-error-message-text " }, t(this.renderErrorMessageforBlock(this.state.endDateError)))))),
                                React.createElement("div", { className: "employee-add-block-my-time-label " }, t("Time")),
                                React.createElement("div", { style: {
                                        display: "flex",
                                        maxWidth: "100%",
                                        alignItems: "center",
                                        marginTop: 24,
                                        gap: "24px",
                                    } },
                                    React.createElement("div", { style: {
                                            flexDirection: "column",
                                            display: "flex",
                                            width: "50%",
                                        } },
                                        React.createElement("label", { className: "employee-add-block-my-time-sublabel", htmlFor: "outlined-adornment-password-1" }, t("From")),
                                        React.createElement(FormControl, { variant: "outlined" },
                                            React.createElement(Autocomplete, { id: "startTime", className: "my-autocomplete", options: this.state.timDataStart, getOptionLabel: (option) => this.getOptionText(option), onChange: (event, value) => {
                                                    this.onWeekendFromSelection(value);
                                                }, style: { width: "234px" }, popupIcon: React.createElement("img", { alt: "Dropdown Icon", src: ArrowDown, height: 24, width: 24 }), renderInput: (params) => (React.createElement(TextField, Object.assign({}, params, { variant: "outlined", placeholder: t("Select time"), "data-testid": "startTimeInput", id: "startTimeAuto", inputProps: Object.assign(Object.assign({}, params.inputProps), { readOnly: true }), error: this.state.TimeError, onBlur: () => {
                                                        this.onDateChange(this.state.startTime, "startTime");
                                                    } }))) })),
                                        React.createElement("div", { style: { marginTop: 10 } },
                                            React.createElement("span", { className: "manage-employee-error-message-text" }, t(this.renderErrorMessageforBlock(this.state.TimeError))))),
                                    React.createElement("div", { style: {
                                            width: "50%",
                                            display: "flex",
                                            flexDirection: "column",
                                        } },
                                        React.createElement("label", { className: "employee-add-block-my-time-sublabel", htmlFor: "outlined-adornment-password-2" }, t("To")),
                                        React.createElement(FormControl, { variant: "outlined" },
                                            React.createElement(Autocomplete, { options: this.state.timDataEnd, id: "endtime", getOptionLabel: (option) => this.getOptionText(option), className: "my-autocomplete", style: { width: "234px" }, onChange: (event, value) => {
                                                    this.setState({
                                                        endTime: value,
                                                        endTimeError: "",
                                                    });
                                                }, popupIcon: React.createElement("img", { src: ArrowDown, alt: "Dropdown Icon", height: 24, width: 24 }), renderInput: (params) => (React.createElement(TextField, Object.assign({ placeholder: t("Select time") }, params, { error: this.state.endTimeError, variant: "outlined", onBlur: () => {
                                                        this.onDateChange(this.state.endTime, "endTime");
                                                    }, inputProps: Object.assign(Object.assign({}, params.inputProps), { readOnly: true }) }))) })),
                                        React.createElement("div", { style: { marginTop: 10 } },
                                            React.createElement("span", { className: "manage-employee-error-message-text " }, t(this.renderErrorMessageforBlock(this.state.endTimeError)))))),
                                React.createElement("div", { style: {
                                        marginTop: 24,
                                        display: "flex",
                                        maxWidth: "100%",
                                    } },
                                    React.createElement("div", { style: {
                                            flexDirection: "column",
                                            maxWidth: "100%",
                                            display: "flex",
                                        } },
                                        React.createElement("label", { className: "employee-add-block-my-time-sublabel", htmlFor: "outlined-adornment-password-1" }, t("Description")),
                                        React.createElement(FormControl, { variant: "outlined" },
                                            React.createElement(OutlinedInput, { type: "text", id: "Description", onBlur: () => {
                                                    this.onDateChange(this.state.desc, "desc");
                                                }, onChange: (event) => {
                                                    this.setState({ desc: event.target.value });
                                                }, placeholder: t("Eg: Break"), error: this.state.descError, style: {
                                                    height: "54px",
                                                    width: "492px",
                                                    fontFamily: "Poppins-Medium, sans-serif",
                                                    borderRadius: "8px",
                                                }, inputProps: {
                                                    min: 0,
                                                    maxLength: 100,
                                                } })),
                                        React.createElement("div", { style: { marginTop: 10 } },
                                            React.createElement("span", { className: "manage-employee-error-message-text" }, t(this.renderErrorMessageforBlock(this.state.descError)))))),
                                React.createElement("div", { style: {
                                        maxWidth: "100%",
                                        alignItems: "center",
                                        display: "flex",
                                        marginBottom: "55px",
                                        marginTop: 60,
                                    } },
                                    React.createElement("div", { style: {
                                            marginLeft: this.marginLeft44Pixel(webAppDirection),
                                            flexDirection: "column",
                                            display: "flex",
                                        } },
                                        React.createElement(Button, { color: "primary", variant: "contained", onClick: () => {
                                                this.handaleCancel();
                                            }, className: "employee-add-block-my-time-button cancel" }, t("Cancel"))),
                                    React.createElement("div", { style: {
                                            display: "flex",
                                            marginLeft: this.marginLeft24Pixel(webAppDirection),
                                            flexDirection: "column",
                                        } },
                                        React.createElement(Button, { onClick: () => {
                                                this.submitData("add");
                                            }, id: "addsaveButton", color: "primary", className: "employee-add-block-my-time-button save", variant: "contained" }, t("Save")))))),
                            this.state.tabName === "editBlockTime" && (React.createElement("div", { className: "employee-card" },
                                React.createElement("div", { className: "employee-add-block-my-time-label" }, t("Days")),
                                React.createElement("div", { style: {
                                        alignItems: "center",
                                        display: "flex",
                                        marginTop: 24,
                                        gap: "24px",
                                        maxWidth: "71%",
                                    } },
                                    React.createElement("div", { style: {
                                            flexDirection: "column",
                                            display: "flex",
                                        } },
                                        React.createElement("label", { className: "employee-add-block-my-time-sublabel", htmlFor: "outlined-adornment-password-1" }, t("From")),
                                        React.createElement(FormControl, { variant: "outlined" },
                                            React.createElement(OutlinedInput, { type: "text", id: "calenderstartTime", disabled: this.state.viewMode, value: this.state.startDate, onBlur: () => {
                                                    this.onDateChange(this.state.startDate, "start");
                                                }, readOnly: true, error: this.state.DateError, placeholder: t("Select date"), style: {
                                                    borderRadius: "8px",
                                                    fontFamily: "Poppins-Medium, sans-serif",
                                                }, onClick: () => {
                                                    this.setState({
                                                        modaltype: "startDate",
                                                        openCalenderDialog: true,
                                                    });
                                                }, endAdornment: React.createElement(InputAdornment, { position: "end" },
                                                    React.createElement(IconButton, { disabled: this.state.viewMode, "aria-label": "toggle password visibility", edge: "end" },
                                                        React.createElement("img", { src: calender, width: "25px", height: "26px", alt: "type" }))) })),
                                        React.createElement("div", { style: { marginTop: 10 } },
                                            React.createElement("span", { className: "manage-employee-error-message-text" }, t(this.renderErrorMessageforBlock(this.state.DateError))))),
                                    React.createElement("div", { style: {
                                            display: "flex",
                                            flexDirection: "column",
                                        } },
                                        React.createElement("label", { htmlFor: "outlined-adornment-password-2", className: "employee-add-block-my-time-sublabel" }, t("To")),
                                        React.createElement(FormControl, { variant: "outlined" },
                                            React.createElement(OutlinedInput, { id: "calenderEndTime", type: "text", disabled: this.state.viewMode, error: this.state.endDateError, onBlur: () => {
                                                    this.onDateChange(this.state.endDate, "end");
                                                }, placeholder: t("Select date"), value: t(this.state.endDate), readOnly: true, style: {
                                                    fontFamily: "Poppins-Medium, sans-serif",
                                                    borderRadius: "8px",
                                                }, onClick: () => {
                                                    this.setState({
                                                        openCalenderDialog: true,
                                                        modaltype: "endDate",
                                                    });
                                                }, endAdornment: React.createElement(InputAdornment, { position: "end" },
                                                    React.createElement(IconButton, { disabled: this.state.viewMode, className: "toggle-visibility", "aria-label": "toggle password visibility", edge: "end" },
                                                        React.createElement("img", { width: "25px", src: calender, height: "26px", alt: "type" }))) })),
                                        React.createElement("div", { style: { marginTop: 10 } },
                                            React.createElement("span", { className: "manage-employee-error-message-text" }, t(this.renderErrorMessageforBlock(this.state.endDateError)))))),
                                React.createElement("div", { className: "employee-add-block-my-time-label" }, t("Time")),
                                React.createElement("div", { style: {
                                        display: "flex",
                                        gap: "24px",
                                        alignItems: "center",
                                        maxWidth: "100%",
                                        marginTop: 24,
                                    } },
                                    React.createElement("div", { style: {
                                            display: "flex",
                                            width: "50%",
                                            flexDirection: "column",
                                        } },
                                        React.createElement("label", { htmlFor: "outlined-adornment-password-1", className: "employee-add-block-my-time-sublabel" }, t("From")),
                                        React.createElement(FormControl, { variant: "outlined" },
                                            React.createElement(Autocomplete, { options: this.state.timDataStart, id: "editstartTime", disabled: this.state.viewMode, className: "my-autocomplete", getOptionLabel: (option) => this.getOptionText(option), value: this.state.startTime, style: { width: "234px" }, popupIcon: React.createElement("img", { src: ArrowDown, alt: "Dropdown Icon", width: 24, height: 24 }), onChange: (event, value) => {
                                                    this.onWeekendFromSelection(value);
                                                }, renderInput: (params) => (React.createElement(TextField, Object.assign({}, params, { placeholder: t("Select time"), error: this.state.TimeError, variant: "outlined", inputProps: Object.assign(Object.assign({}, params.inputProps), { readOnly: true }), onBlur: () => {
                                                        this.onDateChange(this.state.startTime, "startTime");
                                                    } }))) })),
                                        React.createElement("div", { style: { marginTop: 10 } },
                                            React.createElement("span", { className: "manage-employee-error-message-text" }, this.renderErrorMessageforBlock(this.state.TimeError)))),
                                    React.createElement("div", { style: {
                                            display: "flex",
                                            width: "50%",
                                            flexDirection: "column",
                                        } },
                                        React.createElement("label", { htmlFor: "outlined-adornment-password-2", className: "employee-add-block-my-time-sublabel" }, t("To")),
                                        React.createElement(FormControl, { variant: "outlined" },
                                            React.createElement(Autocomplete, { id: "editendTime", options: this.state.timDataEnd, disabled: this.state.viewMode, className: "my-autocomplete", getOptionLabel: (option) => this.getOptionText(option), style: { width: "234px" }, value: this.state.endTime, onChange: (event, value) => {
                                                    this.setState({
                                                        endTime: value,
                                                        endTimeError: "",
                                                    });
                                                }, popupIcon: React.createElement("img", { src: ArrowDown, width: 24, alt: "Dropdown Icon", height: 24 }), renderInput: (params) => (React.createElement(TextField, Object.assign({}, params, { variant: "outlined", placeholder: t("Select time"), error: this.state.endTimeError, inputProps: Object.assign(Object.assign({}, params.inputProps), { readOnly: true }), onBlur: () => {
                                                        this.onDateChange(this.state.endTime, "endTime");
                                                    } }))) })),
                                        React.createElement("div", { style: { marginTop: 10 } },
                                            React.createElement("span", { className: "manage-employee-error-message-text" }, t(this.renderErrorMessageforBlock(this.state.endTimeError)))))),
                                React.createElement("div", { style: {
                                        display: "flex",
                                        marginTop: 24,
                                        maxWidth: "100%",
                                    } },
                                    React.createElement("div", { style: {
                                            display: "flex",
                                            maxWidth: "100%",
                                            flexDirection: "column",
                                        } },
                                        React.createElement("label", { htmlFor: "outlined-adornment-password-1", className: "employee-add-block-my-time-sublabel" }, t("Description")),
                                        React.createElement(FormControl, { variant: "outlined" },
                                            React.createElement(OutlinedInput, { type: "text", id: "editDescription", disabled: this.state.viewMode, onBlur: () => {
                                                    this.onDateChange(this.state.desc, "desc");
                                                }, error: this.state.descError, value: this.state.desc, onChange: (event) => {
                                                    this.setState({ desc: event.target.value });
                                                }, placeholder: t("Eg: Break"), style: {
                                                    height: "54px",
                                                    width: "492px",
                                                    fontFamily: "Poppins-Medium, sans-serif",
                                                    borderRadius: "8px",
                                                }, inputProps: {
                                                    min: 0,
                                                    maxLength: 100,
                                                } })),
                                        React.createElement("div", { style: { marginTop: 10 } },
                                            React.createElement("span", { className: "manage-employee-error-message-text" }, t(this.renderErrorMessageforBlock(this.state.descError)))))),
                                this.state.viewMode && (React.createElement("div", { style: {
                                        display: "flex",
                                        marginTop: 60,
                                        flexDirection: "row",
                                        maxWidth: "100%",
                                        marginBottom: "50px",
                                    } },
                                    React.createElement("div", { style: {
                                            marginLeft: this.marginLeft44Pixel(webAppDirection),
                                            display: "flex",
                                        } },
                                        React.createElement(Button, { variant: "contained", color: "primary", className: "employee-add-block-my-time-button edit", onClick: () => {
                                                this.handleModeChange();
                                            } },
                                            React.createElement("img", { src: editIcon, width: "14px", alt: "type", height: "16px" }),
                                            "\u00A0 ",
                                            t("Edit"))),
                                    React.createElement("div", { style: {
                                            marginLeft: this.marginLeft24Pixel(webAppDirection),
                                            display: "flex",
                                        } },
                                        React.createElement(Button, { variant: "contained", color: "primary", className: "employee-add-block-my-time-button delete", onClick: () => {
                                                this.setState({ alertDialogOpen: true });
                                            } }, t("Delete"))))),
                                !this.state.viewMode && (React.createElement("div", { style: {
                                        alignItems: "center",
                                        display: "flex",
                                        marginBottom: "55px",
                                        marginTop: 60,
                                    } },
                                    React.createElement("div", { style: {
                                            flexDirection: "column",
                                            display: "flex",
                                            marginLeft: this.marginLeft44Pixel(webAppDirection),
                                        } },
                                        React.createElement(Button, { variant: "contained", color: "primary", className: "employee-add-block-my-time-button cancel", onClick: () => {
                                                this.handaleCancel();
                                            } }, t("Cancel"))),
                                    React.createElement("div", { style: {
                                            display: "flex",
                                            marginLeft: this.marginLeft24Pixel(webAppDirection),
                                            flexDirection: "column",
                                        } },
                                        React.createElement(Button, { variant: "contained", color: "primary", id: "saveEditButton", className: "employee-add-block-my-time-button save", onClick: () => {
                                                this.submitData("edit");
                                            } }, t("Save")))))))))),
                this.state.alertDialogOpen && (React.createElement(Dialog, { open: this.state.alertDialogOpen, PaperProps: {
                        style: {
                            width: "427px",
                            borderRadius: "24px",
                            height: "480px",
                            background: "#FFFFFF",
                            boxShadow: "none",
                        },
                    } },
                    React.createElement(DialogContent, null,
                        React.createElement("div", { className: "employee-alert-dialog" },
                            React.createElement("div", { className: "employee-alert-dialog-icon" },
                                React.createElement("img", { src: alertIcon, alt: "type", height: "120px", width: "120px" })),
                            React.createElement("div", { className: "employee-alert-dialog-text" },
                                React.createElement("span", { className: "employee-alert-dialog-text-value" }, t("Are you sure you want to")),
                                React.createElement("span", { className: "employee-alert-dialog-text-value" }, t("delete this Block days&time?"))),
                            React.createElement("div", { className: "employee-alert-dialog-buttons" },
                                React.createElement("div", null,
                                    React.createElement(Button, { variant: "contained", color: "primary", className: "employee-add-block-my-time-button noButton", onClick: () => {
                                            this.setState({ alertDialogOpen: false });
                                        } }, t("No"))),
                                React.createElement("div", null,
                                    React.createElement(Button, { variant: "contained", color: "primary", className: "employee-add-block-my-time-button yesButton", onClick: () => {
                                            this.submitData("delete");
                                        } }, t("Yes")))))))),
                this.state.openCalenderDialog && (React.createElement(Dialog, { open: this.state.openCalenderDialog, PaperProps: {
                        style: {
                            width: "403px",
                            borderRadius: "24px",
                            height: "481px",
                            background: "#FFFFFF",
                            boxShadow: "none",
                        },
                    } },
                    React.createElement(DialogContent, null,
                        React.createElement(Box, { mb: 0.5 },
                            React.createElement("div", { style: {
                                    marginLeft: "10px",
                                } },
                                React.createElement(DatePicker, { variant: "static", openTo: "date", onClose: this.handaleClose, value: this.getDate(), format: "EEE, MMM dd", onChange: this.handleDateChange, minDate: minDate, views: ["year", "month", "date"] }),
                                React.createElement("div", { style: {
                                        marginLeft: "40px",
                                        marginBottom: "20px",
                                        marginRight: "40px",
                                    } },
                                    React.createElement("span", { className: "manage-employee-error-message-text" }, t(this.renderErrorMessageforBlock(this.state.selectDateError))))),
                            React.createElement("div", { style: {
                                    display: "flex",
                                    marginTop: "20px",
                                    gap: 10,
                                } },
                                React.createElement("button", { style: {
                                        width: "130px",
                                        height: "52px",
                                        backgroundColor: "#FFFFFF",
                                        border: "1px solid #2C2C2E",
                                        marginLeft: "30px",
                                        borderRadius: "28px",
                                    }, id: "handleOngoing", disabled: this.state.modaltype === "startDate", onClick: () => {
                                        this.handleOngoing();
                                    } }, t("Ongoing")),
                                React.createElement("button", { style: {
                                        height: "52px",
                                        width: "130px",
                                        borderRadius: "28px",
                                        backgroundColor: "#1E5FEA",
                                        border: "none",
                                        color: "#FFFFFF",
                                    }, onClick: () => {
                                        this.handaleDone();
                                    }, id: "handleDone" }, t("Done")))))))))
        // Customizable Area End
        );
    }
}
//@ts-ignore
export default withTranslation()(withRouter(ManageBlockMyTime));
