var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// Customizable Area Start
import { Message } from "framework/src/Message";
import MessageEnum, { getName, } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { BlockComponent } from "framework/src/BlockComponent";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createRef } from "react";
toast.configure();
const Cookies = require("js-cookie");
export const configJSON = require("./config");
export default class ProfileDrawerController extends BlockComponent {
    // Customizable Area End
    constructor(props) {
        // Customizable Area Start
        super(props);
        // Customizable Area Start
        this.getSettingdataApiCallId = "";
        this.getLanguagedataApiCallId = "";
        this.sendDeactivationForAccountId = "";
        this.apiGetCustomerProfileCallId = "";
        this.updateLanguageApiCallId = "";
        this.logoutApiCallId = "";
        this.myBookingsRef = createRef();
        this.deactivateAccountRef = createRef();
        this.contactUsRef = createRef();
        this.languageRef = createRef();
        this.termAndConditionRef = createRef();
        this.aboutUsRef = createRef();
        this.privacyPolicyRef = createRef();
        this.open_dialog = (values, resetForm) => {
            this.setState({ forms_data: [values, resetForm], openLogout: true });
        };
        this.navigateToSearch = (text) => {
            this.props.history.push({
                pathname: "/search",
                state: {
                    searchValue: text,
                },
            });
        };
        this.handleLogout = () => {
            let device_token = localStorage.getItem("device_token") ||
                Cookies.get("Push_NotificationToken") ||
                "";
            let browser_id = localStorage.getItem("BrowserUniqueId") ||
                Cookies.get("BrowserUniqueId") ||
                "";
            const header = {
                "Content-Type": "application/json",
                token: localStorage.getItem("token") || "",
            };
            let body = {
                device_token: device_token,
                platform_id: `${browser_id}${device_token}`,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.logoutApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_login/logins/logout_account");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.handleUpdateLanguage = (language, direction) => {
            this.setState({ isLoading: true });
            const header = {
                "Content-Type": "application/json",
                token: localStorage.getItem("token") || "",
            };
            let body = {
                language: language
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.updateLanguageApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_login/logins/add_language");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
            runEngine.sendMessage(requestMessage.id, requestMessage);
            this.changeLanguage(language, direction);
            return true;
        };
        this.handleBackArrow = () => {
            if (this.state.profileHeaderTitle === "Profile") {
                this.props.history.push("/CustomerHomeScreen");
            }
            this.setState({
                profileHeaderTitle: "Profile",
                setBackgroundSetting: "",
                settingResponse: [],
            });
        };
        this.handleReceivedSettingResponse = (responseJson) => {
            if (responseJson) {
                switch (this.state.profileHeaderTitle) {
                    case "Privacy policy":
                        {
                            this.setState({
                                settingResponse: responseJson.settings[0].business_privacy_policy,
                            });
                        }
                        break;
                    case "About SqueezMeIn":
                        {
                            this.setState({
                                settingResponse: responseJson.settings[0].about_us,
                            });
                        }
                        break;
                    case "Contact Us":
                        {
                            this.setState({
                                settingResponse: responseJson.settings[0].contact_email,
                            });
                        }
                        break;
                    case "Terms & conditions":
                        {
                            this.setState({
                                settingResponse: responseJson.data[0].attributes.description,
                            });
                        }
                        break;
                    default:
                        break;
                }
            }
        };
        this.handleReceivedLanguageResponse = (responseJson) => {
            this.setState({ languageResponse: responseJson });
        };
        this.handleReceivedsendDeactivationResponse = (responseJson) => {
            var _a, _b;
            let { t } = this.props;
            if (((_a = responseJson === null || responseJson === void 0 ? void 0 : responseJson.meta) === null || _a === void 0 ? void 0 : _a.message) === "Account Successfully Deactivated") {
                toast.success(t((_b = responseJson === null || responseJson === void 0 ? void 0 : responseJson.meta) === null || _b === void 0 ? void 0 : _b.message), {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    style: {
                        direction: "inherit"
                    }
                });
                this.props.history.push("/");
            }
            else if ((responseJson === null || responseJson === void 0 ? void 0 : responseJson.message) === "This password you entered is in-correct.") {
                toast.error(t(responseJson === null || responseJson === void 0 ? void 0 : responseJson.message), {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    style: {
                        direction: "inherit"
                    }
                });
            }
        };
        this.fetchCustomerProfileAPICall = () => __awaiter(this, void 0, void 0, function* () {
            this.setState({
                isLoading: true
            });
            const header = {
                token: localStorage.getItem("token"),
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.apiGetCustomerProfileCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getCustomerProfileEndPoint);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getHttpRequest);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        });
        this.getSettingData = (heading) => {
            let webAppLanguage = localStorage.getItem("webAppLanguage") ||
                Cookies.get("webAppLanguage") || "en";
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getSettingdataApiCallId = requestMessage.messageId;
            if (heading === "Terms & conditions") {
                requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.settingsTermsConditionEndPoint + "?" + `language=${webAppLanguage}`);
            }
            else {
                requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.settingsEndPoint + "?" + `language=${webAppLanguage}`);
            }
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getHttpRequest);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.getLanguageData = () => {
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getLanguagedataApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.showLanguageUrl);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getHttpRequest);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.sendDeactivationForAccount = () => {
            var _a, _b, _c, _d;
            this.setState({ openLogout: false });
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
                token: localStorage.getItem("token") || "",
            };
            const httpBody = {
                data: {
                    attributes: {
                        reason: (_a = this.state.forms_data[0]) === null || _a === void 0 ? void 0 : _a.messege,
                        password: (_b = this.state.forms_data[0]) === null || _b === void 0 ? void 0 : _b.password,
                    },
                },
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.sendDeactivationForAccountId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.urlDeactivate);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.putMethod);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            (_d = (_c = this.state.forms_data)[1]) === null || _d === void 0 ? void 0 : _d.call(_c);
            return true;
        };
        this.handleCustomerResponse = (responseJson) => {
            var _a;
            const data = (_a = responseJson === null || responseJson === void 0 ? void 0 : responseJson.data) === null || _a === void 0 ? void 0 : _a.attributes;
            localStorage.setItem("profileImage", data.profile_image);
            this.setState({
                customerProfile: data,
                isLoading: false,
            });
        };
        this.handleLogoutResponse = (responseJson) => {
            if (responseJson) {
                localStorage.clear();
                this.setState({
                    openLogout: false,
                }, () => {
                    this.props.history.push("/LandingPage");
                });
            }
        };
        //istanbul ignore next
        this.changeLanguage = (lng, dir) => {
            localStorage.setItem("webAppDirection", dir);
            localStorage.setItem("webAppLanguage", lng);
            localStorage.removeItem("formatted_address");
            Cookies.set("webAppDirection", dir);
            Cookies.set("webAppLanguage", lng);
            this.setState({ selectLanguage: lng });
        };
        this.scrollToElement = (elementRef) => {
            setTimeout(() => {
                if (this.isScreenWidthSmall() && elementRef.current) {
                    elementRef.current.scrollIntoView({
                        behavior: "smooth",
                    });
                }
            }, 10);
        };
        this.handleOptionClick = (action) => {
            this.setState({
                profileHeaderTitle: action,
                setBackgroundSetting: action,
            });
        };
        this.getBackgroundColor = (setting, target) => {
            return setting === target ? "rgba(27,90,221,.2)" : "";
        };
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIRequestMessage),
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            profileHeaderTitle: "Profile",
            settingResponse: [],
            languageResponse: [],
            selectLanguage: "en",
            openLogout: false,
            forms_data: [],
            setBackgroundSetting: "",
            customerProfile: null,
            isLoading: false,
            isShowPassword: false,
        };
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
        // Customizable Area End
    }
    // Customizable Area Start
    componentDidMount() {
        const _super = Object.create(null, {
            componentDidMount: { get: () => super.componentDidMount }
        });
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            _super.componentDidMount.call(this);
            setTimeout(() => {
                let userToken = localStorage.getItem("token") || "";
                let userType = localStorage.getItem("userType") || "";
                if (!userToken || userType === "employee") {
                    localStorage.clear();
                    window.location.replace("/LandingPage");
                }
            }, 100);
            let typeUser = localStorage.getItem("userType") || "";
            if (typeUser === "customer") {
                this.fetchCustomerProfileAPICall();
            }
            let webAppDirection = localStorage.getItem("webAppDirection") ||
                Cookies.get("webAppDirection") ||
                "ltr";
            let isFromFooter = ((_b = (_a = this.props.location) === null || _a === void 0 ? void 0 : _a.state) === null || _b === void 0 ? void 0 : _b.isFromFooter) || false;
            if (webAppDirection === "ltr") {
                this.setState({ selectLanguage: "en" });
            }
            if (webAppDirection === "rtl") {
                this.setState({ selectLanguage: "ar" });
            }
            if (isFromFooter) {
                this.props.location.state.privacyOrTerms === "Privacy policy" ? this.getSettingData() : this.getSettingData("Terms & conditions");
                this.setState({
                    profileHeaderTitle: this.props.location.state.privacyOrTerms || "Profile",
                    setBackgroundSetting: this.props.location.state.privacyOrTerms || "",
                });
            }
        });
    }
    receive(from, message) {
        return __awaiter(this, void 0, void 0, function* () {
            if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
                const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
                const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
                if (apiRequestCallId === this.getSettingdataApiCallId) {
                    this.handleReceivedSettingResponse(responseJson);
                }
                if (apiRequestCallId === this.getLanguagedataApiCallId) {
                    this.handleReceivedLanguageResponse(responseJson);
                }
                if (apiRequestCallId === this.sendDeactivationForAccountId) {
                    this.handleReceivedsendDeactivationResponse(responseJson);
                }
                if (apiRequestCallId === this.apiGetCustomerProfileCallId) {
                    this.handleCustomerResponse(responseJson);
                }
                if (apiRequestCallId === this.logoutApiCallId) {
                    this.handleLogoutResponse(responseJson);
                }
                if (apiRequestCallId === this.updateLanguageApiCallId) {
                    this.setState({ isLoading: false });
                    if ((responseJson === null || responseJson === void 0 ? void 0 : responseJson.message) === "User Updated") {
                        window.location.reload();
                    }
                }
            }
            else {
                runEngine.debugLog("GOIT");
            }
        });
    }
    isScreenWidthSmall() {
        return window.innerWidth <= 1060;
    }
}
