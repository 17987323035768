var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName, } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
// Customizable Area Start
import moment from "moment";
import { defultPng } from "../../../appointmentmanagement/src/assets";
// Customizable Area End
export const configJSON = require("../config");
export default class EmployeeViewProfileWebController extends BlockComponent {
    // Customizable Area End
    constructor(props) {
        super(props);
        this.handlePopstate = () => {
            this.checkPathname();
        };
        this.preventDefault = (event) => {
            if (event.keyCode === 9) {
                event.preventDefault();
            }
        };
        this.getProfileImage = () => {
            const { businessImages } = this.state;
            if (businessImages && businessImages.length > 0) {
                console.log("businessImages ::", businessImages[0].path);
                return businessImages[0].path || defultPng;
            }
            else {
                return defultPng; // return null if businessImages array is empty or not present
            }
        };
        this.getBackGroundColor = (selectionMode, weekType) => {
            return selectionMode === weekType ? "#1e5fea" : "#F9F9F8";
        };
        this.getTextColor = (selectionMode, weekType) => {
            return selectionMode === weekType ? '#FFFFFF' : '#2C2C2E';
        };
        //istanbul ignore next
        this.getTime = (timeType) => {
            const { getSelectionMode, weekdayFrom, WeekdayTo, weekendFrom, weekendTo, weekdayBlockFrom, weekdayBlockTo, weekendBlockFrom, weekendBlockTo } = this.state;
            console.log("getTime ::", getSelectionMode, weekdayFrom, WeekdayTo, weekendFrom, weekendTo, weekdayBlockFrom, weekdayBlockTo, weekendBlockFrom, weekendBlockTo);
            const timeMap = {
                'from': getSelectionMode === "weekdays" ? weekdayFrom : weekendFrom,
                'to': getSelectionMode === "weekdays" ? WeekdayTo : weekendTo,
                'blockFrom': getSelectionMode === "weekdays" ? weekdayBlockFrom : weekendBlockFrom,
                'blockTo': getSelectionMode === "weekdays" ? weekdayBlockTo : weekendBlockTo,
            };
            let { t } = this.props;
            let [time, amPm] = timeMap[timeType].toUpperCase().split(" ");
            return `${time} ${t(amPm)}`;
        };
        this.handleBackgroundColor = (item) => {
            const isWeekday = item.isWeekday;
            const isWeekend = item.isWeekend;
            const selectionMode = this.state.getSelectionMode;
            let backgroundColor = "#F9F9F8";
            if ((selectionMode === "weekdays" && isWeekday) || (selectionMode === "weekends" && isWeekend)) {
                backgroundColor = "#1E5FEA";
            }
            return backgroundColor;
        };
        this.handleColor = (item) => {
            const isWeekday = item.isWeekday;
            const isWeekend = item.isWeekend;
            const selectionMode = this.state.getSelectionMode;
            let color = "#2C2C2E";
            if ((selectionMode === "weekdays" && isWeekday) || (selectionMode === "weekends" && isWeekend)) {
                color = "#FFFFFF";
            }
            return color;
        };
        this.updatedSwitchData = (val) => {
            this.setState({ getSelectionMode: val });
        };
        this.handleChange = (value) => {
            if (!value) {
                this.setState({ empServices: this.state.serviceList });
            }
            else if (Array.isArray(this.state.serviceList)) { // Add this check
                const newServices = this.state.serviceList.filter((service) => {
                    return service.display_name.toLowerCase().includes(value.toLowerCase());
                });
                this.setState({ searchTerm: value, empServices: newServices });
            }
            return this.state.empServices;
        };
        this.getHoursFromDuration = (data) => {
            if (moment(data.duration, 'HH:mm:ss').hours() === 1) {
                return "60";
            }
            else {
                return moment(data.duration, 'HH:mm:ss').minutes();
            }
        };
        this.getTeamMembers = () => __awaiter(this, void 0, void 0, function* () {
            const { token } = this.state;
            const header = {
                "Content-Type": "application/json",
                token
            };
            yield this.apiCall({
                setApiCallId: 'getTeamMemberDataCallId',
                header,
                method: "GET",
                endPoint: "account_block/employee_profile"
            });
        });
        this.apiCall = (data) => __awaiter(this, void 0, void 0, function* () {
            const { setApiCallId, header, endPoint, method, body } = data;
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            if (setApiCallId === 'getTeamMemberDataCallId') {
                this.getTeamMemberDataCallId = requestMessage.messageId;
                requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
                requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
                requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
                if (body) {
                    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
                }
                runEngine.sendMessage(requestMessage.id, requestMessage);
            }
        });
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            userId: '',
            profileImage: "",
            errorMsg: "",
            empServices: [],
            token: "",
            logout: false,
            isLoading: true,
            accountOwnerName: "",
            accountOwnerNameError: "",
            accountNumber: "",
            accountNumberError: "",
            confirmAccountNumber: "",
            confirmAccountNumberError: "",
            ifscCode: "",
            ifscCodeError: "",
            allowTeam: true,
            enablePayAtShop: false,
            enableCardPayment: false,
            showServiceList: false,
            serviceList: "",
            searchTerm: "",
            serviceId: null,
            serviceValue: "Choose",
            imageModalVisible: false,
            workingFrom: "",
            workingTo: "",
            businessImages: [],
            businessImageError: "",
            providedService: [],
            selectedTeamSize: null,
            removePhotoEnable: false,
            selectedPhoto: null,
            serviceCategory: [],
            teamSizeData: [],
            workingSlot: [
                "12:00 am",
                "12:30 am",
                "1:00 am",
                "1:30 am",
                "2:00 am",
                "2:30 am",
                "3:00 am",
                "3:30 am",
                "4:00 am",
                "4:30 am",
                "5:00 am",
                "5:30 am",
                "6:00 am",
                "6:30 am",
                "7:00 am",
                "7:30 am",
                "8:00 am",
                "8:30 am",
                "9:00 am",
                "9:30 am",
                "10:00 am",
                "10:30 am",
                "11:00 am",
                "11:30 am",
                "12:00 pm",
                "12:30 pm",
                "1:00 pm",
                "1:30 pm",
                "2:00 pm",
                "2:30 pm",
                "3:00 pm",
                "3:30 pm",
                "4:00 pm",
                "4:30 pm",
                "5:00 pm",
                "5:30 pm",
                "6:00 pm",
                "6:30 pm",
                "7:00 pm",
                "7:30 pm",
                "8:00 pm",
                "8:30 pm",
                "9:00 pm",
                "9:30 pm",
                "10:00 pm",
                "10:30 pm",
                "11:00 pm",
                "11:30 pm",
            ],
            workingHours: [
                {
                    id: 1,
                    day: "Sunday",
                    isSelected: false,
                    type: "weekend"
                },
                {
                    id: 2,
                    day: "Monday",
                    isSelected: false,
                    type: "weekday"
                },
                {
                    id: 3,
                    day: "Tuesday",
                    isSelected: false,
                    type: "weekday"
                },
                {
                    id: 4,
                    day: "Wednesday",
                    isSelected: false,
                    type: "weekday"
                },
                {
                    id: 5,
                    day: "Thursday",
                    isSelected: false,
                    type: "weekday"
                },
                {
                    id: 6,
                    day: "Friday",
                    isSelected: false,
                    type: "weekday"
                },
                {
                    id: 7,
                    day: "Saturday",
                    isSelected: false,
                    type: "weekend"
                },
            ],
            workingDaysData: [
                {
                    id: 1,
                    day: "Sunday",
                    isWeekend: false,
                    isWeekday: false,
                    isDisableWeekday: false,
                    isDisableWeekend: false
                },
                {
                    id: 2,
                    day: "Monday",
                    isWeekend: false,
                    isWeekday: false,
                    isDisableWeekday: false,
                    isDisableWeekend: false
                },
                {
                    id: 3,
                    day: "Tuesday",
                    isWeekend: false,
                    isWeekday: false,
                    isDisableWeekday: false,
                    isDisableWeekend: false
                },
                {
                    id: 4,
                    day: "Wednesday",
                    isWeekend: false,
                    isWeekday: false,
                    isDisableWeekday: false,
                    isDisableWeekend: false
                },
                {
                    id: 5,
                    day: "Thursday",
                    isWeekend: false,
                    isWeekday: false,
                    isDisableWeekday: false,
                    isDisableWeekend: false
                },
                {
                    id: 6,
                    day: "Friday",
                    isWeekend: false,
                    isWeekday: false,
                    isDisableWeekday: false,
                    isDisableWeekend: false
                },
                {
                    id: 7,
                    day: "Saturday",
                    isWeekend: false,
                    isWeekday: false,
                    isDisableWeekday: false,
                    isDisableWeekend: false
                },
            ],
            Name: "",
            NameError: "",
            Fname: "",
            FnameError: "",
            Lname: "",
            LnameError: "",
            Email: "",
            EmailError: "",
            Mobile: "",
            MobileError: "",
            Dob: "",
            DobError: "",
            AboutMe: "",
            Gender: "",
            GenderError: "",
            Language: "",
            LanguageError: "",
            StartDate: "",
            StartDateError: "",
            EndDate: "",
            EndDateError: "",
            workingHoursError: "",
            BlockTimeFrom: "",
            BlockTimeTo: "",
            workingDays: [],
            workingDaysError: "",
            Services: [],
            ServicesError: "",
            DateModal: false,
            DateModalType: "dob",
            loading: false,
            deleteModal: false,
            imageChanged: false,
            selectedCountryCode: "951",
            weekendFrom: "",
            weekendTo: "",
            weekdayFrom: "",
            WeekdayTo: "",
            weekdayBlockFrom: "",
            weekdayBlockTo: "",
            weekendBlockFrom: "",
            weekendBlockTo: "",
            weekendDaysError: "",
            weekendDays: [],
            businessName: "",
            businessNameError: "",
            businessOwner: "",
            businessOwnerError: "",
            businessEmail: "",
            businessEmailError: "",
            website: "",
            websiteError: "",
            crn: "",
            crnError: "",
            contact: "",
            contactError: "",
            bankAccountPlaceholder: "Add bank account",
            accountDetails: [],
            getRoundCorner: 25,
            getSelectionMode: 'weekdays',
            serviceError: "",
            bankDetailError: "",
            modalDobError: "",
            modalStartError: "",
            modalEndError: "",
            isTakePhotoModalVisible: false,
            capturedImage: "",
            height: null,
            width: null,
            uri: ""
            // Customizable Area Start
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    receive(from, message) {
        return __awaiter(this, void 0, void 0, function* () {
            if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
                const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
                const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
                if (apiRequestCallId === this.getTeamMemberDataCallId) {
                    yield this.handleTeamMemberDataResponse(responseJson);
                }
                console.log("getProfileData::2");
                if (apiRequestCallId === this.apiProfileItemCallId) {
                    console.log("getProfileData::3");
                    yield this.handleProfileDataResponse(responseJson);
                }
            }
        });
    }
    handleProfileDataResponse(responseJson) {
        return __awaiter(this, void 0, void 0, function* () {
            if (responseJson && !responseJson.errors && responseJson.data) {
                console.log("getProfileData::4");
                if (responseJson.data.length === 0) {
                    this.setState({
                        errorMsg: "Data Not Found",
                        loading: false,
                    });
                }
                else {
                    const profileData = responseJson.data;
                    const { attributes } = profileData;
                    console.log("handleProfileDataResponse::", attributes);
                    window.localStorage.setItem("appointmentID", "");
                    window.localStorage.setItem("profileImage", attributes.team_member_profile);
                    window.localStorage.setItem("businessName", attributes.display_business_name);
                    window.localStorage.setItem("teammembername", `${attributes.first_name} ${attributes.last_name}`);
                    this.setState({ profileImage: attributes.team_member_profile, });
                }
            }
        });
    }
    handleTeamMemberDataResponse(responseJson) {
        return __awaiter(this, void 0, void 0, function* () {
            if (responseJson && responseJson.data !== null && responseJson.data) {
                const data = responseJson.data.attributes;
                const workingDays = this.mapWorkingDaysData(data);
                const servicesId = this.extractServicesId(data);
                const servicesList = this.extractServicesList(data);
                this.setState({
                    businessImages: [{ path: data.team_member_profile }],
                    serviceList: servicesList,
                    empServices: servicesList,
                    Name: data.title,
                    Fname: data.first_name,
                    Lname: data.last_name,
                    Email: data.email_address,
                    Mobile: data.mobile_number.toString(),
                    Gender: data.gender,
                    Dob: data.date_of_birth,
                    Language: data.prefered_language,
                    AboutMe: data.about_me,
                    StartDate: data.emp_start_date,
                    EndDate: data.emp_end_date,
                    weekdayFrom: data.working_time_from,
                    WeekdayTo: data.working_time_to,
                    BlockTimeFrom: data.block_time_from,
                    BlockTimeTo: data.block_time_to,
                    workingDays: workingDays,
                    workingDaysData: workingDays,
                    workingHours: workingDays,
                    Services: servicesId,
                    weekendFrom: data.weekend_time_from,
                    weekendTo: data.weekend_time_to,
                    weekendBlockFrom: data.weekend_block_time_from,
                    weekendBlockTo: data.weekend_block_time_to,
                    weekdayBlockFrom: data.block_time_from,
                    weekdayBlockTo: data.block_time_to,
                    selectedCountryCode: data.country_code,
                }, () => {
                    console.log("businessImages :", this.state.businessImages, this.state);
                });
                this.setState({ loading: false, isLoading: false });
            }
            else {
                this.setState({ loading: false, isLoading: false });
            }
        });
    }
    mapWorkingDaysData(data) {
        return this.state.workingDaysData.map((d) => {
            return {
                id: d.id,
                day: d.day,
                isWeekend: data.weekend_working_days.includes(d.day),
                isWeekday: data.working_days.includes(d.day),
                isDisableWeekday: data.working_days.includes(d.day),
                isDisableWeekend: data.weekend_working_days.includes(d.day)
            };
        });
    }
    extractServicesId(data) {
        return data.team_member_services.reduce((acc, d) => {
            if (d.length !== 0) {
                acc.push(d[0].id);
            }
            return acc;
        }, []);
    }
    extractServicesList(data) {
        return data.team_member_services.reduce((acc, d) => {
            if (d.length !== 0) {
                acc.push(d[0]);
            }
            return acc;
        }, []);
    }
    componentDidMount() {
        const _super = Object.create(null, {
            componentDidMount: { get: () => super.componentDidMount }
        });
        return __awaiter(this, void 0, void 0, function* () {
            _super.componentDidMount.call(this);
            window.addEventListener('keydown', this.preventDefault);
            if (typeof window !== "undefined") {
                let token = window.localStorage.getItem("token");
                let user = window.localStorage.getItem("userType");
                if (!token || user != "employee") {
                    localStorage.clear();
                    this.props.navigation.navigate("LandingPage");
                }
                this.setState({ token: token }, () => {
                    setTimeout(() => {
                        this.getTeamMembers();
                        this.getProfileData();
                    }, 1000);
                });
            }
            this.checkPathname();
            window.addEventListener('popstate', this.handlePopstate);
        });
    }
    componentWillUnmount() {
        return __awaiter(this, void 0, void 0, function* () {
            window.removeEventListener('keydown', this.preventDefault);
            window.removeEventListener('popstate', this.enableBackButton);
        });
    }
    checkPathname() {
        const { pathname } = window.location;
        console.log("pathname :", pathname, pathname === '/EmployeeHomeScreen');
        if (pathname === '/EmployeeHomeScreen' || pathname === '/ManageEmployee/EmployeeManageBookings') {
            this.disableBackButton();
        }
        else {
            this.enableBackButton();
        }
    }
    disableBackButton() {
        window.history.pushState(null, "", window.location.href);
        window.onpopstate = () => {
            window.history.go(1);
        };
    }
    //istanbul ignore next
    enableBackButton() {
        window.onpopstate = null;
    }
    getProfileData() {
        console.log("getProfileData::1");
        const header = {
            "Content-Type": "application/json",
            token: this.state.token,
        };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.apiProfileItemCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "account_block/employee_profile");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }
}
