// Customizable Area Start
import React from "react";
import CustomerHomeScreenController from "./CustomerHomeScreenController";
import { SqueezMeInImageSmall, pasteimage, search_icon, navigation_icon, location_icon, location_placeholder_icon, defultPng, } from "../../appointmentmanagement/src/assets";
import { Modal, Backdrop, Button } from "@material-ui/core";
import "../assets/style/Header.web.scss";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
const Cookies = require("js-cookie");
import Autosuggest from "react-autosuggest";
// Customizable Area End
class Header extends CustomerHomeScreenController {
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const profileImage = localStorage.getItem("profileImage") || "";
        let { t } = this.props;
        let webAppDirection = localStorage.getItem("webAppDirection") ||
            Cookies.get("webAppDirection") ||
            "ltr";
        const inputProps = {
            placeholder: t("Search for store"),
            value: this.state.searchText,
            onChange: this.onSearchChange,
        };
        const theme = {
            container: "autosuggest-container",
            suggestionsContainer: "suggestions-container",
            suggestionsList: "suggestions-list",
            suggestion: "suggestion-item",
            suggestionsContainerOpen: "suggestions-container-open",
            suggestionHighlighted: "suggestion-highlighted",
        };
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "customer-parent-header-container" },
                React.createElement("div", { className: "main-header-container header-container" },
                    React.createElement("div", { className: "header" },
                        React.createElement("img", { src: SqueezMeInImageSmall, onClick: () => {
                                this.handleNavigation("/CustomerHomeScreen");
                            }, style: { cursor: "pointer" } }),
                        React.createElement("div", { className: "subheaderdup" },
                            React.createElement("img", { src: pasteimage, onClick: this.navigateToBookings, className: "paste_image" }),
                            React.createElement("img", { "data-testid": "goto_profile_page", src: profileImage ? profileImage : defultPng, onClick: () => this.onProfileClick(), className: webAppDirection === "ltr"
                                    ? "groupimage"
                                    : "arabic-groupimage" })),
                        React.createElement("div", { className: "searchBar_location_navigation" },
                            React.createElement("div", { className: "searchBar_with_location" },
                                React.createElement("div", { className: "search_bar_div" },
                                    React.createElement(Autosuggest, { suggestions: this.state.suggestions, onSuggestionsFetchRequested: this.onSuggestionsFetchRequested, onSuggestionsClearRequested: this.onSuggestionsClearRequested, getSuggestionValue: this.getSuggestionValue, renderSuggestion: this.renderSuggestion, inputProps: inputProps, theme: theme }),
                                    React.createElement("button", null,
                                        React.createElement("img", { src: search_icon, className: "search-icon-img" }))),
                                React.createElement("img", { src: navigation_icon, "data-testid": "nav_popup_open", className: webAppDirection === "ltr"
                                        ? "header-navigation-image"
                                        : "arabic-header-navigation-image", onClick: () => {
                                        this.setState({ model_nav: true }, () => { });
                                    } }))),
                        React.createElement("div", { className: "subheader" },
                            React.createElement("img", { src: pasteimage, onClick: this.navigateToBookings, className: "paste_image" }),
                            React.createElement("img", { "data-testid": "goto_profile_page", src: profileImage ? profileImage : defultPng, onClick: () => {
                                    this.props.history.push("/customer/profile");
                                }, className: webAppDirection === "ltr"
                                    ? "groupimage"
                                    : "arabic-groupimage" }))),
                    React.createElement("div", null,
                        React.createElement("div", { className: "location_with_text" },
                            React.createElement("img", { src: location_icon, style: { width: "12px", height: "14px" } }),
                            React.createElement("h6", null, localStorage.getItem("formatted_address") || ""))))),
            React.createElement(Modal, { "aria-labelledby": "transition-modal-title", "aria-describedby": "transition-modal-description", "data-testid": "enable_location_mui_modal", open: this.state.model_nav, style: {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }, onClose: () => {
                    this.setState({ model_nav: false });
                }, closeAfterTransition: true, BackdropComponent: Backdrop, BackdropProps: {
                    timeout: 500,
                } },
                React.createElement("div", { className: "content-modal-enable" },
                    React.createElement("img", { src: location_placeholder_icon }),
                    React.createElement("h4", null, t("In order to use SqueezMeIn location service must be enabled")),
                    React.createElement("p", null, t("Accessing your location allows us to find places near you and provides the ability to use SqueezMeIn to book services.")),
                    React.createElement(Button, { variant: "contained", className: "enable_location_btn", style: { background: "#1e5fea" }, onClick: () => this.location_fetch() }, t("Enable Location")),
                    React.createElement("h5", { "data-testid": "maybe_later_close", id: "maybe_later", onClick: () => this.setState({ model_nav: false }) }, t("Maybe Later"))))));
        // Customizable Area End
    }
}
//@ts-ignore
export default withTranslation()(withRouter(Header));
