// Customizable Area Start
import React from "react";
import MyBookingController from "./MyBookingController.web";
import { white_left_arrow, info_icon, info_last, doubleQuoteUpcoming, warningIcon, invoice, invoice_mail, cancel_appointment, quote, star_rate, unfilled_star, green_check, defultPng, } from "../../../blocks/appointmentmanagement/src/assets";
import "../../../blocks/appointmentmanagement/assets/style/BusinessSevices.web.scss";
import "../../../blocks/appointmentmanagement/assets/style/ConfirmAndPay.web.scss";
import "../../../blocks/appointmentmanagement/assets/style/MyBookings.web.scss";
import "../../../blocks/appointmentmanagement/assets/style/ProfileDrawer.web.scss";
import "../../contentmoderation/assets/Styles/ManageWorkingHours.scss";
import { withRouter } from "react-router-dom";
import { Button, Modal, TextField } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import moment from "moment";
import { withTranslation } from "react-i18next";
import Loader from "../../../components/src/Loader.web";
const Cookies = require("js-cookie");
class MyBookingsDetail extends MyBookingController {
    constructor() {
        super(...arguments);
        this.getRescheduleInfo = () => {
            const { t } = this.props;
            const { appointmentdata } = this.state;
            const { attributes } = appointmentdata || {};
            const customer_rescheduled_count = (attributes === null || attributes === void 0 ? void 0 : attributes.customer_rescheduled_count) || 0;
            if (customer_rescheduled_count >= 5) {
                return (React.createElement("div", { className: "info-div", style: {
                        background: "#f8f8f7",
                        borderRadius: "8px",
                        height: "auto",
                        marginTop: 20,
                    } },
                    React.createElement("img", { src: info_icon, alt: "" }),
                    " \u00A0",
                    React.createElement("p", null, t("You have reached your maximum allowable attempts to reschedule."))));
            }
        };
        this.getRenderData = () => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
            let { t } = this.props;
            let webAppDirection = localStorage.getItem("webAppDirection") ||
                Cookies.get("webAppDirection") ||
                "ltr";
            const { appointmentdata, hoursBrefore, } = this.state;
            const { attributes } = appointmentdata || {};
            const { status, paid_by, customer_rescheduled_count, offer, reschedule_cantellation_time: rescheduleCancellationTime } = attributes || {};
            let cancelledCondition = paid_by === "pay_at_shop" &&
                status === "cancelled";
            let noShowondition = paid_by === "pay_at_shop" &&
                status === "no_show";
            const isCancellationAllowed = status !== "cancelled" &&
                status !== "refund_initiated" &&
                status !== "refunded" &&
                (hoursBrefore >
                    (parseInt(rescheduleCancellationTime === null || rescheduleCancellationTime === void 0 ? void 0 : rescheduleCancellationTime.reschedule_time) ||
                        parseInt(rescheduleCancellationTime === null || rescheduleCancellationTime === void 0 ? void 0 : rescheduleCancellationTime.cancellation_time)));
            const shouldDisplayRefundInfo = (status === "no_show" && paid_by !== "pay_at_shop") ||
                (status === "cancelled" && paid_by !== "pay_at_shop") ||
                status === "refund_initiated" ||
                status === "refunded";
            const shouldDisplayInfoMessage = (status === "pending" ||
                status === "re_scheduled" ||
                status === "scheduled" ||
                status === "created") &&
                customer_rescheduled_count < 5 &&
                !offer;
            return (React.createElement("div", { className: "main-div" },
                React.createElement("div", { style: { maxWidth: "100%", backgroundColor: "#3d40c3" } },
                    React.createElement("div", { className: "services-header" },
                        React.createElement("img", { src: white_left_arrow, alt: "", id: "service_left_arrow", style: { transform: webAppDirection === "ltr" ? "scaleX(1)" : "scaleX(-1)" }, onClick: () => {
                                var _a;
                                let { clickFromProfile } = ((_a = this.props.location) === null || _a === void 0 ? void 0 : _a.state) || "";
                                if (clickFromProfile) {
                                    this.props.history.push({
                                        pathname: "/customer/profile",
                                    });
                                }
                                else {
                                    this.props.history.push({
                                        pathname: "/MyBookings",
                                    });
                                }
                                this.getAppointmentFromindexDb(true);
                            } }),
                        React.createElement("h1", null, t("Bookings")))),
                React.createElement("div", { className: "mybookingdetail-main-div" },
                    React.createElement("div", { className: webAppDirection === "ltr"
                            ? "booking-details-wrapper"
                            : "arabic-booking-details-wrapper", style: { height: "auto" } },
                        React.createElement("div", { style: {
                                width: "100%",
                                maxWidth: "100%",
                            } },
                            cancelledCondition || noShowondition ? (React.createElement(React.Fragment, null)) : (React.createElement("div", { className: "suberServee-item", style: { cursor: "pointer" }, onClick: () => {
                                    this.senEmailInvoice();
                                } },
                                React.createElement("h1", { style: {
                                        marginLeft: webAppDirection === "ltr" ? "auto" : 0,
                                        marginRight: webAppDirection === "rtl" ? "auto" : 0,
                                        color: "#007aff",
                                        paddingRight: "6px",
                                    } },
                                    t("Invoice"),
                                    " "),
                                React.createElement("img", { src: invoice, alt: "" }))),
                            React.createElement("div", { className: "suberServ-item" },
                                React.createElement("p", null, t("Services")),
                                React.createElement("h1", { style: {
                                        marginLeft: webAppDirection === "ltr" ? "auto" : 0,
                                        marginRight: webAppDirection === "rtl" ? "auto" : 0,
                                        color: this.getcolor(status),
                                    } }, t(this.getStatus(status)))),
                            React.createElement("div", { className: "suber-item" },
                                React.createElement("p", null,
                                    t("Booking ID:"),
                                    " ", attributes === null || attributes === void 0 ? void 0 :
                                    attributes.booking_id),
                                React.createElement("p", { style: {
                                        marginLeft: webAppDirection === "ltr" ? "auto" : 0,
                                        marginRight: webAppDirection === "rtl" ? "auto" : 0,
                                    } },
                                    t("Booking code:"),
                                    " ", (_a = attributes === null || attributes === void 0 ? void 0 : attributes.booking_code) === null || _a === void 0 ? void 0 :
                                    _a.pin)),
                            React.createElement("div", { style: { display: "flex", justifyContent: "space-between" } },
                                React.createElement("div", { className: "booking-detail" },
                                    React.createElement("h2", null, t(`{{gender}}'s {{service}}`, {
                                        gender: (_e = (_d = (_c = (_b = attributes === null || attributes === void 0 ? void 0 : attributes.business_profile) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.attributes) === null || _d === void 0 ? void 0 : _d.service_provided) === null || _e === void 0 ? void 0 : _e.display_name,
                                        service: (_f = attributes === null || attributes === void 0 ? void 0 : attributes.service) === null || _f === void 0 ? void 0 : _f.display_name,
                                    })),
                                    React.createElement("div", { className: "booking-with-detail", style: { alignItems: "center", marginTop: "5%" } },
                                        React.createElement("p", { style: { color: "#757575", margin: 0 } }, t("By")),
                                        React.createElement("img", { src: ((_j = (_h = (_g = attributes === null || attributes === void 0 ? void 0 : attributes.employee_details) === null || _g === void 0 ? void 0 : _g.data) === null || _h === void 0 ? void 0 : _h.attributes) === null || _j === void 0 ? void 0 : _j.team_member_profile) || defultPng, style: {
                                                borderRadius: "50px",
                                                width: "16px",
                                                marginLeft: "6px",
                                                height: "16px",
                                            } }),
                                        React.createElement("p", { style: { marginLeft: "6px" } }, ((_m = (_l = (_k = attributes === null || attributes === void 0 ? void 0 : attributes.employee_details) === null || _k === void 0 ? void 0 : _k.data) === null || _l === void 0 ? void 0 : _l.attributes) === null || _m === void 0 ? void 0 : _m.first_name) +
                                            " " + ((_q = (_p = (_o = attributes === null || attributes === void 0 ? void 0 : attributes.employee_details) === null || _o === void 0 ? void 0 : _o.data) === null || _p === void 0 ? void 0 : _p.attributes) === null || _q === void 0 ? void 0 : _q.last_name)))),
                                React.createElement("h2", { className: "omr-h2" },
                                    "OMR ", attributes === null || attributes === void 0 ? void 0 :
                                    attributes.total_price)),
                            React.createElement("div", { className: "booking-schedule" },
                                React.createElement("p", null, t("Booking Schedule")),
                                React.createElement("p", { className: "time-p" }, moment(attributes === null || attributes === void 0 ? void 0 : attributes.start_time).format("hh:mm A") +
                                    " " +
                                    moment(attributes === null || attributes === void 0 ? void 0 : attributes.start_time)
                                        .format("dddd")
                                        .substring(0, 3) +
                                    ", " +
                                    moment(attributes === null || attributes === void 0 ? void 0 : attributes.booking_date).format("DD MMM YYYY"))),
                            (attributes === null || attributes === void 0 ? void 0 : attributes.notes) && (React.createElement("div", { style: {
                                    display: "flex",
                                    background: "#f9f9f8",
                                    borderRadius: "8px",
                                    padding: 15,
                                    marginTop: 25,
                                } },
                                React.createElement("img", { src: doubleQuoteUpcoming, style: {
                                        alignSelf: "baseline",
                                        marginTop: "4px",
                                        marginRight: "7px",
                                    }, alt: "" }),
                                React.createElement("p", { style: { color: "#2c2c2e", fontSize: 12 } }, attributes === null || attributes === void 0 ? void 0 : attributes.notes))),
                            React.createElement("div", { style: { display: "flex" } },
                                React.createElement("h2", { className: "summery", style: { marginTop: 20 } }, t("Summary")),
                                React.createElement("h3", { style: {
                                        marginLeft: webAppDirection === "ltr" ? "auto" : 0,
                                        marginRight: webAppDirection === "rtl" ? "auto" : 0,
                                        alignSelf: "end",
                                        color: (attributes === null || attributes === void 0 ? void 0 : attributes.paid_by) === "pay_at_shop"
                                            ? "#ff9f0a"
                                            : "#34c759",
                                        fontSize: 14,
                                    } }, (attributes === null || attributes === void 0 ? void 0 : attributes.paid_by) === "pay_at_shop"
                                    ? t("Pay at shop")
                                    : t("Pay by card"))),
                            React.createElement("div", { className: "booking-price-details" },
                                React.createElement("h2", null, t("Services")),
                                React.createElement("h3", null,
                                    "OMR ", (_r = attributes === null || attributes === void 0 ? void 0 : attributes.service) === null || _r === void 0 ? void 0 :
                                    _r.service_cost)),
                            React.createElement("div", { className: "booking-price-details" },
                                React.createElement("p", null, t("Service Fee")),
                                React.createElement("h3", null,
                                    "OMR ", attributes === null || attributes === void 0 ? void 0 :
                                    attributes.service_fee)),
                            React.createElement("div", { className: "booking-price-details" },
                                React.createElement("p", null, t("Taxes")),
                                React.createElement("h3", null,
                                    "OMR ", attributes === null || attributes === void 0 ? void 0 :
                                    attributes.tax)),
                            React.createElement("div", { className: "booking-price-details", style: { marginTop: "20px" } },
                                React.createElement("h2", null, t("Total")),
                                React.createElement("h5", null,
                                    "OMR ", attributes === null || attributes === void 0 ? void 0 :
                                    attributes.total_price)),
                            (shouldDisplayRefundInfo || shouldDisplayInfoMessage) && (React.createElement("div", { className: "info-div", style: {
                                    background: "#f8f8f7",
                                    marginTop: 20,
                                    height: "auto",
                                    borderRadius: "8px",
                                } },
                                React.createElement("img", { src: info_last, alt: "" }),
                                " \u00A0",
                                shouldDisplayRefundInfo && (React.createElement("p", null, this.props.t("Refund will be initiated in 7-14 business working days provided the cancellation was done as per the policy i.e 4 hours prior to the booking time."))),
                                shouldDisplayInfoMessage && (React.createElement("p", null, this.props.t("The cancellation and reschedule should be done atleast {{time}} hours before your appointment.", {
                                    time: (_s = attributes === null || attributes === void 0 ? void 0 : attributes.reschedule_cantellation_time) === null || _s === void 0 ? void 0 : _s.reschedule_time,
                                }))))),
                            (attributes === null || attributes === void 0 ? void 0 : attributes.is_upcoming_booking) && this.getRescheduleInfo(),
                            (attributes === null || attributes === void 0 ? void 0 : attributes.is_upcoming_booking) && offer && (React.createElement("div", { className: "info-div", style: {
                                    background: "#f8f8f7",
                                    borderRadius: "8px",
                                    height: "auto",
                                    marginTop: 20,
                                } },
                                React.createElement("img", { src: info_last, alt: "" }),
                                " \u00A0",
                                React.createElement("p", null, t("Reschedule of bookings is not permitted outside the discounting period")))),
                            isCancellationAllowed && (React.createElement("div", { className: "btn-pair-of-filters" },
                                React.createElement(Button, { className: "up-btn", id: "cancel-btn-need", style: {
                                        opacity: status === "re_scheduled" ? "0.7" : "1",
                                    }, onClick: () => {
                                        this.setState({
                                            invoiceorCancel: "cancel",
                                            openModalInvoice: true,
                                        });
                                    }, disabled: status === "re_scheduled" }, t("Cancel")),
                                "\u00A0\u00A0\u00A0",
                                React.createElement(Button, { className: "past-btn", id: "updateData_btn", disabled: this.checkDisablity(), onClick: () => {
                                        this.gotoThatPage();
                                    } }, t("Reschedule")))),
                            (!(attributes === null || attributes === void 0 ? void 0 : attributes.is_upcoming_booking) ||
                                localStorage.getItem("appointmentIdFromIndexDb")) &&
                                (attributes === null || attributes === void 0 ? void 0 : attributes.status) === "completed" && (React.createElement(React.Fragment, null,
                                React.createElement(Button, { className: "r-btn", onClick: () => {
                                        this.setState({ openModalReview: true });
                                    }, style: { width: "100%" } }, t("Write a review")),
                                React.createElement("p", { className: "p-query" }, t("Any Queries?")),
                                React.createElement(Button, { className: "a-btn", onClick: () => {
                                        this.moveToissueScreen("Booking ");
                                    }, style: { width: "100%" } }, t("Booking issue")),
                                React.createElement(Button, { className: "p-btn", onClick: () => {
                                        this.moveToissueScreen("Payment ");
                                    }, style: { width: "100%" } }, t("Payment issue")),
                                React.createElement(Button, { className: "s-btn", onClick: () => {
                                        this.moveToissueScreen("Service ");
                                    }, style: { width: "100%" } }, t("Service issue")),
                                React.createElement(Button, { className: "o-btn", onClick: () => {
                                        this.moveToissueScreen("Others ");
                                    }, style: { width: "100%" } }, t("Others")))))))));
        };
    }
    render() {
        let { t } = this.props;
        return (React.createElement(React.Fragment, null,
            React.createElement(Loader, { loading: this.state.isloading }),
            this.getRenderData(),
            React.createElement(Modal, { open: this.state.openModalInvoice, "aria-labelledby": "modal-modal-title", "aria-describedby": "modal-modal-description" }, this.state.invoiceorCancel === "invoice" ? (React.createElement("div", { className: "modal-booknow-invoice" },
                React.createElement("div", { className: "img-debit-card-div-invoice" },
                    React.createElement("div", { className: "img-debit-card-invoice" },
                        React.createElement("div", { style: {
                                textAlign: "center",
                                margin: 50,
                                marginBottom: 10,
                            } },
                            React.createElement("img", { style: { marginLeft: "auto" }, src: invoice_mail, alt: "" })),
                        React.createElement("p", { className: "p-tag-invoice" }, t("Invoice has been sent to your mail.")))),
                React.createElement("div", null,
                    React.createElement(Button, { className: "book-now-btn-modal-debit-invoice", onClick: () => {
                            this.setState({ openModalInvoice: false });
                        } },
                        " ",
                        t("Done"))))) : (React.createElement("div", { className: "modal-booknow-cancel" },
                React.createElement("div", { className: "img-debit-card-div-cancel" },
                    React.createElement("div", { className: "img-debit-card-cancel" },
                        React.createElement("div", { style: {
                                textAlign: "center",
                                margin: 50,
                                marginBottom: 10,
                            } },
                            React.createElement("img", { style: { marginLeft: "auto" }, src: cancel_appointment, alt: "" })),
                        React.createElement("h1", { className: "h1-tag-cancel" }, t("Cancelling Appointment?")),
                        React.createElement("p", { className: "p-tag-cancel" }, t("Are you sure you want to cancel the appointment?")))),
                React.createElement("div", null,
                    React.createElement(Button, { className: "book-now-btn-modal-debit-cancel", onClick: () => {
                            this.setState({ openModalInvoice: false });
                        } },
                        " ",
                        t("Don’t Cancel"))),
                React.createElement("div", null,
                    React.createElement(Button, { className: "book-now-btn-modal-debit-cancel-yes", onClick: () => {
                            this.CancelAppointmentAccount();
                        } },
                        " ",
                        t("Yes")))))),
            React.createElement(Modal, { open: this.state.openModalReview, "aria-labelledby": "modal-modal-title", "aria-describedby": "modal-modal-description" }, this.state.showSubmittedresp ? (React.createElement("div", { className: "modal-booknow-confirmed-review" },
                React.createElement("div", { className: "img-debit-card-div-confirmed-review" },
                    React.createElement("div", { className: "img-debit-card-confirmed-review" },
                        React.createElement("div", { style: {
                                textAlign: "center",
                                margin: 50,
                                marginBottom: 10,
                            } },
                            React.createElement("img", { style: { marginLeft: "auto", width: 120 }, src: this.state.alreadySubmitted ? warningIcon : green_check, alt: "" })),
                        React.createElement("h1", { className: "h1-review-done" }, this.state.alreadySubmitted
                            ? t("Review Already Submitted!")
                            : t("Congrats!")),
                        React.createElement("p", { className: "p-tag-confirmed-review" }, this.state.alreadySubmitted
                            ? ""
                            : t("Your review has been submitted.")))),
                React.createElement("div", null,
                    React.createElement(Button, { className: "book-now-btn-modal-debit-confirmed-review", onClick: () => {
                            this.props.history.push({
                                pathname: "/CustomerHomeScreen",
                            });
                        } },
                        " ",
                        t("Go Home"))))) : (React.createElement("div", { className: "modal-booknow-cancel-review" },
                React.createElement("div", { className: "img-debit-card-div-cancel-review" },
                    React.createElement("div", { className: "img-debit-card-cancel-review" },
                        React.createElement("h1", { className: "h1-tag-cancel-review" }, t("So what do you think?")),
                        React.createElement(TextField, { className: "textArea-issue-review", variant: "outlined", multiline: true, rows: 5, onChange: (e) => {
                                this.setState({ reviewInput: e.target.value });
                            }, InputProps: {
                                startAdornment: (React.createElement("img", { src: quote, style: {
                                        marginTop: "-80px",
                                        marginLeft: "10px",
                                        marginRight: "10px",
                                        width: 21,
                                    }, alt: "" })),
                            }, inputProps: { maxLength: 200 } }))),
                React.createElement("div", { className: "review-rating" },
                    React.createElement("h1", { className: "rating-h1" }, t("Ratings")),
                    React.createElement(Rating, { name: "size-large", defaultValue: 2, size: "large", value: this.state.ratingvalue, className: "rating-star", onChange: (event, newValue) => {
                            this.setState({ ratingvalue: newValue });
                        }, icon: React.createElement("img", { src: star_rate }), emptyIcon: React.createElement("img", { src: unfilled_star }) })),
                this.state.requireRating ? (React.createElement("p", { style: {
                        marginTop: 25,
                        color: "red",
                        fontSize: 14,
                        paddingLeft: 12,
                    } }, t("Write something and give rating to continue."))) : (""),
                React.createElement("div", { className: "btn-pair-of-filters" },
                    React.createElement(Button, { className: "up-btn review-cancel0button", onClick: () => {
                            this.setState({ openModalReview: false });
                        } }, t("Cancel")),
                    "\u00A0\u00A0\u00A0",
                    React.createElement(Button, { className: "past-btn review-submit0button", id: "updateData_btn", onClick: () => {
                            if (!this.state.reviewInput || !this.state.ratingvalue) {
                                this.setState({ requireRating: true });
                            }
                            else {
                                this.setState({ requireRating: false });
                                this.CreateReview();
                            }
                        } }, t("Submit"))))))));
    }
}
//@ts-ignore
export default withTranslation()(withRouter(MyBookingsDetail));
// Customizable Area End
