import React from "react";
// Customizable Area Start
import { withStyles, Grid, Card } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import Chart from "react-apexcharts";
import ManageBusinessWeb from "../../contentmoderation/src/ManageBusiness.web";
//@ts-ignore
import Cookies from "js-cookie";
import { withTranslation } from "react-i18next";
// Customizable Area End
import AnalyticsController from "./AnalyticsController.web";
class Analytics extends AnalyticsController {
    constructor(props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        var _a, _b, _c, _d, _e, _f;
        // Customizable Area Start
        //@ts-ignore
        const { classes, t } = this.props;
        const { getSelectionMode, ChartDataThisWeek, ChartDataLastWeek, pastData, currentData, currentTopBookings, pastTopBookings } = this.state;
        let webAppDirection = localStorage.getItem("webAppDirection") ||
            Cookies.get("webAppDirection") ||
            "ltr";
        // Customizable Area End
        return (
        // Customizable Area Start
        React.createElement(ThemeProvider, { theme: theme },
            React.createElement("div", { className: `manage-team-main-container ${classes.root}` },
                React.createElement(ManageBusinessWeb, null),
                React.createElement("div", { className: webAppDirection === "ltr"
                        ? "manage-team-content-container"
                        : "arabic-manage-team-content-container", style: { overflowX: "hidden" } },
                    React.createElement("div", { className: classes.enriqueButton },
                        React.createElement("h1", { id: "text", className: classes.analyticsHeadingTxt }, t("Analytics"))),
                    React.createElement("div", { className: classes.viewAnalyticsContainer },
                        React.createElement(ToggleButtonGroup, null,
                            React.createElement(ToggleButton, { style: {
                                    borderRadius: 24,
                                    width: 150,
                                    border: 0,
                                    height: 44,
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: this.getTextColor(getSelectionMode, "thisWeek"),
                                    textTransform: "none",
                                    backgroundColor: this.getBackGroundColor(getSelectionMode, "thisWeek"),
                                }, value: "left", id: "btnSelect", className: "thisWeek", onClick: () => {
                                    this.setState({ getSelectionMode: "thisWeek" });
                                }, "aria-label": "left aligned" },
                                React.createElement("span", { className: "fontfamily-profile" }, t("This Week"))),
                            React.createElement(ToggleButton, { style: {
                                    borderRadius: 24,
                                    width: 150,
                                    border: 0,
                                    height: 44,
                                    fontWeight: 500,
                                    textTransform: "none",
                                    fontSize: 14,
                                    color: this.getTextColor(getSelectionMode, "lastWeek"),
                                    fontFamily: "Poppins",
                                    backgroundColor: this.getBackGroundColor(getSelectionMode, "lastWeek"),
                                }, onClick: () => {
                                    this.setState({ getSelectionMode: "lastWeek" });
                                }, value: "center", id: "lastWeek", "aria-label": "centered" },
                                React.createElement("span", { className: "fontfamily-profile" }, t("Last Week"))))),
                    getSelectionMode === "thisWeek" && (React.createElement(React.Fragment, null,
                        React.createElement(Grid, { container: true, style: { marginTop: 30 } },
                            React.createElement(Grid, { item: true, xs: 12 },
                                React.createElement(Card, { className: classes.chartCard },
                                    React.createElement("div", { className: classes.chartHeading }, t("Bookings")),
                                    React.createElement(Chart, { series: ChartDataThisWeek.options.series, options: ChartDataThisWeek.options, height: "300", type: "line" }),
                                    " "))),
                        React.createElement(Grid, { container: true, spacing: 4, style: { marginTop: 20 } },
                            React.createElement(Grid, { item: true, xs: 12, md: 6 },
                                React.createElement(Card, { className: classes.detailsCard },
                                    React.createElement("div", { className: classes.chartSubHeading },
                                        React.createElement("div", null, t("Bookings")),
                                        React.createElement("div", { id: "Bookings" }, currentData.booking_count)),
                                    React.createElement("div", { className: classes.chartSubTxt },
                                        React.createElement("div", null, t("Completed :")),
                                        React.createElement("div", { id: "Completed" }, (_a = currentData.count_by_status) === null || _a === void 0 ? void 0 : _a.Completed)),
                                    React.createElement("div", { className: classes.chartSubTxt },
                                        React.createElement("div", null, t("Cancelled :")),
                                        React.createElement("div", { id: "Cancelled" }, (_b = currentData.count_by_status) === null || _b === void 0 ? void 0 : _b.Cancelled)),
                                    React.createElement("div", { style: { marginBottom: "0px" }, className: classes.chartSubTxt },
                                        React.createElement("div", null, t("No-show :")),
                                        React.createElement("div", { id: "No-show" }, (_c = currentData.count_by_status) === null || _c === void 0 ? void 0 : _c.No_Show)))),
                            React.createElement(Grid, { item: true, xs: 12, md: 6 },
                                React.createElement(Card, { className: classes.detailsCard, style: { paddingBottom: 0 } },
                                    React.createElement("div", { className: classes.chartSubHeading },
                                        React.createElement("div", null, t("Top Bookings"))),
                                    currentTopBookings.slice(0, 5).map((booking) => (React.createElement("div", { className: classes.chartSubTxt },
                                        React.createElement("div", null,
                                            `${booking.serviceName} :`,
                                            " "),
                                        React.createElement("div", null, booking.serviceValue))))))),
                        React.createElement(Grid, { container: true, style: { marginTop: 20 }, spacing: 4 },
                            React.createElement(Grid, { item: true, md: 6, xs: 12 },
                                React.createElement(Card, { className: classes.salesCard },
                                    React.createElement("div", { className: classes.chartSubTxt }, t("Sales")),
                                    React.createElement("div", { style: { marginBottom: "0px" }, className: classes.chartSubHeading }, `OMR ${currentData.sales}`)))))),
                    getSelectionMode === "lastWeek" && (React.createElement(React.Fragment, null,
                        React.createElement(Grid, { container: true, style: { marginTop: 30 } },
                            React.createElement(Grid, { item: true, xs: 12 },
                                React.createElement(Card, { className: classes.chartCard },
                                    React.createElement("div", { className: classes.chartHeading }, t("Bookings")),
                                    React.createElement(Chart, { options: ChartDataLastWeek.options, series: ChartDataLastWeek.options.series, type: "line", height: "300" })))),
                        React.createElement(Grid, { container: true, spacing: 4, style: { marginTop: 20 } },
                            React.createElement(Grid, { item: true, xs: 12, md: 6 },
                                React.createElement(Card, { className: classes.detailsCard },
                                    React.createElement("div", { className: classes.chartSubHeading },
                                        React.createElement("div", null, t("Bookings")),
                                        React.createElement("div", null, pastData.booking_count)),
                                    React.createElement("div", { className: classes.chartSubTxt },
                                        React.createElement("div", null, t("Completed :")),
                                        React.createElement("div", null, (_d = pastData.count_by_status) === null || _d === void 0 ? void 0 : _d.Completed)),
                                    React.createElement("div", { className: classes.chartSubTxt },
                                        React.createElement("div", null, t("Cancelled :")),
                                        React.createElement("div", null, (_e = pastData.count_by_status) === null || _e === void 0 ? void 0 : _e.Cancelled)),
                                    React.createElement("div", { className: classes.chartSubTxt, style: { marginBottom: "0px" } },
                                        React.createElement("div", null, t("No-show :")),
                                        React.createElement("div", null, (_f = pastData.count_by_status) === null || _f === void 0 ? void 0 : _f.No_Show)))),
                            React.createElement(Grid, { item: true, xs: 12, md: 6 },
                                React.createElement(Card, { className: classes.detailsCard },
                                    React.createElement("div", { className: classes.chartSubHeading },
                                        React.createElement("div", null, t("Top Bookings"))),
                                    pastTopBookings.slice(0, 5).map((booking) => (React.createElement("div", { className: classes.chartSubTxt },
                                        React.createElement("div", null,
                                            `${booking.serviceName} :`,
                                            " "),
                                        React.createElement("div", null, booking.serviceValue))))))),
                        React.createElement(Grid, { container: true, spacing: 4, style: { marginTop: 20 } },
                            React.createElement(Grid, { item: true, xs: 12, md: 6 },
                                React.createElement(Card, { className: classes.salesCard },
                                    React.createElement("div", { className: classes.chartSubTxt }, t("Sales")),
                                    React.createElement("div", { className: classes.chartSubHeading, style: { marginBottom: "0px" } }, `OMR ${pastData.sales}`)))))))))
        // Customizable Area End
        );
    }
}
// Customizable Area Start
const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
const webStyle = {
    root: {
        "& .MuiToggleButtonGroup-root": {
            borderRadius: "20px",
            backgroundColor: "white",
        },
        "& .MuiToggleButton-root:hover": {
            backgroundColor: "white",
        },
        "& .apexcharts-datalabels-group": {
            opacity: "0 !important",
        },
        "& .apexcharts-text tspan": {
            color: "#191D32",
            textAlign: "right",
            fontFamily: "Poppins-Medium",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "24px",
        }
    },
    enriqueButton: {
        textAlign: "center",
        "@media (max-width: 840px)": {
            marginTop: "5%",
        },
    },
    viewAnalyticsContainer: {
        display: "flex",
        justifyContent: "center",
        marginTop: "35px",
        marginBottom: "40px",
        "@media (max-width: 840px)": {
            display: "flex",
            justifyContent: "start",
        },
    },
    chartCard: {
        height: "320px",
        borderRadius: 16,
        padding: "10px 30px",
        overflowX: "scroll"
    },
    detailsCard: {
        borderRadius: 16,
        padding: 30,
        display: 'flex',
        flexDirection: 'column',
        height: '280px',
        '@media (max-width: 840px)': {
            width: '260px',
        },
    },
    analyticsHeadingTxt: {
        color: "#2C2C2E",
        fontFamily: "Poppins-Medium",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "30px",
        marginLeft: "15px",
        '@media (max-width: 840px)': {
            textAlign: 'start',
        },
    },
    chartHeading: {
        position: "absolute",
        color: "#191D32",
        fontFamily: "Poppins-Medium",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "24px" /* 133.333% */,
    },
    chartSubHeading: {
        color: "#2C2C2E",
        fontFamily: "Poppins-Medium",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "24px" /* 133.333% */,
        display: "flex",
        justifyContent: "space-between",
        marginBottom: 15,
    },
    chartSubTxt: {
        color: "#757575",
        fontFamily: "Poppins-Medium",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "24px" /* 133.333% */,
        display: "flex",
        justifyContent: "space-between",
        marginBottom: 15,
    },
    salesCard: {
        borderRadius: 16,
        padding: 25,
        display: 'flex',
        flexDirection: 'column',
        '@media (max-width: 840px)': {
            width: '245px',
        },
    }
};
//@ts-ignore
export default withTranslation()(withStyles(webStyle)(Analytics));
// Customizable Area End
