// Customizable Area Start
import React from "react";
import CustomerSubcategoryScreenController from "../../appointmentmanagement/src/CustomerSubcategoryScreenController.web";
import Rating from '@material-ui/lab/Rating';
import { white_left_arrow, unfilled_star, star_main_rate, defultPng } from "../../appointmentmanagement/src/assets";
import "../../appointmentmanagement/assets/style/availableslot.web.scss";
import ".../../../packages/blocks/reviews/assets/SqueesMeInRating.web.scss";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Loader from "../../../components/src/Loader.web";
const Cookies = require("js-cookie");
// Customizable Area End
class SqueezMeinRatting extends CustomerSubcategoryScreenController {
    // Customizable Area Start
    render() {
        var _a;
        this.call_shope_one_times = 'call_last';
        let { t } = this.props;
        let webAppDirection = localStorage.getItem("webAppDirection") ||
            Cookies.get("webAppDirection") ||
            "ltr";
        return (React.createElement(React.Fragment, null,
            React.createElement(Loader, { loading: this.state.isLoading }),
            React.createElement("div", { className: "main-div" },
                React.createElement("div", { className: "services-header" },
                    React.createElement("img", { src: white_left_arrow, alt: "", id: "service_left_arrow", style: { transform: webAppDirection === "ltr" ? "scaleX(1)" : "scaleX(-1)" }, onClick: () => {
                            var _a;
                            this.props.history.push({
                                pathname: '/CustomerSubcategoryDetail',
                                state: { gender: this.state.gender, show_gender: this.state.show_gender, subCategory: this.state.currentCategory, id: this.state.categoryId, subId: (_a = this.props.location.state) === null || _a === void 0 ? void 0 : _a.subId }
                            });
                        } }),
                    React.createElement("h1", null, t("Reviews"))),
                React.createElement("div", { className: "main-rating-div" },
                    React.createElement("div", { className: "reviews-rating-div" }, ((_a = this.state.reviewResponse) === null || _a === void 0 ? void 0 : _a.length) != 0 ? this.state.reviewResponse.map((element, index) => {
                        return (React.createElement("div", { className: "reviews-scroll-div", key: element.id },
                            React.createElement("div", { className: "sub-reviews-div" },
                                React.createElement("div", { className: "profile-div" },
                                    React.createElement("img", { src: element.attributes.reviewer.data.attributes.profile_image || defultPng, alt: "" }),
                                    React.createElement("div", null,
                                        React.createElement("h1", null, element.attributes.reviewer.data.attributes.full_name || ""),
                                        React.createElement("p", null, element.attributes.review_date || ""))),
                                React.createElement(Rating, { name: "hover-feedback", value: element.attributes.rating || 0, readOnly: true, emptyIcon: React.createElement("img", { src: unfilled_star, style: { width: "26px", height: "26px", marginTop: '-3px', marginLeft: -3 } }), icon: React.createElement("img", { src: star_main_rate, style: { width: "20px", height: "20px", marginRight: 3 } }) }),
                                React.createElement("h6", null, element.attributes.description || ""),
                                React.createElement("p", null,
                                    t("Services"),
                                    ": ",
                                    React.createElement("b", null, element.attributes.display_name || "")))));
                    }) : React.createElement("h1", null, t("No data available")))))));
    }
}
//@ts-ignore
export default withTranslation()(withRouter(SqueezMeinRatting));
