// Customizable Area Start
import React from "react";
import ManageEmployee from "./ManageEmployee.web";
import { withRouter } from "react-router-dom";
import EmployeeManageBookingsWebController from "../../src/EmployeeProfile/EmployeeManageBookingsWebController.web";
import Loader from "../../../../../packages/components/src/Loader.web";
import "../../../contentmoderation/assets/Styles/manage-bookings-business.web.css";
import ManageBookingsListing from "../../../contentmoderation/src/ManageBookingsListing.web";
import ManageBookingsAppointmentDetails from "../../../contentmoderation/src/ManageBookingsAppointmentDetails.web";
import ReviewSuccessModal from "../../../contentmoderation/src/ReviewSuccessModal.web";
const Cookies = require("js-cookie");
class EmployeeManageBookings extends EmployeeManageBookingsWebController {
    render() {
        let webAppDirection = localStorage.getItem("webAppDirection") ||
            Cookies.get("webAppDirection") ||
            "ltr";
        return (React.createElement(React.Fragment, null,
            React.createElement(Loader, { loading: this.state.loading }),
            React.createElement("div", { className: "manage-catalouge-main-container" },
                React.createElement(ManageEmployee, null),
                React.createElement("div", { className: webAppDirection === "ltr"
                        ? "manage-booking-business-content-container"
                        : "arabic-manage-booking-business-content-container", "data-testid": "bookings-list-container", ref: this.listRef, onScroll: () => this.handleScrolling() },
                    !this.state.isViewingAppointment ? (React.createElement(ManageBookingsListing, { state: this.state, data: this.state.selectedDataView === "upcoming"
                            ? this.state.upcomingAppointments
                            : this.state.pastAppointments, handleSearchOpenClose: () => this.handleSearchOpenClose(), handlePastBookingsView: () => this.handlePastBookingsView(), handleUpcomingBookingsView: () => this.handleUpcomingBookingsView(), handleAppointmentOnclick: (appointment) => this.handleAppointmentOnclick(appointment), handleFilterModalChange: () => this.handleFilterModalChangeInEmployee(), handleFilterValueChanges: (e) => this.handleFilterValueChangesInEmployee(e), getAllAppointments: this.getAllAppointments, resetFilterSearchValue: () => this.resetFilterSearchValueEmployeeBookings() })) : (React.createElement(ManageBookingsAppointmentDetails
                    //@ts-ignore
                    , { 
                        //@ts-ignore
                        handleBack: () => this.handleBackOnListing(), storeTime: (time) => this.storeTime(time), getTime: (time) => this.getTime(time), onStart: () => this.startTimerApiCall(), onStop: () => this.stopTimerApiCall(), onCancel: () => this.cancelAppointment(), onNoshow: () => this.noShowApiCall(), onReview: () => this.reviewApiCall(), handleBookingCodeChange: (code) => this.handleBookingCodeChange(code), handleRatingStarChange: (stars) => this.handleRatingStarChange(stars), handleReviewTextChange: (review) => this.handleReviewTextChange(review), "data-testid": "ManageBookingsAppointmentDetails", remainingTime: this.state.remainingTime, restart: this.state.restart, startParams: this.state.startParams, appointmentData: this.state.selectedAppointmentForView, enableStartButton: this.state.enableStartButton, enableCancelButton: this.state.enableCancelButton, enableNoShowButton: this.state.enableNoShowButton, enableResechduleButton: this.state.enableResechduleButton, otpCode: this.state.otpCode, bookingCodeError: this.state.bookingCodeError, givenRating: this.state.givenRating, reviewText: this.state.reviewText, bookingCodeErrorMessage: this.state.bookingCodeErrorMessage })),
                    this.state.reviewSuccessModal && (React.createElement(ReviewSuccessModal, { openDialog: this.state.reviewSuccessModal, reviewAlreadySubmitted: this.state.isReviewAlreadySubmitted, goHome: this.handleGoHomeAfterReview }))))));
    }
}
//@ts-ignore
export default withRouter(EmployeeManageBookings);
// Customizable Area End
