// Customizable Area Start
import React from "react";
import TeamBusinessWorkingController from "./TeamBusinessWorkingController.web";
import "../assets/Styles/ManageWorkingHours.scss";
import ".../../../../src/packages/blocks/CustomisableUserProfiles/assets/Styles/Userprofile.css";
import { search_Bitmap, left_time_ar, right_time_ar } from "./assets";
import { withRouter } from "react-router-dom";
import ManageBusinessWeb from "./ManageBusiness.web";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { TextField, InputAdornment, Button, Card } from "@material-ui/core";
import { ArrowDown, ArrowUp, } from ".../../../../src/packages/blocks/CustomisableUserProfiles/src/assets";
const Cookies = require("js-cookie");
import { withTranslation } from "react-i18next";
import { defultPng } from "../../appointmentmanagement/src/assets";
import Loader from "../../../components/src/Loader.web";
class TimeWorkingDays extends TeamBusinessWorkingController {
    constructor(props) {
        super(props);
        this.loadListTeam = () => {
            var _a, _b;
            let webAppDirection = localStorage.getItem("webAppDirection") ||
                Cookies.get("webAppDirection") ||
                "ltr";
            return ((_a = this.state.responseTeamList) === null || _a === void 0 ? void 0 : _a.length) !== 0
                ? (_b = this.state.responseTeamList) === null || _b === void 0 ? void 0 : _b.map((element) => {
                    var _a, _b, _c, _d;
                    return (React.createElement(React.Fragment, { key: element === null || element === void 0 ? void 0 : element.id },
                        "\u00A0\u00A0\u00A0\u00A0",
                        React.createElement("div", { className: "profile-first-div", onClick: () => {
                                this.getTeamMemberListApi(element === null || element === void 0 ? void 0 : element.id);
                                this.setState({ normalMode: false });
                            } },
                            React.createElement("img", { className: "our-staff-img", src: ((_a = element === null || element === void 0 ? void 0 : element.attributes) === null || _a === void 0 ? void 0 : _a.team_member_profile) || defultPng, alt: "" }),
                            React.createElement("div", null,
                                React.createElement("h1", null, ((_b = element === null || element === void 0 ? void 0 : element.attributes) === null || _b === void 0 ? void 0 : _b.first_name) +
                                    " " + ((_c = element === null || element === void 0 ? void 0 : element.attributes) === null || _c === void 0 ? void 0 : _c.last_name)),
                                React.createElement("p", null, (_d = element === null || element === void 0 ? void 0 : element.attributes) === null || _d === void 0 ? void 0 : _d.email_address)),
                            React.createElement("img", { style: {
                                    marginLeft: webAppDirection === "ltr" ? "auto" : "",
                                    marginRight: webAppDirection === "rtl" ? "auto" : "",
                                }, id: "team-working-days-back-image", src: right_time_ar, alt: "" }))));
                }) : "";
        };
        this.getSelectionWorkingPart = () => {
            let { t } = this.props;
            const { workingHoursError, weekendHoursError } = this.state;
            const borderStyleworkingHours = this.getBorderStyle(workingHoursError);
            const borderStyleweekendHours = this.getBorderStyle(weekendHoursError);
            let webAppDirection = Cookies.get("webAppDirection") ||
                localStorage.getItem("webAppDirection") ||
                "ltr";
            let imgStyle = {
                left: webAppDirection === "ltr" ? "auto" : "10px",
                right: webAppDirection === "rtl" ? "auto" : "10px",
            };
            return this.state.getSelectionMode === "weekdays" ? (React.createElement(React.Fragment, null,
                " ",
                React.createElement("h1", { className: "h1-work" }, t("Work Days & Time")),
                React.createElement("div", { className: "input-container-item-time" },
                    React.createElement("div", { className: "hours-form" },
                        React.createElement("div", { style: {
                                position: "absolute",
                                marginBottom: 50,
                            } },
                            React.createElement("span", { className: "profile-form-sub-header" }, t("From"))),
                        React.createElement("div", { className: "form-custom-select" },
                            React.createElement("select", { onChange: (event) => {
                                    this.onWorkingFromSelection(event.target.value);
                                }, value: this.state.workingFrom, onClick: () => {
                                    this.handleSelectToggle("isSelectOpenWeekHoursFrom");
                                }, style: {
                                    border: borderStyleworkingHours,
                                    borderRadius: 8,
                                }, id: "SelectWeekHours" }, this.state.workingSlot.map((option, workingFromIndex) => {
                                let [time, amPm] = this.showTimeInUppercase(option).split(" ");
                                return (React.createElement("option", { key: workingFromIndex, disabled: this.optionDisable(option), id: workingFromIndex, value: option },
                                    time,
                                    " ",
                                    t(amPm)));
                            })),
                            React.createElement("img", { src: this.state.isSelectOpenWeekHoursFrom ? ArrowUp : ArrowDown, alt: "Custom Icon", style: imgStyle }))),
                    React.createElement("div", { className: "hours-to" },
                        React.createElement("div", { style: {
                                position: "absolute",
                                marginBottom: 50,
                            } },
                            React.createElement("span", { className: "profile-form-sub-header" }, t("To"))),
                        React.createElement("div", { className: "form-custom-select" },
                            React.createElement("select", { id: "mySelectTo", value: this.state.workingTo, onChange: (event) => {
                                    this.setState({
                                        workingTo: event.target.value,
                                        workingHoursError: "",
                                    });
                                }, disabled: this.state.isWorkingToDisable, onClick: () => {
                                    this.handleSelectToggle("isSelectOpenWeekHoursTo");
                                }, style: {
                                    border: borderStyleworkingHours,
                                    borderRadius: 8,
                                } }, this.state.workingSlotTo.map((option, workingToIndex) => {
                                let [time, amPm] = this.showTimeInUppercase(option).split(" ");
                                return (React.createElement("option", { key: workingToIndex, disabled: this.optionDisable(option), id: workingToIndex, value: option },
                                    time,
                                    " ",
                                    t(amPm)));
                            })),
                            React.createElement("img", { style: imgStyle, src: this.state.isSelectOpenWeekHoursTo ? ArrowUp : ArrowDown, alt: "Custom Icon" })))))) : (React.createElement(React.Fragment, null,
                React.createElement("h1", { className: "h1-work" }, t("Work Days & Time")),
                React.createElement("div", { className: "input-container-item-time" },
                    React.createElement("div", { className: "hours-form" },
                        React.createElement("div", { style: {
                                position: "absolute",
                                marginBottom: 50,
                            } },
                            React.createElement("span", { className: "profile-form-sub-header" }, t("From"))),
                        React.createElement("div", { className: "form-custom-select" },
                            React.createElement("select", { id: "weekend_select_from", value: this.state.weekendFrom, onChange: (event) => {
                                    this.onWeekendFromSelection(event.target.value);
                                }, onClick: () => {
                                    this.handleSelectToggle("isSelectOpenWeekEndsFrom");
                                }, style: {
                                    border: borderStyleweekendHours,
                                    borderRadius: 8,
                                } }, this.state.workingSlot.map((option, weekendFromIndex) => {
                                let [time, amPm] = this.showTimeInUppercase(option).split(" ");
                                return (React.createElement("option", { key: weekendFromIndex, disabled: this.optionDisable(option), id: weekendFromIndex, value: option },
                                    time,
                                    " ",
                                    t(amPm)));
                            })),
                            React.createElement("img", { src: this.state.isSelectOpenWeekEndsFrom ? ArrowUp : ArrowDown, alt: "Custom Icon", style: imgStyle }))),
                    React.createElement("div", { className: "hours-to" },
                        React.createElement("div", { style: {
                                marginBottom: 50,
                                position: "absolute",
                            } },
                            React.createElement("span", { className: "profile-form-sub-header" }, t("To"))),
                        React.createElement("div", { className: "form-custom-select" },
                            React.createElement("select", { id: "weekend_select_to", value: this.state.weekendTo, disabled: this.state.isWeekendToDisable, onChange: (event) => {
                                    this.setState({
                                        weekendTo: event.target.value,
                                        weekendHoursError: "",
                                    });
                                }, style: {
                                    borderRadius: 8,
                                    border: borderStyleweekendHours,
                                }, onClick: () => {
                                    this.handleSelectToggle("isSelectOpenWeekEndsTo");
                                } }, this.state.weekendSlotTo.map((option, weekendToIndex) => {
                                let [time, amPm] = this.showTimeInUppercase(option).split(" ");
                                return (React.createElement("option", { key: weekendToIndex, disabled: this.optionDisable(option), id: weekendToIndex, value: option },
                                    time,
                                    " ",
                                    t(amPm)));
                            })),
                            React.createElement("img", { src: this.state.isSelectOpenWeekEndsTo ? ArrowUp : ArrowDown, style: imgStyle, alt: "Custom Icon" }))))));
        };
        this.getSelectionBlockPart = () => {
            let { t } = this.props;
            const { BlockTimeHoursError, BlockTimeWeekendHoursError } = this.state;
            const borderStyleBlockTimeHours = this.getBorderStyle(BlockTimeHoursError);
            const borderStyleBlockTimeWeekendHours = this.getBorderStyle(BlockTimeWeekendHoursError);
            let webAppDirection = Cookies.get("webAppDirection") ||
                localStorage.getItem("webAppDirection") ||
                "ltr";
            let blockTimeImgStyle = {
                right: webAppDirection === "rtl" ? "auto" : "10px",
                left: webAppDirection === "ltr" ? "auto" : "10px",
            };
            return this.state.getSelectionMode === "weekdays" ? (React.createElement(React.Fragment, null,
                " ",
                React.createElement("h1", { className: "h1-work" }, t("Block time")),
                React.createElement("div", { className: "input-container-item-time" },
                    React.createElement("div", { className: "hours-form" },
                        React.createElement("div", { style: {
                                position: "absolute",
                                marginBottom: 50,
                            } },
                            React.createElement("span", { className: "profile-form-sub-header" },
                                t("From"),
                                " ")),
                        React.createElement("div", { className: "form-custom-select" },
                            React.createElement("select", { id: "SelectWeekHours", onChange: (event) => {
                                    this.onBlockFromSelection(event.target.value);
                                }, onClick: () => {
                                    this.handleSelectToggle("isSelectOpenBlockTimeHoursFrom");
                                }, style: {
                                    border: borderStyleBlockTimeHours,
                                    borderRadius: 8,
                                }, value: this.state.BlockTimeFrom }, this.state.workingSlot.map((option, blockWorkingFromIndex) => {
                                let [time, amPm] = this.showTimeInUppercase(option).split(" ");
                                return (React.createElement("option", { key: blockWorkingFromIndex, disabled: this.optionDisable(option), id: blockWorkingFromIndex, value: option },
                                    time,
                                    " ",
                                    t(amPm)));
                            })),
                            React.createElement("img", { src: this.state.isSelectOpenBlockTimeHoursFrom
                                    ? ArrowUp
                                    : ArrowDown, style: blockTimeImgStyle, alt: "Custom Icon" }))),
                    React.createElement("div", { className: "hours-to" },
                        React.createElement("div", { style: {
                                position: "absolute",
                                marginBottom: 50,
                            } },
                            React.createElement("span", { className: "profile-form-sub-header" }, t("To"))),
                        React.createElement("div", { className: "form-custom-select" },
                            React.createElement("select", { id: "mySelectTo", value: this.state.BlockTimeTo, disabled: this.state.isBlockToDisable, onChange: (event) => {
                                    this.setState({
                                        BlockTimeTo: event.target.value,
                                        BlockTimeHoursError: "",
                                    });
                                }, onClick: () => {
                                    this.handleSelectToggle("isSelectOpenBlockTimeHoursTo");
                                }, style: {
                                    border: borderStyleBlockTimeHours,
                                    borderRadius: 8,
                                } }, this.state.blockFromSlot.map((option, blockWorkingToIndex) => {
                                let [time, amPm] = this.showTimeInUppercase(option).split(" ");
                                return (React.createElement("option", { key: blockWorkingToIndex, disabled: this.optionDisable(option), id: blockWorkingToIndex, value: option },
                                    time,
                                    " ",
                                    t(amPm)));
                            })),
                            React.createElement("img", { style: blockTimeImgStyle, src: this.state.isSelectOpenBlockTimeHoursTo ? ArrowUp : ArrowDown, alt: "Custom Icon" })))))) : (React.createElement(React.Fragment, null,
                React.createElement("h1", { className: "h1-work" }, t("Block time")),
                React.createElement("div", { className: "input-container-item-time" },
                    React.createElement("div", { className: "hours-form" },
                        React.createElement("div", { style: {
                                position: "absolute",
                                marginBottom: 50,
                            } },
                            React.createElement("span", { className: "profile-form-sub-header" }, t("From"))),
                        React.createElement("div", { className: "form-custom-select" },
                            React.createElement("select", { value: this.state.BlockTimeFromWeekends, id: "block_weekend_from", onChange: (event) => {
                                    this.setState({
                                        isBlockToWeekendDisable: false,
                                        BlockTimeFromWeekends: event.target.value,
                                        BlockTimeWeekendHoursError: "",
                                    });
                                    this.setBlockToTimeToArr(event.target.value);
                                }, onClick: () => {
                                    this.handleSelectToggle("isSelectOpenBlockTimeHoursWeekendsFrom");
                                }, style: {
                                    border: borderStyleBlockTimeWeekendHours,
                                    borderRadius: 8,
                                } }, this.state.workingSlot.map((option, blockWeekendFromIndex) => {
                                let [time, amPm] = this.showTimeInUppercase(option).split(" ");
                                return (React.createElement("option", { key: blockWeekendFromIndex, disabled: this.optionDisable(option), id: blockWeekendFromIndex, value: option },
                                    time,
                                    " ",
                                    t(amPm)));
                            })),
                            React.createElement("img", { src: this.state.isSelectOpenBlockTimeHoursWeekendsFrom
                                    ? ArrowUp
                                    : ArrowDown, style: blockTimeImgStyle, alt: "Custom Icon" }))),
                    React.createElement("div", { className: "hours-to" },
                        React.createElement("div", { style: {
                                position: "absolute",
                                marginBottom: 50,
                            } },
                            React.createElement("span", { className: "profile-form-sub-header" }, t("To"))),
                        React.createElement("div", { className: "form-custom-select" },
                            React.createElement("select", { value: this.state.BlockTimeToWeekends, disabled: this.state.isBlockToWeekendDisable, id: "block_weekend_to", onChange: (event) => {
                                    this.setState({
                                        BlockTimeToWeekends: event.target.value,
                                        BlockTimeWeekendHoursError: "",
                                    });
                                }, onClick: () => {
                                    this.handleSelectToggle("isSelectOpenBlockTimeHoursWeekendsTo");
                                }, style: {
                                    borderRadius: 8,
                                    border: borderStyleBlockTimeWeekendHours,
                                } }, this.state.blockToslotweekend.map((option, blockWeekendToIndex) => {
                                let [time, amPm] = this.showTimeInUppercase(option).split(" ");
                                return (React.createElement("option", { key: blockWeekendToIndex, disabled: this.optionDisable(option), id: blockWeekendToIndex, value: option },
                                    time,
                                    " ",
                                    t(amPm)));
                            })),
                            React.createElement("img", { src: this.state.isSelectOpenBlockTimeHoursWeekendsTo
                                    ? ArrowUp
                                    : ArrowDown, alt: "Custom Icon", style: blockTimeImgStyle }))))));
        };
        this.workingHrError = () => {
            let { t } = this.props;
            return (this.state.workingHoursError && (React.createElement("div", { style: {
                    position: "relative",
                    marginBottom: 10,
                } },
                React.createElement("span", { className: "profile-error-message-text" }, t(this.state.workingHoursError)))));
        };
        this.weekendHrError = () => {
            let { t } = this.props;
            return (this.state.weekendHoursError && (React.createElement("div", { style: {
                    marginBottom: 10,
                    position: "relative",
                    marginLeft: 60,
                } },
                React.createElement("span", { className: "profile-error-message-text" }, t(this.state.weekendHoursError)))));
        };
        this.blockTimeHrError = () => {
            let { t } = this.props;
            return (this.state.BlockTimeHoursError && (React.createElement("div", { style: {
                    position: "relative",
                    marginBottom: 10,
                    marginLeft: 60,
                } },
                React.createElement("span", { className: "profile-error-message-text" }, t(this.state.BlockTimeHoursError)))));
        };
        this.allData = () => {
            var _a, _b, _c, _d;
            let webAppDirection = localStorage.getItem("webAppDirection") ||
                Cookies.get("webAppDirection") ||
                "ltr";
            let { t } = this.props;
            return this.state.normalMode ? (React.createElement("div", { className: webAppDirection === "ltr"
                    ? "main-time-hours-detail-list"
                    : "arabic-main-time-hours-detail-list" },
                React.createElement("h1", null,
                    React.createElement("img", { src: left_time_ar, id: "team_working_back_img", style: { cursor: "pointer" }, onClick: () => {
                            this.props.history.push("/ManageBusiness/ManageWorkingHours");
                        }, alt: "" }),
                    " ",
                    "\u00A0",
                    t("Team working days & time")),
                "\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0",
                React.createElement("div", { className: "main_content-div" },
                    React.createElement("div", { className: "input-div" },
                        React.createElement(TextField, { InputProps: {
                                startAdornment: (React.createElement(InputAdornment, { position: "start" },
                                    React.createElement("img", { src: search_Bitmap, className: "input-icon", alt: "" }))),
                            }, id: "outlined-basic", value: this.state.searchValue, className: "seache-sevice-input", variant: "outlined", placeholder: t("Search by name"), onChange: this.handleEmpSearch })),
                    React.createElement("div", { className: "items-div" }, this.loadListTeam())))) : (React.createElement("div", { className: webAppDirection === "ltr"
                    ? "main-time-hours-detail-list"
                    : "arabic-main-time-hours-detail-list" },
                React.createElement("h1", null,
                    React.createElement("img", { src: left_time_ar, alt: "", id: "load_api_img", style: { cursor: "pointer" }, onClick: this.handleBackToNormalMode }),
                    " ",
                    "\u00A0",
                    t("Edit {{emp_name}}'s working days & time", {
                        emp_name: (_b = (_a = this.state.responseTeamList) === null || _a === void 0 ? void 0 : _a.attributes) === null || _b === void 0 ? void 0 : _b.first_name,
                    })),
                "\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0",
                React.createElement("div", { className: "input-container-button" },
                    React.createElement(ToggleButtonGroup, null,
                        React.createElement(ToggleButton, { style: {
                                borderRadius: 24,
                                width: 150,
                                border: 0,
                                height: 44,
                                fontWeight: 500,
                                fontSize: 14,
                                color: this.getTextColor(this.state.getSelectionMode, "weekdays"),
                                textTransform: "none",
                                backgroundColor: this.getBackGroundColor(this.state.getSelectionMode, "weekdays"),
                            }, value: "left", id: "btnSelect", className: "weekdays", onClick: () => {
                                this.setState({ getSelectionMode: "weekdays" });
                            }, "aria-label": "left aligned" },
                            React.createElement("span", { className: "fontfamily-profile" }, t("Weekday"))),
                        React.createElement(ToggleButton, { style: {
                                borderRadius: 24,
                                width: 150,
                                border: 0,
                                height: 44,
                                fontWeight: 500,
                                textTransform: "none",
                                fontSize: 14,
                                color: this.getTextColor(this.state.getSelectionMode, "weekends"),
                                fontFamily: "Poppins",
                                backgroundColor: this.getBackGroundColor(this.state.getSelectionMode, "weekends"),
                            }, onClick: () => {
                                this.setState({ getSelectionMode: "weekends" });
                            }, value: "center", id: "weekEnds", "aria-label": "centered" },
                            React.createElement("span", { className: "fontfamily-profile" }, t("Weekend"))))),
                React.createElement("div", { className: "profile-left-side-label" },
                    React.createElement(Card, { className: "profile-business-working-hours-card", style: { border: "none", boxShadow: "none" } },
                        React.createElement("div", { className: "input-container-left" },
                            this.getSelectionWorkingPart(),
                            this.getSelectionBlockPart(),
                            React.createElement("div", { className: "input-container-item-time" },
                                this.workingHrError(),
                                this.weekendHrError(),
                                this.blockTimeHrError()),
                            React.createElement("div", { className: "form-working-days" },
                                React.createElement("span", { className: "form-label", style: { color: "#2C2C2E" } }, t("Working Days"))),
                            React.createElement("div", { className: "form-working-days-label" },
                                React.createElement("div", { style: {
                                        flexDirection: "row",
                                        flexWrap: "wrap",
                                    }, className: "form-working-days-label-button" }, this.state.workingDaysData.map((item) => {
                                    return (React.createElement(Button, { className: "profile-from-button", key: item.id, id: "work_days_list_btn", onClick: () => {
                                            this.handleWorkingHoursSelection(item.id);
                                        }, style: {
                                            padding: 0,
                                            border: 0,
                                            background: 0,
                                            marginRight: 10,
                                            borderRadius: "27px",
                                            width: "144px",
                                            height: "54px",
                                            fontSize: "18px",
                                            marginTop: 10,
                                            backgroundColor: this.handleBackgroundColor(item),
                                            color: this.handleColor(item),
                                        }, color: "primary" },
                                        React.createElement("span", { className: "fontfamily-profile" }, t(item.day))));
                                }))),
                            ((_c = this.state.workingDays) === null || _c === void 0 ? void 0 : _c.length) === 0 && !this.state.loading && (React.createElement("p", { style: {
                                    color: "#ff453a",
                                    paddingLeft: "58px",
                                    paddingTop: "10px",
                                    fontSize: "16px",
                                } }, t("Please choose your working days."))),
                            ((_d = this.state.weekendDays) === null || _d === void 0 ? void 0 : _d.length) === 0 && !this.state.loading && (React.createElement("p", { style: {
                                    color: "#ff453a",
                                    paddingLeft: "58px",
                                    paddingTop: "10px",
                                    fontSize: "16px",
                                } }, t("Please choose your weekend days."))),
                            React.createElement("div", { className: "cancel_deactivate-btn" },
                                React.createElement(Button, { className: "cancel-btn-time", onClick: () => {
                                        this.getTeamMemberListApi();
                                    } }, t("Cancel")),
                                "\u00A0\u00A0\u00A0\u00A0",
                                React.createElement(Button, { className: "deactivate-btn", variant: "contained", disabled: this.checkDisablity(), id: "updateData_btn", onClick: () => {
                                        this.updateUserdetails();
                                    } }, t("Update"))))))));
        };
    }
    render() {
        return (React.createElement("div", { className: "manage-catalouge-main-container" },
            React.createElement(ManageBusinessWeb, null),
            React.createElement(Loader, { loading: this.state.loading }),
            this.allData()));
    }
}
//@ts-ignore
export default withTranslation()(withRouter(TimeWorkingDays));
// Customizable Area End
