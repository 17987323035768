var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// Customizable Area Start
import React from "react";
import { Message } from "framework/src/Message";
import MessageEnum, { getName, } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { BlockComponent } from "framework/src/BlockComponent";
//@ts-ignore
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { baseUrlConfig } from "../../contentmoderation/src/BusinessManageBookingsWebController";
//@ts-ignore
import { createConsumer } from "@rails/actioncable";
import { getStorageData } from "framework/src/Utilities";
toast.configure();
export const configJSON = require("../../../blocks/appointmentmanagement/src/config");
export default class MyBookingController extends BlockComponent {
    // Customizable Area End
    // Customizable Area Start
    constructor(props) {
        super(props);
        // Customizable Area Start
        this.sendAppointmentcreation = "";
        this.getMyAppointmentApiCallId = "";
        this.getOneAppointmentApiCallId = "";
        this.bookingScreen = "";
        this.invoiceApiCallId = "";
        this.editAppointmentcreation = "";
        this.getallIssueApiCallId = "";
        this.createReviewApiId = "";
        this.callallApi = (bookingscreen) => {
            var _a, _b, _c, _d;
            if (bookingscreen == 'yes') {
                this.getMyallAppoitments();
                this.getAppointmentFromindexDb(true);
            }
            if ((_a = this.props.location) === null || _a === void 0 ? void 0 : _a.state) {
                localStorage.removeItem("appointmentIdFromIndexDb");
                let { appointmentId } = (_b = this.props.location) === null || _b === void 0 ? void 0 : _b.state;
                if (appointmentId) {
                    this.getOneAppoitments(appointmentId);
                    this.getIsues();
                }
            }
            if (!((_d = (_c = this.props.location) === null || _c === void 0 ? void 0 : _c.state) === null || _d === void 0 ? void 0 : _d.appointmentId)) {
                this.getAppointmentFromindexDb(false);
                setTimeout(() => {
                    let appointmentId = localStorage.getItem("appointmentIdFromIndexDb") || localStorage.getItem("appointmentIdFromIndexDb1") || '';
                    if (appointmentId) {
                        this.getOneAppoitments(appointmentId);
                        this.getIsues();
                    }
                }, 1000);
            }
        };
        this.gotoThatPage = () => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
            this.props.history.push({
                pathname: '/customer/service/booking',
                state: { serviceId: (_b = (_a = this.state.appointmentdata) === null || _a === void 0 ? void 0 : _a.attributes) === null || _b === void 0 ? void 0 : _b.service_id, gender: this.state.appointmentdata.attributes.business_profile.data.attributes.service_provided.name, show_gender: this.state.appointmentdata.attributes.business_profile.data.attributes.service_provided.display_name, staffId: (_d = (_c = this.state.appointmentdata) === null || _c === void 0 ? void 0 : _c.attributes) === null || _d === void 0 ? void 0 : _d.service_id, shopSubResponse: (_g = (_f = (_e = this.state.appointmentdata) === null || _e === void 0 ? void 0 : _e.attributes) === null || _f === void 0 ? void 0 : _f.business_profile) === null || _g === void 0 ? void 0 : _g.data, subServiceid: (_l = (_k = (_j = (_h = this.state.appointmentdata) === null || _h === void 0 ? void 0 : _h.attributes) === null || _j === void 0 ? void 0 : _j.business_profile) === null || _k === void 0 ? void 0 : _k.data) === null || _l === void 0 ? void 0 : _l.id, element: this.state.appointmentdata.attributes.service, name: [this.state.appointmentdata.attributes.service.display_name || "", (_p = (_o = (_m = this.state.appointmentdata) === null || _m === void 0 ? void 0 : _m.attributes) === null || _o === void 0 ? void 0 : _o.service) === null || _p === void 0 ? void 0 : _p.service_cost, (_s = (_r = (_q = this.state.appointmentdata) === null || _q === void 0 ? void 0 : _q.attributes) === null || _r === void 0 ? void 0 : _r.service) === null || _s === void 0 ? void 0 : _s.currency], reschedule: true, appointmentId: (_t = this.state.appointmentdata) === null || _t === void 0 ? void 0 : _t.id }
            });
        };
        this.checkDisablity = () => {
            let { appointmentdata } = this.state;
            let { attributes } = appointmentdata || {};
            let { customer_rescheduled_count, offer } = attributes || {};
            if (customer_rescheduled_count >= 5 || offer) {
                let btn = document.querySelector('#updateData_btn');
                btn && btn.setAttribute("style", 'color: #ffffff63; cursor: no-drop;');
                return true;
            }
            else {
                let btn = document.querySelector('#updateData_btn');
                btn && btn.setAttribute("style", 'color: #ffffff; cursor: pointer;');
                return false;
            }
        };
        //istanbul ignore next
        this.getAppointmentFromindexDb = (isDelete) => {
            function connectDB(functionArgs) {
                const request = self.indexedDB.open('squeezMein', 1);
                request.onsuccess = function () {
                    functionArgs(request.result);
                };
                request.onupgradeneeded = function (event) {
                    const database = event.target.result;
                    database.createObjectStore('squeez', { autoIncrement: true });
                    connectDB(functionArgs);
                };
            }
            connectDB((database) => {
                const transition = database.transaction(['squeez'], 'readwrite');
                const objectStore = transition.objectStore('squeez');
                const objectStoreRequest = objectStore.getAll();
                objectStoreRequest.onsuccess = function () {
                    var _a, _b, _c;
                    if (isDelete) {
                        objectStore.delete(0);
                        localStorage.removeItem("appointmentIdFromIndexDb");
                        localStorage.removeItem("appointmentIdFromIndexDb1");
                    }
                    else {
                        localStorage.setItem("appointmentIdFromIndexDb", ((_c = (_b = (_a = objectStoreRequest === null || objectStoreRequest === void 0 ? void 0 : objectStoreRequest.result[0]) === null || _a === void 0 ? void 0 : _a.payload) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.appointment_id) || "");
                    }
                };
            });
        };
        this.CancelAppointmentAccount = () => {
            var _a;
            this.setState({ isloading: true, openModalInvoice: false, });
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
                token: localStorage.getItem("token") || "",
            };
            const httpBody = {
                id: (_a = this.state.appointmentdata) === null || _a === void 0 ? void 0 : _a.id,
            };
            const cancelAppointmentRequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.editAppointmentcreation = cancelAppointmentRequestMessage.messageId;
            cancelAppointmentRequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.cancelAppoitment);
            cancelAppointmentRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            cancelAppointmentRequestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
            cancelAppointmentRequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.putMethod);
            runEngine.sendMessage(cancelAppointmentRequestMessage.id, cancelAppointmentRequestMessage);
            return true;
        };
        this.senEmailInvoice = () => {
            var _a;
            this.setState({ invoiceorCancel: 'invoice', isloading: true });
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
                token: localStorage.getItem("token") || "",
            };
            const emailInvoiceRequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.invoiceApiCallId = emailInvoiceRequestMessage.messageId;
            emailInvoiceRequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.sendEmil + '/' + ((_a = this.state.appointmentdata) === null || _a === void 0 ? void 0 : _a.id));
            emailInvoiceRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            emailInvoiceRequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getHttpRequest);
            runEngine.sendMessage(emailInvoiceRequestMessage.id, emailInvoiceRequestMessage);
            return true;
        };
        this.handleSearchBookings = (value) => {
            if (this.debounceTimer) {
                clearTimeout(this.debounceTimer);
            }
            this.debounceTimer = setTimeout(() => {
                if (value) {
                    this.searchAppoitments(value);
                }
                else {
                    this.filterShowResultsOnClick();
                }
            }, 1000);
        };
        this.searchAppoitments = (value) => __awaiter(this, void 0, void 0, function* () {
            this.setState({ isloading: true, pageNo: 1, hasMoreData: false, pendingAppointments: [], completedAppointments: [] });
            let token = (yield getStorageData("token")) || "";
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
                token: token
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.searchAppointmentApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.endpointAvailable}?search=${value}&page=${this.state.pageNo}&per=15`);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getHttpRequest);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        });
        this.getMyallAppoitments = (getStatus) => __awaiter(this, void 0, void 0, function* () {
            this.setState({ isloading: true });
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
                token: (yield getStorageData("token")) || "",
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getMyAppointmentApiCallId = requestMessage.messageId;
            if (getStatus && getStatus !== "all") {
                requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.endpointAvailable}?page=${this.state.pageNo}&per=15&status=${getStatus}`);
            }
            else {
                requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.endpointAvailable}?page=${this.state.pageNo}&per=15`);
            }
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getHttpRequest);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        });
        this.getOneAppoitments = (id) => {
            this.setState({ isloading: true });
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
                token: localStorage.getItem("token") || "",
            };
            const getOneAppointMentRequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getOneAppointmentApiCallId = getOneAppointMentRequestMessage.messageId;
            getOneAppointMentRequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.endpointAvailable + '/' + id);
            getOneAppointMentRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            getOneAppointMentRequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getHttpRequest);
            runEngine.sendMessage(getOneAppointMentRequestMessage.id, getOneAppointMentRequestMessage);
            return true;
        };
        this.CreateReview = () => {
            var _a, _b, _c, _d, _e, _f;
            let { appointmentId } = ((_a = this.props.location) === null || _a === void 0 ? void 0 : _a.state) || "";
            let appointmentIdFromNotification = localStorage.getItem("appointmentIdFromIndexDb") || localStorage.getItem("appointmentIdFromIndexDb1") || '';
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
                token: localStorage.getItem("token") || "",
            };
            const httpBody = {
                "account_id": (_f = (_e = (_d = (_c = (_b = this.state.appointmentdata) === null || _b === void 0 ? void 0 : _b.attributes) === null || _c === void 0 ? void 0 : _c.employee_details) === null || _d === void 0 ? void 0 : _d.data) === null || _e === void 0 ? void 0 : _e.attributes) === null || _f === void 0 ? void 0 : _f.employee_account_id,
                "rating": this.state.ratingvalue,
                "description": this.state.reviewInput,
                "appointment_id": appointmentId || appointmentIdFromNotification
            };
            const createReviewRequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.createReviewApiId = createReviewRequestMessage.messageId;
            createReviewRequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.reviewApi);
            createReviewRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            createReviewRequestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
            createReviewRequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.postMethod);
            runEngine.sendMessage(createReviewRequestMessage.id, createReviewRequestMessage);
            return true;
        };
        this.getIsues = () => {
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
                token: localStorage.getItem("token") || "",
            };
            const getIssuesRequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getallIssueApiCallId = getIssuesRequestMessage.messageId;
            getIssuesRequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.inquireType);
            getIssuesRequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getHttpRequest);
            getIssuesRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            runEngine.sendMessage(getIssuesRequestMessage.id, getIssuesRequestMessage);
            return true;
        };
        this.handleResponseAppointment = (response) => {
            if ((response === null || response === void 0 ? void 0 : response.notice) === "No appointments found.") {
                if (this.state.pageNo === 1) {
                    this.setState({
                        pendingAppointments: [],
                        completedAppointments: [],
                        isloading: false,
                        hasMoreData: false,
                    });
                }
                else {
                    this.setState({
                        hasMoreData: false,
                        isloading: false,
                    });
                }
            }
            else {
                this.setState({
                    pendingAppointments: [
                        ...this.state.pendingAppointments,
                        ...response === null || response === void 0 ? void 0 : response.pending_appointments.data,
                    ],
                    completedAppointments: [
                        ...this.state.completedAppointments,
                        ...response === null || response === void 0 ? void 0 : response.completed_appointments.data,
                    ],
                    hasMoreData: true,
                    isloading: false,
                });
            }
        };
        this.handleSearchAppointmentApiResponse = (response) => {
            if ((response === null || response === void 0 ? void 0 : response.notice) === "No appointments found.") {
                if (this.state.pageNo === 1) {
                    this.setState({
                        pendingAppointments: [],
                        completedAppointments: [],
                        isloading: false,
                        hasMoreData: false,
                    });
                }
                else {
                    this.setState({
                        hasMoreData: false,
                        isloading: false,
                    });
                }
            }
            else {
                this.setState({
                    pendingAppointments: [
                        ...response === null || response === void 0 ? void 0 : response.pending_appointments.data,
                    ],
                    completedAppointments: [
                        ...response === null || response === void 0 ? void 0 : response.completed_appointments.data,
                    ],
                    hasMoreData: false,
                    isloading: false,
                });
            }
        };
        this.handleResponseOneAppointment = (response) => {
            var _a, _b, _c, _d;
            if (response === null || response === void 0 ? void 0 : response.data) {
                let aDate = moment((_b = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.attributes) === null || _b === void 0 ? void 0 : _b.start_time).format('MM/DD/YYYY') + ' ' + moment((_d = (_c = response === null || response === void 0 ? void 0 : response.data) === null || _c === void 0 ? void 0 : _c.attributes) === null || _d === void 0 ? void 0 : _d.start_time).format('hh:mm A');
                let bDate = moment().format('MM/DD/YYYY') + ' ' + moment().format('hh:mm A');
                aDate = new Date(aDate).getTime();
                bDate = new Date(bDate).getTime();
                let ms = aDate - bDate;
                let seconds = Math.floor(ms / 1000);
                let minutes = Math.floor(seconds / 60);
                let hours = minutes / 60;
                this.setState({ appointmentdata: response === null || response === void 0 ? void 0 : response.data, hoursBrefore: hours, isloading: false }, () => this.checkDisablity());
            }
        };
        this.handleResponseinvoice = () => {
            this.setState({ openModalInvoice: true, isloading: false });
        };
        this.handleResponseIssues = (response) => {
            this.setState({ issueArr: response });
        };
        this.handleResponseCancel = (response) => {
            if (response === null || response === void 0 ? void 0 : response.message) {
                if (response.message === "Contact squeezeMeIn for further bookings") {
                    this.props.history.push("/CustomerHomeScreen");
                    this.setState({ isloading: false });
                }
                else {
                    this.props.history.push({
                        pathname: "/BookingRescheduled",
                        state: { cancel: true },
                    });
                    this.setState({ isloading: false });
                }
            }
        };
        this.handleResponseReview = (response) => {
            var _a;
            if ((response === null || response === void 0 ? void 0 : response.message) == 'account id already reviewed') {
                this.setState({ showSubmittedresp: true, alreadySubmitted: true });
            }
            else if (((_a = response === null || response === void 0 ? void 0 : response.message) === null || _a === void 0 ? void 0 : _a.account[0]) == 'must exist') {
                toast.error('Employee does not exist.', { position: toast.POSITION.BOTTOM_RIGHT });
            }
            else {
                this.setState({ showSubmittedresp: true, alreadySubmitted: false });
            }
        };
        this.moveToissueScreen = (screen) => {
            this.state.issueArr.forEach((element) => {
                var _a;
                if ((element === null || element === void 0 ? void 0 : element.name.trim()) == screen.trim()) {
                    let { clickFromProfile } = ((_a = this.props.location) === null || _a === void 0 ? void 0 : _a.state) || "";
                    if (clickFromProfile) {
                        this.props.history.push({
                            pathname: '/NeedHelp',
                            state: { clickFromProfile: true, issue: screen, issueId: element === null || element === void 0 ? void 0 : element.id, appointmentId: this.state.appointmentdata.id }
                        });
                    }
                    else {
                        this.props.history.push({
                            pathname: '/NeedHelp',
                            state: { issue: screen, issueId: element === null || element === void 0 ? void 0 : element.id, appointmentId: this.state.appointmentdata.id }
                        });
                    }
                }
            });
        };
        this.updateAppointmentUsingSocket = (data) => {
            var _a;
            let { appointmentId } = ((_a = this.props.location) === null || _a === void 0 ? void 0 : _a.state) || "";
            if (data.type === "appointment_status_changed" &&
                appointmentId ==
                    data.appointment_id) {
                setTimeout(() => {
                    this.getOneAppoitments(data.appointment_id);
                }, 2000);
            }
            if (data.type === "new_appointment_assigned") {
                this.getMyallAppoitments();
            }
        };
        this.trackScrolling = () => {
            const { isloading, hasMoreData, filterRadioVal } = this.state;
            const wrappedElement = this.listRef.current;
            if (!wrappedElement || isloading || !hasMoreData)
                return;
            const isBottomReached = wrappedElement.scrollHeight - wrappedElement.scrollTop <= wrappedElement.clientHeight + 1;
            if (isBottomReached) {
                this.setState(prevState => ({
                    pageNo: prevState.pageNo + 1
                }), () => {
                    this.getMyallAppoitments(filterRadioVal);
                });
            }
        };
        this.filterShowResultsOnClick = () => {
            this.setState({
                pendingAppointments: [],
                completedAppointments: [],
                pageNo: 1,
                hasMoreData: true,
                openFilter: false,
            }, () => {
                this.getMyallAppoitments(this.state.filterRadioVal);
            });
        };
        this.handleFilterModalChange = () => {
            this.setState({ openFilter: !this.state.openFilter });
        };
        this.handleFilterValueChanges = (event) => {
            const { allFiltermanage } = this.state;
            const updatedFilters = Object.keys(allFiltermanage).reduce((acc, filter) => {
                acc[filter] = filter === event.target.value;
                return acc;
            }, {});
            this.setState({
                filterRadioVal: event.target.value,
                allFiltermanage: Object.assign(Object.assign({}, this.state.allFiltermanage), updatedFilters),
            });
        };
        this.getStatus = (status) => {
            const statusLabels = {
                completed: "Completed",
                scheduled: "Upcoming",
                pending: "Upcoming",
                started: "Started",
                refunded: "Refunded",
                re_scheduled: "Re-scheduled",
                created: "Created",
                no_show: "No-show",
                cancelled: "Cancelled",
                refund_initiated: "Refund-initiated",
                request_failed: "Request-failed",
            };
            return statusLabels[status] || "";
        };
        this.getcolor = (status) => {
            const statusColors = {
                scheduled: "#ff9f0a",
                pending: "#ff9f0a",
                completed: "#34C759",
                re_scheduled: "#ff9f0a",
                started: "#34C759",
                refund_initiated: "#ff9f0a",
                refunded: "#8D35FF",
                created: "#02AFCE",
                cancelled: "#de350b",
                no_show: "#de350b",
                request_failed: "#de350b",
            };
            return statusColors[status] || "#de350b";
        };
        this.receive = this.receive.bind(this);
        this.listRef = React.createRef();
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIRequestMessage),
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            slectActiveBtn: true,
            shoInput: false,
            appointmentArr: [],
            pendingAppointments: [],
            completedAppointments: [],
            isloading: false,
            appointmentdata: [],
            openModalInvoice: false,
            invoiceorCancel: 'cancel',
            issueArr: [],
            openModalReview: false,
            ratingvalue: '',
            requireRating: false,
            reviewInput: '',
            showSubmittedresp: false,
            alreadySubmitted: '',
            hoursBrefore: '',
            openFilter: false,
            filterRadioVal: '',
            allFiltermanage: {
                all: true,
                completed: false,
                no_show: false,
                cancelled: false,
                refunded: false,
                pending: false,
                started: false,
                re_scheduled: false,
                refund_initiated: false,
                request_failed: false,
            },
            pageNo: 1,
            hasMoreData: true,
        };
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }
    // Customizable Area End
    // Customizable Area Start
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            let user = localStorage.getItem("userType") || "";
            let token = localStorage.getItem("token") || "";
            setTimeout(() => {
                if (user != "customer" || !token) {
                    localStorage.clear();
                    window.location.replace("/LandingPage");
                }
                else {
                    this.callallApi(this.bookingScreen);
                }
            }, 1000);
        });
    }
    componentWillUnmount() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.listRef.current) {
                this.listRef = null;
            }
        });
    }
    receive(from, message) {
        return __awaiter(this, void 0, void 0, function* () {
            if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
                const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
                const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
                if (apiRequestCallId === this.getMyAppointmentApiCallId) {
                    this.handleResponseAppointment(responseJson);
                }
                if (apiRequestCallId === this.getOneAppointmentApiCallId) {
                    this.handleResponseOneAppointment(responseJson);
                }
                if (apiRequestCallId === this.invoiceApiCallId) {
                    this.handleResponseinvoice();
                }
                if (apiRequestCallId === this.editAppointmentcreation) {
                    this.handleResponseCancel(responseJson);
                }
                if (apiRequestCallId === this.getallIssueApiCallId) {
                    this.handleResponseIssues(responseJson);
                }
                if (apiRequestCallId === this.createReviewApiId) {
                    this.handleResponseReview(responseJson);
                }
                if (apiRequestCallId === this.searchAppointmentApiCallId) {
                    this.handleSearchAppointmentApiResponse(responseJson);
                }
            }
            else {
                runEngine.debugLog("GOIT");
            }
        });
    }
    componentWillMount() {
        return __awaiter(this, void 0, void 0, function* () {
            const token = yield getStorageData("token");
            const URL = `${baseUrlConfig.baseURL}/cable?token=${token}`;
            const consumer = createConsumer(URL);
            consumer.subscriptions.create({ channel: "UserChannel" }, {
                disconnected: () => runEngine.debugLog("Customer Socket Disconnected"),
                connected: () => runEngine.debugLog("Customer Socket Connected"),
                received: (data) => this.updateAppointmentUsingSocket(data),
            });
        });
    }
}
