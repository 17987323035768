export const employeeAvtar = require("../assets/avtar.png");
export const next = require("../assets/next.png");
export const call = require("../assets/call.png");
export const search = require("../assets/search.png");
export const plus = require("../assets/plus.png");
export const defaultProfile = require("../assets/user.png");
export const success = require("../assets/success.png");
export const edit = require("../assets/edit.png");
export const astar = require("../assets/astar.png");
export const dstar = require("../assets/dstar.png");
export const quotes = require("../assets/quote.png");
export const alert = require("../assets/alert.png");
export const imgSplash = require("../../global_assets/logo.png");
export const img0c5601295f4c5a7a6ce62366d2e371ab1929bd5b = require("../assets/0c5601295f4c5a7a6ce62366d2e371ab1929bd5b.png");
export const SqueezMeInImageSmall = require("../assets/squeezsmall.png");
export const pasteimage = require("../assets/pasteimage.png");
export const groupimage = require("../assets/Oval.png");
export const squeeze_big_image = require("../assets/squeezimage.png");
export const dotImgOne = require("../assets/dot.png");
export const gudda_img = require("../assets/gudda.png");
export const gudhiya_img = require("../assets/gudhiya.png");
export const gudda_gudhiya_img = require("../assets/gudda_gudhiya.png");
export const men_img = require("../assets/men.png");
export const women_img = require("../assets/women.png");
export const men_women_img = require("../assets/men_women.png");
export const services_img = require("../assets/services.png");
export const squeez_white_img = require("../assets/squeezwhite.png");
export const search_icon_img = require("../assets/search_icon_new_new.png");
export const barbershop_icon = require("../assets/barbarshop.png");
export const fitness_icon = require("../assets/fitness.png");
export const spa_icon = require("../assets/spa.png");
export const salon02_icon = require("../assets/salon02.png");
export const fitness02_icon = require("../assets/fitness02.png");
export const spa02_icon = require("../assets/spa02.png");
export const fitness03_icon = require("../assets/fitness03.png");
export const spa03_icon = require("../assets/spa03.png");
export const navigation_icon = require("../assets/nav_icon.png");
export const location_icon = require("../assets/location_point.png");
export const location_placeholder_icon = require("../assets/location_placeholder.png");
export const enable_location_icon = require("../assets/enable_location_button.png");
export const info_icon = require("../assets/infoIcon.svg");
export const fitness_list_main = require("../assets/beast_up.png");
export const fitness_list_icon = require("../assets/fitness-list-icon.svg");
export const dot_fitness = require("../assets/dot_fitness.svg");
export const star_rate = require("../assets/star_rate.svg");
export const Salon_main = require("../assets/Salon_main.png");
export const left_arrow = require("../assets/left_arrow.svg");
export const kozma_img = require("../assets/kozma_img.png");
export const call_us = require("../assets/call_us.svg");
export const search_icon = require("../assets/Group.png");
export const location_logo = require("../assets/location_icon.svg");
export const select_arrow = require("../assets/select_arrow.svg");
export const clock_icon = require("../assets/clock_icon.svg");
export const our_staff = require("../assets/our_staff.svg");
export const star_main_rate = require("../assets/star_main-rate.svg");
export const close_modal_icon = require("../assets/close_modal_icon.svg");
export const location_icon_btn = require("../assets/location_icon_btn.svg");
export const email_icon = require("../assets/email_icon.svg");
export const contact_icon = require("../assets/contact_icon.svg");
export const earth_icon = require("../assets/earth_icon.svg");
export const info_icon_services = require("../assets/info_icon_services.svg");
export const search_icon_services = require("../assets/search_icon_services.svg");
export const right_arrow_services = require("../assets/right_arrow_services.svg");
export const left_arrow_services = require("../assets/left_arrow_services.svg");
export const my_profile = require("../assets/my_profile.svg");
export const profile_arrow = require("../assets/profile_arrow.svg");
export const deactivate_account = require("../assets/deactivate_account.svg");
export const contact_us = require("../assets/contact_us.svg");
export const setting = require("../assets/setting.svg");
export const payment_method_profile = require("../assets/payment_method_profile.svg");
export const my_booking = require("../assets/my_booking.svg");
export const language = require("../assets/language.svg");
export const about_squees = require("../assets/about_squees.svg");
export const terms_condition = require("../assets/terms_condition.svg");
export const privacy_policy = require("../assets/privacy_policy.svg");
export const LogOut = require("../assets/LogOut.svg");
export const right_side_slider_arrow = require("../assets/right_side_slider_arrow.svg");
export const left_side_slider_arrow = require("../assets/left_side_slider_arrow.svg");
export const calender_prev_arrow = require("../assets/calender_prev_arrow.svg");
export const calender_next_arrow = require("../assets/calender_next_arrow.svg");
export const accordian_arrow = require("../assets/accordian_arrow.svg");
export const sesssionExpired = require("../assets/sesssionExpired.svg");
export const no_staff = require("../assets/no_staff.svg");
export const uncheck_icon = require("../assets/uncheck_icon.svg");
export const check_icon = require("../assets/check_icon.svg");
export const debit_icon = require("../assets/debitCard.svg");
export const white_left_arrow = require("../assets/white_left_arrow.svg");
export const payNow = require("../assets/payNow.svg");
export const green_check = require("../assets/green_check.svg");
export const red_check = require("../assets/red_check.svg");
export const visa_icon = require("../assets/visa_icon.svg");
export const master_card_icon = require("../assets/master_card_icon.svg");
export const cash_icon = require("../assets/cash_icon.svg");
export const cards_icon = require("../assets/cards_icon.svg");
export const quotes_icon = require("../assets/quotes_icon.svg");
export const notes_icon = require("../assets/notes_icon.svg");
export const UnCheckCard = require("../assets/UnCheckCard.svg");
export const CheckCard = require("../assets/CheckCard.svg");
export const info_last = require("../assets/info_last.svg");
export const nocard = require("../assets/nocard.svg");
export const quote = require("../assets/quote.svg");
export const bookingSearchIcon = require("../assets/bookingSearchIcon.svg");
export const doubleQuoteUpcoming = require("../assets/doubleQuoteUpcoming.svg");
export const invoice = require("../assets/invoice.svg");
export const invoice_mail = require("../assets/invoice_mail.svg");
export const cancel_appointment = require("../assets/cancel_appointment.svg");
export const unfilled_star = require("../assets/unfilled_star.svg");
export const edit_icon = require("../assets/editIcon.png");
export const edit_profile = require("../assets/editProfile.png");
export const cross = require("../assets/cross.png");
export const filterBookings = require("../assets/Setting_Icon.png");
export const warningIcon = require("../assets/warningIcon.svg");
export const defultPng = require("../assets/profilealt.png");
