var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React from "react";
// Customizable Area Start
import { getStorageData } from 'framework/src/Utilities';
// import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
const Cookies = require("js-cookie");
// Customizable Area End
export const configJSON = require("./config");
const AccountRegExr = /^([a-zA-Z0-9\_.-]+)$/;
export default class CustomisableUserProfilesWebController extends BlockComponent {
    constructor(props) {
        super(props);
        this.optionDisable = (value) => {
            return value === "" ? true : false;
        };
        this.showTimeInUppercase = (value) => {
            return value.toUpperCase();
        };
        this.setServiceID = (value) => {
            this.setState({ serviceId: value });
        };
        // web events
        this.setStateValue = () => {
            this.setState({ openBankDetailsDialog: true });
        };
        // web events
        this.setInputValue = (text) => {
            if (/^[a-zA-Z ]*$/.test(text)) {
                this.setState({ txtInputValue: text, businessName: text, businessNameError: "" });
            }
        };
        this.setArabicInputValue = (text) => {
            if (/^$|^[\u0600-\u065F\s]+$/.test(text)) {
                this.setState({
                    txtInputValue: text,
                    arabicBusinessName: text,
                    arabicBusinessNameError: "",
                });
            }
        };
        // web events
        this.onChange = (imageList, addUpdateIndex) => {
            // data for submit
            const flag = imageList.length === 0 ? false : true;
            if (imageList) {
                this.setState({ businessImages: imageList, showImages: flag, businessImagesError: "" });
            }
            if (imageList && imageList.length < 2) {
                this.setState({ businessImagesError: "Please upload at least 2 images of your business" });
            }
            else if (imageList && imageList.length > 5) {
                this.setState({ showImages: false, businessImages: [], businessImagesError: "You can upload max 5 images of your business" });
            }
        };
        this.validateBusinessName = () => {
            if (this.state.businessName === "") {
                this.setState({ businessNameError: "Please enter business name" });
            }
            else {
                this.setState({ businessNameError: "" });
            }
        };
        this.validateArabicBusinessName = () => {
            if (this.state.arabicBusinessName === "") {
                this.setState({ arabicBusinessNameError: "Please enter business name in arabic" });
            }
            else {
                this.setState({ arabicBusinessNameError: "" });
            }
        };
        this.validateBusinessEmail = () => {
            const EmailRegExr = /^\w+([\.-]?\w+)*@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
            if (this.state.businessEmail === "") {
                this.setState({ businessEmailError: "Please enter business email" });
            }
            else if (!this.state.businessEmail.match(EmailRegExr)) {
                this.setState({ businessEmailError: "Invalid business email" });
            }
            else {
                this.setState({ businessEmailError: "" });
            }
        };
        this.validateWebsite = () => {
            const websiteFormat = /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/;
            if (this.state.website !== "") {
                if (!this.state.website.match(websiteFormat)) {
                    this.setState({ websiteError: "Invalid website" });
                }
                else {
                    this.setState({ websiteError: "" });
                }
            }
        };
        this.validateBusinessOwner = () => {
            if (this.state.businessOwner === "") {
                this.setState({ businessOwnerError: "Please enter owner name" });
            }
            else {
                this.setState({ businessOwnerError: "" });
            }
        };
        this.validateContact = () => {
            const contactRegex = /^[0-9 \-\(\)\.]{8,14}$/;
            if (this.state.contact === "") {
                this.setState({ contactError: "Please add your business contact number" });
            }
            else if (!this.state.contact.match(contactRegex)) {
                this.setState({ contactError: "Please add your business contact number" });
            }
            else {
                this.setState({ contactError: "" });
            }
        };
        this.validateCRN = () => {
            if (this.state.crn === "") {
                this.setState({ crnError: "Please enter CRN" });
            }
            else {
                this.setState({ crnError: "" });
            }
        };
        this.validateName = () => {
            const { accountOwnerFullName } = this.state;
            const accountOwnerNameError = accountOwnerFullName ? "" : "Please enter full name";
            this.setState({ accountOwnerNameError });
        };
        this.validateAccountNumber = () => {
            const { accountNumber } = this.state;
            let accountNumberError = "";
            if (accountNumber) {
                if (!accountNumber.match(/^[a-zA-Z0-9]*$/)) {
                    accountNumberError = "Invalid bank account number";
                }
            }
            else {
                accountNumberError = "Please enter bank account number";
            }
            this.setState({ accountNumberError });
        };
        this.validateConfirmAccountNumber = () => {
            const { accountNumber, confirmAccountNumber } = this.state;
            let confirmAccountNumberError = "";
            if (confirmAccountNumber) {
                if (accountNumber !== confirmAccountNumber) {
                    confirmAccountNumberError = "Confirm bank account number does not match";
                }
            }
            else {
                confirmAccountNumberError = "Please enter confirm bank account number";
            }
            this.setState({ confirmAccountNumberError });
        };
        this.validateBankName = () => {
            const { bankName } = this.state;
            const bankNameError = bankName ? "" : "Please enter bank name";
            this.setState({ bankNameError });
        };
        this.validatePurpose = () => {
            const { purposeOfPayment } = this.state;
            const purposeOfPaymentError = purposeOfPayment ? "" : "Please enter purpose of payment";
            this.setState({ purposeOfPaymentError });
        };
        this.handlePaymentSelection = (value) => {
            if (value === "payAtShop") {
                this.setState({ enablePayAtShop: !this.state.enablePayAtShop }, () => {
                    if (this.state.enablePayAtShop) {
                        this.setState({ paymentOptionError: "" });
                    }
                    if (!this.state.enableCardPayment && !this.state.enablePayAtShop) {
                        this.setState({ paymentOptionError: "Please choose your payment options" });
                    }
                });
            }
            else {
                this.setState({ enableCardPayment: !this.state.enableCardPayment }, () => {
                    if (this.state.enableCardPayment) {
                        this.setState({ paymentOptionError: "" });
                    }
                    if (!this.state.enableCardPayment && !this.state.enablePayAtShop) {
                        this.setState({ paymentOptionError: "Please choose your payment options" });
                    }
                });
            }
        };
        this.handleSelectToggle = (key) => {
            this.setState(prevState => {
                const newState = Object.assign(Object.assign({}, prevState), { [key]: !prevState[key] });
                return newState;
            });
        };
        this.getTogle = (item, togleTrue, togleFalse) => {
            if (item) {
                return togleTrue;
            }
            else {
                return togleFalse;
            }
        };
        this.getBorderStyle = (error) => {
            return error ? "1px solid #FF453A" : "1px solid #757575";
        };
        this.renderErrorMessageforProfile = (Message) => {
            const errorMessage = Message ? Message : null;
            return errorMessage;
        };
        this.handleBackgroundColor = (item) => {
            const isWeekday = item.isWeekday;
            const isWeekend = item.isWeekend;
            const selectionMode = this.state.getSelectionMode;
            let backgroundColor = "#f9f9f9";
            if ((selectionMode === "weekdays" && isWeekday) || (selectionMode === "weekends" && isWeekend)) {
                backgroundColor = "#1E5FEA";
            }
            return backgroundColor;
        };
        this.handleColor = (item) => {
            const isWeekday = item.isWeekday;
            const isWeekend = item.isWeekend;
            const selectionMode = this.state.getSelectionMode;
            let color = "#808080";
            if ((selectionMode === "weekdays" && isWeekday) || (selectionMode === "weekends" && isWeekend)) {
                color = "#FFFFFF";
            }
            return color;
        };
        this.handleCountryCodeChange = (value) => {
            if (value) {
                this.setState({ countryCode: value, countryCodeModal: false });
            }
            else {
                this.setState({ countryCodeModal: !this.state.countryCodeModal });
            }
        };
        this.handleWorkingHoursSelection = (itemId) => {
            const { getSelectionMode, workingDaysError, weekendDaysError } = this.state;
            const updatedWorkingDaysData = this.state.workingDaysData.map((item) => {
                if (itemId !== item.id)
                    return item;
                if (getSelectionMode === "weekdays" && !item.isWeekend) {
                    item.isWeekday = !item.isWeekday;
                    item.isDisableWeekend = item.isWeekday;
                }
                else if (getSelectionMode === "weekends" && !item.isWeekday) {
                    item.isWeekend = !item.isWeekend;
                    item.isDisableWeekday = item.isWeekend;
                }
                return item;
            });
            const workingDays = updatedWorkingDaysData.filter((item) => item.isWeekday);
            const weekendDays = updatedWorkingDaysData.filter((item) => item.isWeekend);
            this.setState({
                workingDaysData: updatedWorkingDaysData,
                workingDays: workingDays,
                workingDaysError: workingDays.length ? "" : workingDaysError,
                weekendDays: weekendDays,
                weekendDaysError: weekendDays.length ? "" : weekendDaysError,
            });
        };
        this.getBackGroundColor = (selectionMode, weekType) => {
            return selectionMode === weekType ? "#1e5fea" : undefined;
        };
        this.getCardBackGroundColor = (showImages) => {
            return "#F9F9F8";
        };
        this.getTextColor = (selectionMode, weekType) => {
            return selectionMode === weekType ? 'white' : 'black';
        };
        this.handleSave = () => __awaiter(this, void 0, void 0, function* () {
            const IFSCRegExr = /^[A-Za-z]{4}\d{7}$/;
            if (this.state.accountOwnerFullName === "") {
                this.setState({
                    accountOwnerNameError: "Please enter full name",
                });
            }
            else {
                this.setState({ accountOwnerNameError: "" });
            }
            if (this.state.accountNumber === "") {
                this.setState({ accountNumberError: "Please enter bank account number" });
            }
            else if (!this.state.accountNumber.match(AccountRegExr)) {
                this.setState({ accountNumberError: "Invalid bank account number" });
            }
            else {
                this.setState({ accountNumberError: "" });
            }
            if (this.state.confirmAccountNumber === "") {
                this.setState({ confirmAccountNumberError: "Please enter confirm bank account number" });
            }
            else if (this.state.accountNumber !== this.state.confirmAccountNumber) {
                this.setState({ confirmAccountNumberError: "Confirm bank account number does not match" });
            }
            else {
                this.setState({ confirmAccountNumberError: "" });
            }
            if (this.state.bankName === "") {
                this.setState({
                    bankNameError: "Please enter bank name",
                });
            }
            else {
                this.setState({ bankNameError: "" });
            }
            if (this.state.purposeOfPayment === "") {
                this.setState({ purposeOfPaymentError: "Please enter purpose of payment" });
            }
            else {
                this.setState({ purposeOfPaymentError: "" });
            }
            if (this.state.accountOwnerFullName !== "" && this.state.accountNumber.match(AccountRegExr) && this.state.confirmAccountNumber !== "" && this.state.accountNumber === this.state.confirmAccountNumber && this.state.bankName !== "" && this.state.purposeOfPayment !== "") {
                const bankDetails = [
                    { accountOwnerName: this.state.accountOwnerFullName },
                    { accountNumber: this.state.accountNumber },
                    { bankName: this.state.bankName },
                    { purpose: this.state.purposeOfPayment }
                ];
                this.setState({ openAddedDetailsDialog: true, openBankDetailsDialog: false, accountDetails: bankDetails, bankDetailsError: "" });
            }
        });
        this.onWeekendFromSelection = (selectedItem) => {
            this.setState({ isWeekendToDisable: false, weekendFrom: selectedItem, weekendHoursError: "" });
            let Ind;
            this.state.workingSlot.forEach((item, index) => {
                if (item === selectedItem) {
                    Ind = index;
                    let fromIndex = this.state.workingSlot.filter((item, index) => {
                        // console.log("index ind==>", index, Ind);
                        if (index > Ind) {
                            return item;
                        }
                    });
                    fromIndex.unshift("");
                    this.setState({ weekendSlotTo: fromIndex });
                    let lastIndex = this.state.workingSlot.length - 1;
                    if (lastIndex === Ind) {
                        let toTime = this.state.workingSlot.filter((item, index) => {
                            if (index !== Ind) {
                                return item;
                            }
                        });
                        // console.log("345==>", toTime);
                        toTime.unshift("");
                        this.setState({ weekendSlotTo: toTime });
                    }
                }
            });
        };
        this.onWorkingFromSelection = (selectedItem) => {
            this.setState({ isWorkingToDisable: false, workingFrom: selectedItem, workingHoursError: "" });
            let Ind;
            this.state.workingSlot.forEach((item, index) => {
                if (item === selectedItem) {
                    Ind = index;
                    let lastIndex = this.state.workingSlot.length - 1;
                    let fromIndex = this.state.workingSlot.filter((item, index) => {
                        if (index > Ind) {
                            return item;
                        }
                    });
                    fromIndex.unshift("");
                    this.setState({ workingSlotTo: fromIndex });
                    if (lastIndex === Ind) {
                        let toTime = this.state.workingSlot.filter((item, index) => {
                            if (index !== Ind) {
                                return item;
                            }
                        });
                        toTime.unshift("");
                        this.setState({ workingSlotTo: toTime });
                    }
                }
            });
            // console.log("3456==>", Ind, this.state.workingSlotTo);
        };
        this.handleCancel = () => {
            this.setState({
                // accountOwnerName: "",
                accountNumber: "",
                confirmAccountNumber: "",
            });
        };
        this.validateBusinessSetup = () => {
            const contactRegex = /^[0-9 \-\(\)\.]{8,14}$/;
            const errors = {
                paymentOption: !this.state.enableCardPayment && !this.state.enablePayAtShop,
                businessImages: this.state.businessImages.length < 2 || this.state.businessImages.length > 5,
                businessName: this.state.businessName === "",
                businessOwner: this.state.businessOwner === "",
                businessEmail: this.state.businessEmail === "" || !this.state.businessEmail.match(/^\w+([\.-]?\w+)*@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/),
                website: this.state.website !== "" && !this.state.website.match(/^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/),
                contact: this.state.contact === "" || !this.state.contact.match(contactRegex),
                crn: this.state.crn === "",
                service: this.state.serviceId === null,
                bankDetails: this.state.accountDetails === undefined || this.state.accountDetails.length === 0,
                workingHours: this.state.workingFrom === "" || this.state.workingTo === "" || this.state.workingFrom === this.state.workingTo,
                weekendHours: this.state.weekendFrom === "" || this.state.weekendTo === "" || this.state.weekendFrom === this.state.weekendTo,
                workingDays: this.state.workingDays.length === 0,
                weekendDays: this.state.weekendDays.length === 0
            };
            const errorMessages = {
                paymentOption: "Please choose your payment options",
                businessImages: "Please upload at least 2 images of your business",
                businessName: "This field can't be empty",
                businessOwner: "This field can't be empty",
                businessEmail: "Invalid business email",
                website: "Invalid website",
                contact: "Please add your business contact number",
                crn: "This field can't be empty",
                service: "Please choose a category",
                bankDetails: "Please add bank details",
                workingHours: "Please choose your working time",
                weekendHours: "Please choose your weekend time",
                workingDays: "Please choose your working days",
                weekendDays: "Please choose your weekend days"
            };
            let hasErrors = false;
            const errorState = {};
            Object.keys(errors).forEach((key) => {
                if (errors[key]) {
                    hasErrors = true;
                    errorState[`${key}Error`] = errorMessages[key];
                }
                else {
                    errorState[`${key}Error`] = "";
                }
            });
            if (hasErrors) {
                this.setState((prevState) => (Object.assign(Object.assign({}, prevState), errorState)));
            }
            else {
                this.PostBusinessSetupAPICall();
            }
        };
        this.PostBusinessSetupAPICall = () => __awaiter(this, void 0, void 0, function* () {
            this.setState({ loading: true });
            const header = {
                token: this.state.token
            };
            var formdata = new FormData();
            formdata.append("owner_name", this.state.businessOwner);
            formdata.append("business_name", this.state.businessName);
            formdata.append("arabic_business_name", this.state.arabicBusinessName);
            formdata.append("business_email", this.state.businessEmail);
            formdata.append("website", this.state.website);
            formdata.append("crn", this.state.crn);
            formdata.append("pay_at_shop", this.state.enablePayAtShop.toString());
            formdata.append("card_payment", this.state.enableCardPayment.toString());
            formdata.append("open_from", this.state.workingFrom);
            formdata.append("close_at", this.state.workingTo);
            formdata.append("service_provided_id", this.state.serviceId);
            formdata.append("bank_account_detail_attributes[owner_name]", this.state.accountOwnerFullName);
            formdata.append("bank_account_detail_attributes[account_number]", this.state.accountNumber);
            formdata.append("bank_account_detail_attributes[bank_name]", this.state.bankName);
            formdata.append("bank_account_detail_attributes[purpose]", this.state.purposeOfPayment);
            formdata.append("contant_number", this.state.contact);
            formdata.append("country_code", this.state.countryCode);
            formdata.append("weekend_working_hours", "true");
            formdata.append("show_staff_members", this.state.allowTeam.toString());
            formdata.append("about_us", this.state.aboutUsText);
            formdata.append("arabic_about_us", this.state.aboutUsTextArabic);
            formdata.append("weekend_hours_to", this.state.weekendTo);
            formdata.append("weekend_hours_from", this.state.weekendFrom);
            this.state.businessImages.map((item, index) => {
                // console.log("image item==>", item);
                const mediaObj = {
                    uri: item.data_url,
                    name: 'photo',
                    type: item.file.type
                };
                formdata.append(`[attachments_attributes][${index}][image]`, item.file);
            });
            this.state.workingDays.map((item) => {
                formdata.append("working_days[]", item.day);
            });
            this.state.weekendDays.map((item) => {
                formdata.append("weekends[]", item.day);
            });
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.apiPostBusinessSetUpCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.PostBusinessSetupEndPoint);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formdata);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPostMethod);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        });
        // Customizable Area Start
        this.handleKeyDown = (event) => {
            if (event.keyCode === 38 || event.keyCode === 40) {
                const scrollAmount = event.keyCode === 38 ? -50 : 50;
                this.containerRef.current.scrollTop += scrollAmount;
                event.preventDefault();
            }
        };
        this.handleContactNumberChange = (e) => {
            if (/^$|^[\d]{0,14}$/.test(e.target.value)) {
                this.setState({
                    contactError: "",
                    contact: e.target.value,
                });
            }
        };
        this.handleWebisteChange = (e) => {
            if (/^[a-zA-Z0-9-./:]*$/.test(e.target.value)) {
                this.setState({
                    websiteError: "",
                    website: e.target.value,
                });
            }
        };
        this.handleCRNChange = (e) => {
            if (/^[a-zA-Z0-9]*$/.test(e.target.value)) {
                this.setState({ crnError: "", crn: e.target.value });
            }
        };
        this.handleAccountNumberInput = (e, stateKey, errorKey) => {
            const inputValue = e.target.value;
            const isValidInput = /^[a-zA-Z0-9]*$/.test(inputValue);
            if (isValidInput) {
                //@ts-ignore
                this.setState({
                    [stateKey]: inputValue,
                    [errorKey]: "",
                });
            }
        };
        this.handleBusinessEmailChange = (e) => {
            if (/^[a-zA-Z0-9 !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]*$/.test(e.target.value)) {
                this.setState({
                    businessEmailError: "",
                    businessEmail: e.target.value,
                });
            }
        };
        this.handleArabicAboutUs = (e) => {
            if (/^$|^[\u0600-\u065F\s.]+$/.test(e.target.value)) {
                this.setState({ aboutUsTextArabic: e.target.value });
            }
        };
        this.handleAccountInformationChange = (e, stateKey, errorKey) => {
            const inputValue = e.target.value;
            let regex = /^[a-zA-Z ]*$/;
            if (regex.test(inputValue)) {
                //@ts-ignore
                this.setState({
                    [stateKey]: inputValue,
                    [errorKey]: "",
                });
            }
        };
        this.handleBankPopupCancelButton = () => {
            this.setState({
                openBankDetailsDialog: false,
                purposeOfPaymentError: "",
                confirmAccountNumberError: "",
                accountNumberError: "",
                bankNameError: "",
                accountOwnerNameError: "",
                accountOwnerFullName: "",
                purposeOfPayment: "",
                confirmAccountNumber: "",
                bankName: "",
                accountNumber: ""
            });
        };
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.containerRef = React.createRef();
        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            saveButton: "",
            isSelectOpenWeekHoursTo: false,
            isSelectOpenWeekEndsFrom: false,
            isSelectOpenWeekHoursFrom: false,
            isSelectOpenWeekEndsTo: false,
            isSelectOpen: false,
            loginSuccess: false,
            aboutUsText: "",
            aboutUsTextArabic: "",
            backgroundColor: "#f9f9f9",
            color: "#808080",
            paymentOptionError: "",
            submisionError: '',
            token: '',
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            openBankDetailsDialog: false,
            openAddedDetailsDialog: false,
            showImages: false,
            countryCode: '+91',
            countryCodeModal: false,
            // Customizable Area Start
            arabicBusinessNameError: "",
            arabicBusinessName: "",
            loading: false,
            // Customizable Area End
            accountOwnerFullName: "",
            accountOwnerNameError: "",
            accountNumber: "",
            accountNumberError: "",
            confirmAccountNumber: "",
            confirmAccountNumberError: "",
            bankName: "",
            bankNameError: "",
            purposeOfPayment: "",
            purposeOfPaymentError: "",
            allowTeam: false,
            enablePayAtShop: false,
            enableCardPayment: false,
            showServiceList: false,
            service: "",
            serviceId: 1,
            serviceValue: "Choose",
            imageModalVisible: false,
            workingFrom: "",
            workingTo: "",
            businessImages: [],
            businessImagesError: "",
            providedService: [{
                    id: 1,
                    name: "Men"
                },
                {
                    id: 2,
                    name: "Women"
                },
                {
                    id: 3,
                    name: "Men & Women"
                }],
            selectedTeamSize: null,
            removePhotoEnable: false,
            selectedPhoto: null,
            serviceCategory: [],
            teamSizeData: [],
            businessName: "",
            businessNameError: "",
            businessOwner: "",
            businessOwnerError: "",
            businessEmail: "",
            businessEmailError: "",
            website: "",
            websiteError: "",
            crn: "",
            crnError: "",
            contact: "",
            contactError: "",
            bankAccountPlaceholder: "Add bank details",
            accountDetails: [],
            getRoundCorner: 25,
            getSelectionMode: 'weekdays',
            weekendFrom: "",
            weekendTo: "",
            serviceError: "",
            bankDetailsError: "",
            workingHoursError: "",
            workingDaysError: "",
            workingDays: [],
            isTakePhotoModalVisible: false,
            capturedImage: "",
            height: null,
            width: null,
            uri: "",
            weekendHoursError: "",
            weekendDays: [],
            isWorkingDayDisable: false,
            weekendDaysError: "",
            selectedWorkingFromIndex: "",
            selectedWorkingToIndex: '',
            workingSlotTo: [],
            weekendSlotTo: [],
            isWorkingToDisable: true,
            isWeekendToDisable: true,
            workingDaysData: [
                {
                    id: 1,
                    day: "Sunday",
                    isWeekend: false,
                    isWeekday: false,
                    isDisableWeekday: false,
                    isDisableWeekend: false
                },
                {
                    id: 2,
                    day: "Monday",
                    isWeekend: false,
                    isWeekday: false,
                    isDisableWeekday: false,
                    isDisableWeekend: false
                },
                {
                    id: 3,
                    day: "Tuesday",
                    isWeekend: false,
                    isWeekday: false,
                    isDisableWeekday: false,
                    isDisableWeekend: false
                },
                {
                    id: 4,
                    day: "Wednesday",
                    isWeekend: false,
                    isWeekday: false,
                    isDisableWeekday: false,
                    isDisableWeekend: false
                },
                {
                    id: 5,
                    day: "Thursday",
                    isWeekend: false,
                    isWeekday: false,
                    isDisableWeekday: false,
                    isDisableWeekend: false
                },
                {
                    id: 6,
                    day: "Friday",
                    isWeekend: false,
                    isWeekday: false,
                    isDisableWeekday: false,
                    isDisableWeekend: false
                },
                {
                    id: 7,
                    day: "Saturday",
                    isWeekend: false,
                    isWeekday: false,
                    isDisableWeekday: false,
                    isDisableWeekend: false
                },
            ],
            workingSlot: [
                "",
                "12:00 am",
                "12:30 am",
                "1:00 am",
                "1:30 am",
                "2:00 am",
                "2:30 am",
                "3:00 am",
                "3:30 am",
                "4:00 am",
                "4:30 am",
                "5:00 am",
                "5:30 am",
                "6:00 am",
                "6:30 am",
                "7:00 am",
                "7:30 am",
                "8:00 am",
                "8:30 am",
                "9:00 am",
                "9:30 am",
                "10:00 am",
                "10:30 am",
                "11:00 am",
                "11:30 am",
                "12:00 pm",
                "12:30 pm",
                "1:00 pm",
                "1:30 pm",
                "2:00 pm",
                "2:30 pm",
                "3:00 pm",
                "3:30 pm",
                "4:00 pm",
                "4:30 pm",
                "5:00 pm",
                "5:30 pm",
                "6:00 pm",
                "6:30 pm",
                "7:00 pm",
                "7:30 pm",
                "8:00 pm",
                "8:30 pm",
                "9:00 pm",
                "9:30 pm",
                "10:00 pm",
                "10:30 pm",
                "11:00 pm",
                "11:30 pm",
            ]
        };
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }
    receive(from, message) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            runEngine.debugLog("Message Recived", message);
            // console.log("responseJson.errors ::", message);
            // console.log("receive message ::", getName(MessageEnum.RestAPIResponceMessage), message.id, getName(MessageEnum.RestAPIResponceMessage) === message.id);
            if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
                const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
                var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
                var errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
                // console.log("receive responseJsonerrors ::", responseJson.errors[0].message);
                // console.log("receive errorReponse ::", errorReponse);
                if (apiRequestCallId === this.apiPostBusinessSetUpCallId) {
                    this.setState({ loading: false });
                    if (responseJson.error === "Internal Server Error" &&
                        responseJson.status === 500) {
                        this.setState({ submisionError: "Internal Server Error" });
                    }
                    if ((responseJson.message === "successfully created business profile" &&
                        responseJson.business_profile) ||
                        ((_a = responseJson === null || responseJson === void 0 ? void 0 : responseJson.errors[0]) === null || _a === void 0 ? void 0 : _a.message) ===
                            "Business profile all ready exists") {
                        this.props.navigation.navigate("CustomisableBusinessSetup");
                    }
                }
            }
        });
    }
    componentWillMount() {
        return __awaiter(this, void 0, void 0, function* () {
            const language = yield getStorageData('webAppLanguage');
            this.setState({ saveButton: language });
        });
    }
    ;
    saveBankDetailssuccess(t) {
        if (this.state.saveButton === "en") {
            return t("Your account details");
        }
        else {
            return t("Your account details is added successfully!");
        }
    }
    ;
    saveBankDetailssuccessSpantwo(t) {
        if (this.state.saveButton === "en") {
            return t("is added successfully!");
        }
        else {
            return "";
        }
    }
    ;
    renderErrorMessage() {
        const errorMessage = this.state.getSelectionMode === "weekdays"
            ? this.state.workingDaysError
            : this.state.weekendDaysError;
        return errorMessage ? errorMessage : null;
    }
    componentDidUpdate(prevProps, prevState) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.state.openAddedDetailsDialog || this.state.loginSuccess) {
                setTimeout(() => {
                    this.setState({ openAddedDetailsDialog: false, loginSuccess: false });
                }, 3000);
            }
        });
    }
    componentDidMount() {
        const _super = Object.create(null, {
            componentDidMount: { get: () => super.componentDidMount }
        });
        return __awaiter(this, void 0, void 0, function* () {
            _super.componentDidMount.call(this);
            this.setState({ loginSuccess: true, token: localStorage.getItem('token') });
            // Customizable Area Start
            setTimeout(() => {
                let loggedInToken = localStorage.getItem("token");
                let userType = localStorage.getItem("userType");
                let language = localStorage.getItem("webAppLanguage") || Cookies.get("webAppLanguage") || "en";
                this.setState({ saveButton: language });
                if (!loggedInToken || userType != "business") {
                    localStorage.clear();
                    window.location.replace("/LandingPage");
                }
            }, 2000);
            // Customizable Area End
        });
    }
}
