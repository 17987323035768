import React from "react";
import { OutlinedInput, InputAdornment, IconButton, Button, Dialog, DialogTitle, DialogContent, } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { backdrop, cross, countryCodeSearchIcon } from "../assets";
import AfterLoginHeader from "../../../dashboard/src/AfterLoginHeader.web";
import EmployeeViewProfileWebController from "../EmployeeProfile/EmployeeViewProfileWebController";
import "../../assets/Styles/EmployeeProfile.css";
import { withTranslation } from "react-i18next";
const Cookies = require("js-cookie");
class EmployeeViewProfile extends EmployeeViewProfileWebController {
    constructor(props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    render() {
        let webAppDirection = localStorage.getItem("webAppDirection") ||
            Cookies.get("webAppDirection") ||
            "ltr";
        let { t } = this.props;
        let formLabelClassName = webAppDirection === "ltr" ? "emp-form-label" : "arabic-emp-form-label";
        return (React.createElement("div", { className: "employes-view-Profile-main-container" },
            React.createElement(AfterLoginHeader, { navigation: this.props.navigation }),
            React.createElement("div", { className: "subheader-container" },
                React.createElement("div", { className: "employee-icon" },
                    React.createElement(IconButton, { style: {
                            width: "24px",
                            height: "24px",
                        }, onClick: () => {
                            this.props.navigation.navigate("ProfilePage");
                        }, id: "removeImage" },
                        React.createElement("img", { src: backdrop, alt: "logo", width: "24px", height: "24px" }))),
                React.createElement("span", { className: "subheader-text" }, t("View Profile"))),
            React.createElement("div", { className: "paper-box-container" },
                React.createElement("div", { className: "paper-box" },
                    React.createElement("div", { className: "paper-box-image-div" },
                        React.createElement("img", { src: this.getProfileImage(), alt: "temp", className: "paper-box-image" })),
                    React.createElement("div", { className: "field-container" },
                        React.createElement("span", { className: formLabelClassName }, t("Title")),
                        React.createElement(OutlinedInput, { type: "text", className: "employee-form-input-style", value: this.state.Name })),
                    React.createElement("div", { className: "field-container-nth-child" },
                        React.createElement("span", { className: formLabelClassName }, t("First name")),
                        React.createElement(OutlinedInput, { type: "text", className: "employee-form-input-style", value: this.state.Fname })),
                    React.createElement("div", { className: "field-container-nth-child" },
                        React.createElement("span", { className: formLabelClassName }, t("Last name")),
                        React.createElement(OutlinedInput, { type: "text", className: "employee-form-input-style", value: this.state.Lname })),
                    React.createElement("div", { className: "field-container-nth-child" },
                        React.createElement("span", { className: formLabelClassName }, t("Email Address")),
                        React.createElement(OutlinedInput, { type: "text", className: "employee-form-input-style", value: this.state.Email })),
                    React.createElement("div", { className: "field-container-nth-child" },
                        React.createElement("span", { className: formLabelClassName }, t("Mobile number")),
                        React.createElement(OutlinedInput, { type: "text", className: "employee-form-input-style", value: this.state.Mobile, startAdornment: React.createElement(InputAdornment, { position: "start" },
                                React.createElement(IconButton, { style: {
                                        width: 66,
                                        height: 48,
                                        borderRight: webAppDirection === "ltr" ? "1px solid #979797" : "",
                                        borderLeft: webAppDirection === "rtl" ? "1px solid #979797" : "",
                                        cursor: "pointer",
                                        justifyContent: "center",
                                        borderRadius: "0%",
                                    }, id: "countrycode" },
                                    React.createElement("span", { className: "country-code-text-style", style: {
                                            color: "#2c2c2e",
                                        } }, this.state.selectedCountryCode))) })),
                    React.createElement("div", { className: "field-container-nth-child" },
                        React.createElement("span", { className: formLabelClassName }, t("Gender")),
                        React.createElement(OutlinedInput, { type: "text", className: "employee-form-input-style", value: this.state.Gender })),
                    React.createElement("div", { className: "field-container-nth-child" },
                        React.createElement("span", { className: formLabelClassName }, t("Date of birth")),
                        React.createElement(OutlinedInput, { type: "text", className: "employee-form-input-style", value: this.state.Dob })),
                    React.createElement("div", { className: "field-container-nth-child" },
                        React.createElement("span", { className: formLabelClassName }, t("Preferred language")),
                        React.createElement(OutlinedInput, { type: "text", className: "employee-form-input-style", value: t(this.state.Language) })),
                    React.createElement("div", { className: "field-container-nth-child" },
                        React.createElement("span", { className: formLabelClassName }, t("About me")),
                        React.createElement(OutlinedInput, { value: this.state.AboutMe, multiline: true, rows: 4, type: "text", className: "employee-form-input-style aboutme" }))),
                React.createElement("div", { className: "paper-box", style: { backgroundColor: "#F9F9F8" } },
                    React.createElement("div", { className: "right-subheader-container" },
                        React.createElement("span", { className: "subheader-text subLabel" }, t("Employment"))),
                    React.createElement("div", { className: "Employment-card-container" },
                        React.createElement("div", { className: "card-field-container" },
                            React.createElement("span", { className: "card-form-label employment" }, t("Start date")),
                            React.createElement(OutlinedInput, { type: "text", value: this.state.StartDate, className: "employee-card-form-input-style employment" })),
                        React.createElement("div", { className: "card-field-container" },
                            React.createElement("span", { className: "card-form-label employment" }, t("End date")),
                            React.createElement(OutlinedInput, { type: "text", value: t(this.state.EndDate), className: "employee-card-form-input-style employment" }))),
                    React.createElement("div", { className: "right-header-container-nth-child" },
                        React.createElement("span", { className: "subheader-text subLabel" }, t("Working Days & Time"))),
                    React.createElement("div", { className: "workingday-card-container" },
                        React.createElement("div", { className: "workingday-card-content" },
                            React.createElement(ToggleButtonGroup, { value: "value", "aria-label": "workingdays" },
                                React.createElement(ToggleButton, { style: {
                                        color: this.getTextColor(this.state.getSelectionMode, "weekdays"),
                                        backgroundColor: this.getBackGroundColor(this.state.getSelectionMode, "weekdays"),
                                    }, className: "myCustomButton", value: "Weekdays", onClick: () => {
                                        this.updatedSwitchData("weekdays");
                                    } }, t("Weekdays")),
                                React.createElement(ToggleButton, { className: "myCustomButton", style: {
                                        color: this.getTextColor(this.state.getSelectionMode, "weekends"),
                                        backgroundColor: this.getBackGroundColor(this.state.getSelectionMode, "weekends"),
                                    }, value: "Weekends", onClick: () => {
                                        this.updatedSwitchData("weekends");
                                    } }, t("Weekends")))),
                        React.createElement("div", { className: "row-container" },
                            React.createElement("div", { className: "daytime-card-field-container field-1" },
                                React.createElement("label", { className: "card-form-sublabel" },
                                    t("From"),
                                    " "),
                                React.createElement("input", { type: "text", className: "daytime-form-input-style", value: this.getTime("from") })),
                            React.createElement("div", { className: "daytime-card-field-container field-2" },
                                React.createElement("label", { className: "card-form-sublabel" }, t("To")),
                                React.createElement("input", { type: "text", className: "daytime-form-input-style", value: this.getTime("to") }))),
                        React.createElement("div", { className: "right-header-container-nth-child subHeader" },
                            React.createElement("span", { className: "card-form-label" }, t("Block time"))),
                        React.createElement("div", { className: "row-container blocktime" },
                            React.createElement("div", { className: "daytime-card-field-container field-1" },
                                React.createElement("label", { className: "card-form-sublabel" },
                                    t("From"),
                                    " "),
                                React.createElement("input", { type: "text", className: "daytime-form-input-style", value: this.getTime("blockFrom") })),
                            React.createElement("div", { className: "daytime-card-field-container field-2" },
                                React.createElement("label", { className: "card-form-sublabel" }, t("To")),
                                React.createElement("input", { type: "text", className: "daytime-form-input-style", value: this.getTime("blockTo") }))),
                        React.createElement("div", { className: "right-header-container-nth-child subHeader" },
                            React.createElement("span", { className: "card-form-label" }, t("Working Days"))),
                        React.createElement("div", { className: "weekDays-map-container" }, this.state.workingDaysData.map((item) => {
                            return (React.createElement(Button, { className: "employess-weekdays-button", key: item.id, style: {
                                    padding: 0,
                                    border: 0,
                                    background: 0,
                                    marginRight: 10,
                                    borderRadius: "27px",
                                    width: "144px",
                                    height: "54px",
                                    fontSize: "18px",
                                    marginTop: 10,
                                    backgroundColor: this.handleBackgroundColor(item),
                                    color: this.handleColor(item),
                                }, color: "primary" },
                                React.createElement("span", { className: "fontfamily-profile" }, t(item.day))));
                        }))),
                    React.createElement("div", { className: "right-header-container-nth-child" },
                        React.createElement("span", { className: "subheader-text subLabel" }, t("Services"))),
                    React.createElement("div", { className: "right-header-container-nth-child HeaderNote" },
                        React.createElement("span", null, t("Add the services this team member can offer."))),
                    React.createElement("div", { className: "right-header-container-services" },
                        React.createElement(OutlinedInput, { type: "text", className: "allService-form-input-style", onClick: () => {
                                this.setState({
                                    showServiceList: !this.state.showServiceList,
                                });
                            }, value: this.state.Services.length == 0
                                ? t("Choose Services")
                                : `${t("All services")}(${this.state.Services.length})` })),
                    this.state.showServiceList && (React.createElement(Dialog, { open: this.state.showServiceList, disableAutoFocus: true, PaperProps: {
                            style: {
                                width: "683px",
                                height: "585px",
                                borderRadius: "24px",
                                boxShadow: "none",
                                background: "#FFFFFF",
                            },
                        } },
                        React.createElement(DialogTitle, { style: {
                                marginTop: "20px",
                                display: "flex",
                                marginLeft: 32,
                                marginRight: 40,
                            } },
                            React.createElement("div", { className: "container" },
                                React.createElement("span", { className: webAppDirection === "rtl" ? "label-right" : "label-left" }, t("Services")),
                                React.createElement("span", { className: webAppDirection === "ltr" ? "label-right" : "label-left" },
                                    React.createElement(IconButton, { color: "primary", "aria-label": "upload picture", component: "span", id: "close-dialog", onClick: () => {
                                            this.setState({ showServiceList: false });
                                        } },
                                        React.createElement("img", { src: cross, alt: "logo", width: "24px", height: "24px" })))),
                            React.createElement("div", { style: { marginTop: "20px" } },
                                React.createElement(OutlinedInput, { type: "text", placeholder: t("Search for Services"), className: "employess-search-service-input", onChange: (e) => this.handleChange(e.target.value), startAdornment: React.createElement(InputAdornment, { position: "start" },
                                        React.createElement(IconButton, { "aria-label": "edit mobile number", edge: "end", style: {
                                                width: 24,
                                                height: 24,
                                                marginRight: 2,
                                                marginLeft: 10,
                                            } },
                                            React.createElement("img", { src: countryCodeSearchIcon, height: 14, width: 14, alt: "eye" }))) }))),
                        React.createElement(DialogContent, null,
                            React.createElement("div", { style: { margin: "20px 8px 30px 32px" } },
                                React.createElement("div", { className: "servicelist-list-container" },
                                    React.createElement("div", { className: "servicelist-main-container" }, this.state.empServices.map((service) => {
                                        return (React.createElement("div", { key: `${service.service_name}`, className: "servicelist-detail-container" },
                                            React.createElement("div", null,
                                                React.createElement("span", { className: "employee-service-name-text" }, service.display_name)),
                                            React.createElement("div", { className: "employee-service-note" },
                                                React.createElement("span", null,
                                                    this.getHoursFromDuration(service),
                                                    " ",
                                                    t("min"),
                                                    " - OMR ",
                                                    service.service_cost))));
                                    }))))),
                        React.createElement(DialogActions, null)))))));
    }
}
//@ts-ignore
export default withTranslation()(EmployeeViewProfile);
